import { Box, IconButton, IconButtonProps, SxProps, Theme, Tooltip } from '@mui/material';
import { faStar as faStarRegular } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { joinSx } from 'utils/helpers';

type StarButtonProps = {
  isFavorite: boolean;
  onClick: IconButtonProps['onClick'];
  sx?: SxProps<Theme>;
};

export const StarButton = (props: StarButtonProps) => {
  return (
    <Tooltip
      title={props.isFavorite ? 'Remove from starred' : 'Star this category'}
      placement="top"
      arrow
    >
      <IconButton
        size="small"
        onClick={props.onClick}
        sx={joinSx(
          {
            height: '2rem',
            color: 'categoricYellow.700',
          },
          props.sx,
        )}
      >
        <Box
          component={FontAwesomeIcon}
          icon={props.isFavorite ? faStarSolid : faStarRegular}
        />
      </IconButton>
    </Tooltip>
  );
};
