import { Autocomplete, Box, TextField, Tooltip } from '@mui/material';
import { keyBy } from 'lodash-es';
import React from 'react';

import { ProjectDashboardVisibility } from 'types/Dashboard';
import { DASHBOARD_VISIBILITIES } from 'features/project-dashboard/helpers';
import { useIsExternalUser } from 'hooks/useIsExternalUser';

type Option = { value: ProjectDashboardVisibility; label: string; tooltip: string };

type ItemPrioritySelectProps = {
  value: ProjectDashboardVisibility;
  readOnly?: boolean;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChange: (type: ProjectDashboardVisibility | null) => void;
  error?: boolean;
  helperText: React.ReactNode;
  required?: boolean;
  hideLabel?: boolean;
};

const allOptions: Option[] = Object.keys(DASHBOARD_VISIBILITIES).map((type: string) => ({
  value: type as ProjectDashboardVisibility,
  label: DASHBOARD_VISIBILITIES[type as ProjectDashboardVisibility].label,
  tooltip: DASHBOARD_VISIBILITIES[type as ProjectDashboardVisibility].tooltip,
}));

const optionsHash = keyBy(allOptions, 'value');

const externalOptions: Option[] = [
  {
    value: 'EXTERNAL' as ProjectDashboardVisibility,
    label: DASHBOARD_VISIBILITIES.EXTERNAL.label,
    tooltip: DASHBOARD_VISIBILITIES.EXTERNAL.tooltip,
  },
];

export const ProjectDashboardVisibilitySelect = (props: ItemPrioritySelectProps) => {
  const isExternalUser = useIsExternalUser();

  const options = isExternalUser ? externalOptions : allOptions;
  const handleChange = (_event: React.SyntheticEvent, selectedOption: Option | null) => {
    if (selectedOption) {
      props.onChange(selectedOption?.value);
    }
  };

  return (
    <Autocomplete
      value={optionsHash[props.value]}
      onChange={handleChange}
      isOptionEqualToValue={(option, selected) => option.value === selected?.value}
      onBlur={props.onBlur}
      options={options}
      fullWidth
      renderOption={(props, option) => (
        <Tooltip key={option.value} title={option.tooltip} arrow placement="top-start">
          <Box component="li" {...props}>
            <span>{option.label}</span>
          </Box>
        </Tooltip>
      )}
      readOnly={props.readOnly}
      disableClearable={!!props.value}
      disabled={props.readOnly}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Type"
            required={props.required}
            placeholder="Select a type..."
            name={props.name}
            error={props.error}
            helperText={props.helperText}
            InputProps={{
              ...params.InputProps,
            }}
          />
        );
      }}
    />
  );
};
