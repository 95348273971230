import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { UserCompany } from 'types/Company';
import { parseCurrentTenantUrl } from 'utils/helpers';

export const useCurrentCompany = () => {
  const { currentUser } = useCurrentUser();

  const { subdomain } = parseCurrentTenantUrl();

  const currentCompany: UserCompany | undefined = currentUser?.companies.find(
    ({ slug }) => slug === subdomain,
  );

  return {
    currentCompany,
  };
};
