import { Theme } from '@mui/material';

export const circleButtonStyles = {
  color: (theme: Theme) => theme.palette.grey[500],
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: (theme: Theme) => theme.palette.grey[100],
  marginRight: (theme: Theme) => theme.spacing(1),
  height: '2rem',
  '& .svg-inline--fa': { height: '1rem' },
};
