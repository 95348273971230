export function pluralize(
  word: string,
  count: number,
  includeNumber = false,
  plural?: string,
) {
  if (count === 1) {
    return includeNumber ? `${count} ${word}` : word;
  }
  if (count > 1) {
    if (plural != null) {
      return includeNumber ? `${count} ${plural}` : plural;
    } else {
      return includeNumber ? `${count} ${word}s` : `${word}s`;
    }
  }
  return '';
}
