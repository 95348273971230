import { Fragment, ReactNode, useState } from 'react';

import {
  Avatar,
  Box,
  Chip,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  Theme,
} from '@mui/material';
import { PrivateURL, PublicURL, History } from 'Urls';
import avatar from 'assets/img/faces/face-0.jpg';

import {
  faArrowRightFromBracket,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useMixpanel } from 'hooks/useMixpanel';
import { MemberChip } from 'components/CustomChips/MemberChip';
import { WorkspaceItemCard } from './WorkspaceItemCard';

import {
  parseCurrentTenantUrl,
  joinSx,
  getUserFullName,
  setPreviewTenant,
} from 'utils/helpers';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { UserCompany } from 'types/Company';
import { isPreview } from 'utils/environment';

type UserIconButtonProps = {
  sx?: SxProps<Theme>;
  readOnly?: boolean;
};

export const UserIconButton = (props: UserIconButtonProps) => {
  const { currentUser } = useCurrentUser();
  const { subdomain, url, host } = parseCurrentTenantUrl();

  const fullname = getUserFullName(currentUser);

  const userCompanies = currentUser?.companies;
  const currentCompany = userCompanies?.find(({ slug }) => slug === subdomain);
  const remainingCompanies = userCompanies
    ? userCompanies.filter(({ slug }) => slug !== subdomain)
    : [];

  const [menuElementRef, setMenuElementRef] = useState<HTMLDivElement | null>(null);
  const open = Boolean(menuElementRef);
  const handleCloseUserMenu = () => {
    setMenuElementRef(null);
  };

  const redirect = (to: string) => {
    History.push(to);
    handleCloseUserMenu();
  };

  const handleChangeWorkspace = (company: UserCompany) => {
    if (isPreview()) {
      setPreviewTenant(company.slug);
      window.location.reload();
    } else {
      window.open(
        `${url.protocol}//${host.replace(currentCompany?.slug ?? '', company.slug)}`,
      );
    }
  };

  return (
    <>
      <Tooltip title={fullname}>
        <Chip
          icon={
            <Avatar
              src={avatar}
              alt="Avatar"
              sx={{
                height: '1.75rem',
                width: '1.75rem',
                marginLeft: '0!important',
                '&.MuiAvatar-circular.MuiChip-icon': {
                  height: '100%',
                },
              }}
            />
          }
          label={
            <Box>
              {currentCompany?.name}
              {!props.readOnly && (
                <Box
                  component={FontAwesomeIcon}
                  icon={open ? faChevronUp : faChevronDown}
                  ml={1}
                />
              )}
            </Box>
          }
          id="user-button"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(event) => {
            setMenuElementRef(event.currentTarget);
          }}
          variant="outlined"
          sx={joinSx(
            {
              padding: '2px',
              cursor: props.readOnly ? 'default' : 'pointer',
            },
            props.sx,
          )}
        />
      </Tooltip>

      <Menu
        id="user-menu"
        anchorEl={menuElementRef}
        open={!props.readOnly && open}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
      >
        <MenuList sx={{ minWidth: '21rem', p: 0 }}>
          <Typography variant="textSmallBold" sx={{ pl: 1, color: 'grey.500' }}>
            Account
          </Typography>
          {currentUser && (
            <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1 }}>
              <MemberChip
                member={currentUser}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  alignItems: 'center',
                  backgroundColor: (theme) => `${theme.palette.blue[100]} !important`,
                  color: 'blue.900',
                  height: '2rem',
                  width: '2rem',
                  borderRadius: '100%',
                  fontSize: '.875rem',
                  mr: 1,
                }}
              />
              <Stack sx={{ flexGrow: 1 }}>
                <Typography variant="textDefaultSemiBold">{fullname}</Typography>
                <Typography color="grey.500">{currentUser.email}</Typography>
              </Stack>
            </Box>
          )}
          <Divider sx={{ my: 0.5 }} />

          <MenuItemButton
            onClick={() => {
              redirect(PrivateURL.USER_PROFILE.replace(':userId', currentUser?.id + ''));
            }}
            trackingTag="User Profile"
          >
            Profile Settings
          </MenuItemButton>
          <MenuItemButton
            onClick={() => {
              redirect(
                PrivateURL.COMPANY_PROFILE.replace(
                  ':companyId',
                  currentCompany?.id ? String(currentCompany.id) : '',
                ),
              );
            }}
            trackingTag="Company Profile"
          >
            Company Settings
          </MenuItemButton>
          <Divider sx={{ my: '.25rem !important' }} />
          <Typography variant="textSmallBold" sx={{ pl: 1, color: 'grey.500' }}>
            Current Workspace
          </Typography>
          {currentCompany && (
            <WorkspaceItemCard workspace={currentCompany} sx={{ px: 2, py: 1.5 }} />
          )}
          {remainingCompanies.length ? (
            <div>
              <Divider sx={{ my: 0.5 }} />
              <Typography variant="textSmallBold" sx={{ pl: 1, color: 'grey.500' }}>
                My Workspaces
              </Typography>
              {remainingCompanies.map((remainingCompany) => (
                <Fragment key={remainingCompany.slug}>
                  <MenuItemButton
                    trackingTag="Change workspace"
                    onClick={() => handleChangeWorkspace(remainingCompany)}
                  >
                    <WorkspaceItemCard workspace={remainingCompany} />
                  </MenuItemButton>
                  <Divider sx={{ my: 0.5 }} />
                </Fragment>
              ))}
            </div>
          ) : (
            <Divider sx={{ my: 0.5 }} />
          )}

          <MenuItemButton
            onClick={() => {
              redirect(PublicURL.LOGOUT);
            }}
            trackingTag="Logout"
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
              <Typography>Logout</Typography>
            </Box>
          </MenuItemButton>
        </MenuList>
      </Menu>
    </>
  );
};

function MenuItemButton({
  onClick,
  children,
  trackingTag,
  disabled,
}: {
  onClick: () => void;
  trackingTag: string;
  children: ReactNode;
  disabled?: boolean;
}) {
  const { mixpanel } = useMixpanel();

  return (
    <MenuItem
      onClick={() => {
        mixpanel.click(trackingTag, 'settings navigation bar');
        onClick();
      }}
      disabled={disabled}
    >
      {children}
    </MenuItem>
  );
}
