import { Box, CircularProgress, FormHelperText, MenuItem, Select } from '@mui/material';
import { useItems } from 'features/Foresite/hooks/items';
import { Item } from 'types/Item';

type ItemSelectorProps = {
  item?: Item | null;
  setItem: (item: Item | null) => void;
  allowItem?: boolean;
  error?: string;
};

export const ItemSelector = ({
  item,
  setItem,
  allowItem = false,
  error,
}: ItemSelectorProps) => {
  const { items, isLoading } = useItems();

  // Function called to generate the list of options
  // available in the Design Milestones dropdown.
  const options = [
    ...(items ?? []).map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    }),
    ...(allowItem ? [{ value: -1, label: 'No Item' }] : []),
  ];

  const areOptions = !isLoading && !!options.length;
  const errorLabel =
    error || (!areOptions ? 'There are no V.O.Items created for this project' : '');

  return isLoading ? (
    <Box>
      <CircularProgress size={24} />
    </Box>
  ) : (
    <>
      <Select
        data-testid="item-selector"
        value={item?.id ?? (allowItem ? -1 : undefined)}
        onChange={(option) => {
          setItem(items?.find((item) => item.id === option.target.value) ?? null);
        }}
        displayEmpty
        renderValue={(value) => {
          if (!value) return 'Choose an Item';
          return item?.name;
        }}
        disabled={!areOptions}
        sx={{
          alignSelf: 'start',
          minWidth: '26rem',
          '& .MuiSelect-icon': {
            translate: '-8px 0',
          },
          '& .MuiSelect-select': {
            py: 1,
          },
        }}
        className="large"
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ px: 0 }}>
            <Box sx={{ pl: 2.75, pr: 2.75 }}>{option.label}</Box>
          </MenuItem>
        ))}
      </Select>
      {errorLabel ? (
        <FormHelperText
          sx={{ color: 'error.main' }}
          data-testid="milestone-required-error"
        >
          {errorLabel}
        </FormHelperText>
      ) : null}
    </>
  );
};
