import { ModelPermission, Permission } from 'types/User';
import { Permissions, PermissionType } from './Permissions';

const EXTERNAL_USER_PERMISSIONS: PermissionType[] = [
  Permissions.OVERVIEW_ACCESS,
  Permissions.OVERVIEW_WIDGET_CALIBRATE,
  Permissions.OVERVIEW_WIDGET_COMPONENT,
  Permissions.OVERVIEW_WIDGET_COST,
  Permissions.OVERVIEW_WIDGET_ESTIMATE,
  Permissions.OVERVIEW_WIDGET_ISSUE,
  Permissions.ADD_SCENARIO,
  Permissions.APPROVE_SCENARIO,
  Permissions.CHANGE_SCENARIO,
  Permissions.DELETE_SCENARIO,
  Permissions.APPROVE_SCENARIO_ITEM,
  Permissions.VISION_ACCESS,
  Permissions.VISION_WIDGET_CALIBRATE,
  Permissions.VISION_WIDGET_COST,
  Permissions.VISION_WIDGET_PROGRAM,
  Permissions.ADD_SCENARIO_ITEM,
  Permissions.VIEW_ITEM_MARKUP,
  Permissions.EDIT_OWN_SCENARIO_ITEM,
  Permissions.FORESITE_ACCESS,
  Permissions.TIMELINE_ACCESS,
  Permissions.COMPONENT_ACCESS,
  Permissions.REPORTING_ACCESS,
] as const;

// Backend returns several permissions, some of then not in use.
const permissionCodenameHash = Object.values(Permissions).reduce(
  (hash, codename) => ({ [codename]: true, ...hash }),
  {} as Record<PermissionType, true>,
);

export const checkIsPermissionInternal = (permission: Permission) =>
  permissionCodenameHash[permission.codename] &&
  !EXTERNAL_USER_PERMISSIONS.includes(permission.codename);

export const checkModelPermissionsAreInternal = (modelPermissions: ModelPermission[]) => {
  // If the modelPermission has any permission enabled that is not in the EXTERNAL_USER_PERMISSIONS
  // list, we consider that model as Internal.
  return modelPermissions.some((modelPermission) =>
    modelPermission.permissions.some(
      (permission) => permission.enabled && checkIsPermissionInternal(permission),
    ),
  );
};
