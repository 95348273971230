import { PrivateURL } from 'Urls';
import { ReactNode, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { Provider } from 'jotai';
import { projectIdAtom } from './atoms';
import { store } from './store';
import { DevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';

export const AtomsProvider = ({ children }: { children: ReactNode }) => {
  const projectMatch = useRouteMatch<{ projectId: string }>(PrivateURL.PROJECT_UPDATE);
  const legacyProjectMatch = useRouteMatch<{ projectId: string }>(
    PrivateURL.PROJECT_LEGACY_UPDATE,
  );
  const projectId =
    projectMatch?.params?.projectId === 'legacy'
      ? legacyProjectMatch?.params?.projectId
      : projectMatch?.params?.projectId;

  const selectedProjectId = useMemo(() => {
    if (!projectId) return null;

    const parsed = parseInt(projectId);
    if (isNaN(parsed)) return null;
    return parsed;
  }, [projectId]);

  store.set(projectIdAtom, selectedProjectId);

  return (
    <Provider store={store}>
      <DevTools position="bottom-right" store={store} />
      {children}
    </Provider>
  );
};
