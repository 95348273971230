import { SxProps, useTheme } from '@mui/material/styles';
import { getRangedHashFromNumber } from 'utils/helpers';
import { CircleChip } from './CircleChip';
import { Box, Theme, Tooltip } from '@mui/material';
import { MouseEventHandler } from 'react';
import { MemberChipTooltipTitle } from './MemberChipTooltipTitle';
import { Member, getMemberInitials } from 'components/CustomChips/helper';

type MemberChipProps = {
  member: Member;
  sx?: SxProps<Theme>;
  disableTooltipHoverListener?: boolean;
  isInactive?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
export const MemberChip = ({
  member,
  sx,
  disableTooltipHoverListener,
  isInactive,
  onClick,
}: MemberChipProps) => {
  const theme = useTheme();
  const scenarioChipIndex = getRangedHashFromNumber(
    member.id,
    0,
    theme.palette.other.projectMembersChip.length,
  );
  // TODO: styles needs to be separated per element
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chipSx: any = sx
    ? {
        width: 'width' in sx ? sx.width : null,
        height: 'height' in sx ? sx.height : null,
        borderRadius: 'borderRadius' in sx ? sx.borderRadius : null,
        '& .MuiChip-label': {
          fontSize: 'fontSize' in sx ? sx.fontSize : null,
          fontWeight: 'fontWeight' in sx ? sx.fontWeight : null,
        },
      }
    : null;

  const activeColor =
    sx && 'color' in sx
      ? sx.color
      : theme.palette.other.projectMembersChip[scenarioChipIndex].color;

  const activeBackgroundColor =
    sx && 'backgroundColor' in sx
      ? sx.backgroundColor
      : theme.palette.other.projectMembersChip[scenarioChipIndex].background;

  const label = getMemberInitials(member);

  return (
    <Tooltip
      placement="top"
      title={<MemberChipTooltipTitle user={member} />}
      disableHoverListener={disableTooltipHoverListener}
      arrow
    >
      <Box sx={sx} onClick={onClick}>
        <CircleChip
          sx={[
            {
              cursor: onClick ? 'pointer' : 'auto',
              background: isInactive ? theme.palette.grey['100'] : activeBackgroundColor,
              '& .MuiChip-label': {
                color: theme.palette.grey['100']
                  ? theme.palette.grey['700']
                  : activeColor,
              },
            },
            chipSx,
          ]}
          label={label}
        />
      </Box>
    </Tooltip>
  );
};
