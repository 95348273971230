import { faEllipsisVertical, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Menu, Typography } from '@mui/material';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { IconMenuItem } from 'components/IconMenuItem/IconMenuItem';
import { DeleteMenuItem } from 'components/IconMenuItem/DeleteMenuItem';
import { ProjectDashboard } from 'types/Dashboard';
import { useDeleteProjectDashboard } from 'features/project-dashboard/hooks/project-dashboard';
import { useState } from 'react';
import { useForesiteFlyout } from 'features/Foresite/hooks/ui';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { hasEditDashboardSettingPermission } from 'features/project-dashboard/hooks/dashboard-permission';

type ActionsMenuProps = {
  projectDashboard: ProjectDashboard;
};

export const ProjectDashboardTableActions = (props: ActionsMenuProps) => {
  const { deleteProjectDashboardMutation } = useDeleteProjectDashboard();
  const { confirmToast } = useToastDialogs();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const { setProjectDashboardDetailsId } = useForesiteFlyout();
  const { currentUser } = useCurrentUser();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleDelete = () => {
    handleCloseMenu();
    confirmToast({
      title: 'Delete Dashboard?',
      message: (
        <>
          {'The dashboard '}
          <Typography variant="textDefaultBold">{`“${props.projectDashboard.name}”`}</Typography>
          {' will be '}
          <Typography variant="textDefaultBold">{`permanently deleted`}</Typography>
          {', and it won’t be restored.'}
        </>
      ),
      handleSuccessCallback: () => {
        deleteProjectDashboardMutation.mutate(props.projectDashboard.id);
      },
    });
  };

  const handleEdit = () => {
    handleCloseMenu();
    setProjectDashboardDetailsId(props.projectDashboard.id);
  };
  const hasEditPerm = hasEditDashboardSettingPermission({
    user: currentUser,
    dashboard: props.projectDashboard,
  });
  return hasEditPerm ? (
    <>
      <IconButton
        id="ellipsis-button"
        aria-controls={isMenuOpen ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleMenuClick}
        sx={{ padding: '3px 10px', color: 'grey.400' }}
      >
        <FontAwesomeIcon icon={faEllipsisVertical} size="xs" />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'ellipsis-button',
        }}
      >
        {hasEditPerm
          ? [
              <IconMenuItem
                key="project-dashboard-table-edit"
                icon={faPencil}
                onClick={handleEdit}
              >
                Edit
              </IconMenuItem>,
              <DeleteMenuItem key="project-dashboard-table-delete" onClick={handleDelete}>
                Delete
              </DeleteMenuItem>,
            ]
          : null}
      </Menu>
    </>
  ) : null;
};
