import { User } from 'types/User';

export type Member = Pick<User, 'id' | 'first_name' | 'last_name'> &
  Partial<Pick<User, 'email'>>;

export function getMemberInitials(member: Member | undefined) {
  if (!member) return '';
  return member.first_name
    ? `${member.first_name[0]?.toUpperCase() || ''}${
        member.last_name?.[0]?.toUpperCase() || ''
      }`
    : member.email?.slice(0, 2).toUpperCase() || '';
}
