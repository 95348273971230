import {
  Box,
  AccordionSummary as MuiAccordionSummary,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';

import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccordionSummaryProps } from '@mui/material/AccordionSummary/AccordionSummary';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactNode } from 'react';
import { joinSx } from 'utils/helpers';
import { isString } from 'lodash-es';

export const CleanAccordionSummary = styled(
  ({
    expanded,
    label,
    icon,
    children,
    tooltipTitle,
    showTooltipOnLabel,
    ...props
  }: AccordionSummaryProps & {
    label: ReactNode;
    expanded?: boolean | undefined;
    icon?: IconProp;
    tooltipTitle?: ReactNode;
    showTooltipOnLabel?: boolean;
  }) => {
    const tooltipTitleWithDefault =
      tooltipTitle ??
      `${expanded ? 'Collapse' : 'Expand'} ${isString(label) ? label : ''}`;
    return (
      <Tooltip
        title={!showTooltipOnLabel ? tooltipTitleWithDefault : ''}
        placement="top-start"
        arrow
      >
        <MuiAccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronUp} size="lg" />}
          {...props}
          sx={joinSx(
            expanded
              ? {}
              : {
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'rotate(90deg)',
                  },
                },
            props.sx,
          )}
        >
          {icon && (
            <Box
              component={FontAwesomeIcon}
              icon={icon}
              sx={{ ml: 1 }}
              color="blue.700"
            />
          )}
          <Tooltip
            title={showTooltipOnLabel ? tooltipTitleWithDefault : ''}
            placement="top-start"
            arrow
          >
            <Typography variant="textLargeBold" sx={{ color: 'grey.500', ml: 1.5 }}>
              {label}
            </Typography>
          </Tooltip>
          {children}
        </MuiAccordionSummary>
      </Tooltip>
    );
  },
)(({ theme }) => ({
  flexDirection: 'row-reverse',
  padding: `0 ${theme.spacing(1)}`,
  '& .MuiPaper-root': {
    border: 'none',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
  },
  '&:hover .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.blue['700'],
  },
  '&:hover .MuiTypography-root': {
    color: theme.palette.blue['700'],
  },
}));
