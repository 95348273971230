import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { atom, useAtom } from 'jotai';
import { max, pick } from 'lodash-es';
import log from 'loglevel';
import { useCallback, useMemo } from 'react';
import { DesignMilestone } from 'types/DesignMilestones';
import { ProjectMilestoneSummary } from 'types/Project';
import { useQueryParam } from 'hooks/useQueryParam';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { queryKeys } from 'utils/reactQuery';
import { sortMilestoneByDateFn } from 'utils/helpers';
import { useMilestones } from './useMilestones';
import { LineItem } from 'types/Estimate';

const isDesignMilestone = (
  milestone: DesignMilestone | ProjectMilestoneSummary,
): milestone is DesignMilestone =>
  (milestone as DesignMilestone).total_cost !== undefined;

function getLastMilestoneWithEstimate<
  T extends DesignMilestone | ProjectMilestoneSummary,
>(milestones: T[]) {
  const index = milestones.findLastIndex(
    (milestone) =>
      milestone.has_estimate === true ||
      (isDesignMilestone(milestone) && milestone.total_cost !== 0),
  );
  if (index < 0) return null;

  return { index, milestone: milestones[index] };
}

export function getActiveMilestone<T extends DesignMilestone | ProjectMilestoneSummary>(
  milestones: T[],
  withEstimate: boolean = false,
) {
  const lastMilestoneWithEstimate = getLastMilestoneWithEstimate(milestones);
  if (withEstimate && lastMilestoneWithEstimate?.milestone) {
    return lastMilestoneWithEstimate.milestone;
  }

  if (
    lastMilestoneWithEstimate &&
    lastMilestoneWithEstimate.index > 0 &&
    lastMilestoneWithEstimate.index + 1 < milestones.length
  ) {
    return milestones[lastMilestoneWithEstimate.index + 1];
  } else {
    const today = new Date();
    const afterToday = milestones
      .filter((milestone) => new Date(milestone.date) >= today)
      .sort(sortMilestoneByDateFn);
    if (afterToday.length) {
      return afterToday[0];
    } else if (milestones.length) {
      return milestones[0];
    }
  }
  return null;
}

const activeMilestoneAtom = atom<DesignMilestone | ProjectMilestoneSummary | null>(null);
activeMilestoneAtom.debugLabel = 'activeMilestoneAtom';

export const useActiveMilestone = ({
  autoAssign = true,
  withEstimate = false,
}: { autoAssign?: boolean; withEstimate?: boolean } = {}) => {
  const query = useQueryParam<{ activeMilestoneId?: string }>();
  const activeMilestoneIdParam = parseInt(query?.activeMilestoneId || '0') || null;

  const [stateActiveMilestone, setActiveMilestone] = useAtom(activeMilestoneAtom);
  const cleanActiveMilestone = useCallback(
    () => setActiveMilestone(null),
    [setActiveMilestone],
  );
  const { milestones } = useMilestones();

  const activeMilestone: DesignMilestone | null = useMemo(() => {
    if (stateActiveMilestone) {
      return milestones.find(
        (milestone) => milestone.id === stateActiveMilestone.id,
      ) as DesignMilestone;
    }

    if ((!stateActiveMilestone && activeMilestoneIdParam) || activeMilestoneIdParam) {
      return (
        milestones.find((milestone) => milestone.id === activeMilestoneIdParam) ||
        ({
          id: activeMilestoneIdParam,
        } as DesignMilestone)
      );
    }

    if (autoAssign && milestones.length > 0) {
      return getActiveMilestone(milestones, withEstimate);
    }
    return null;
  }, [
    activeMilestoneIdParam,
    autoAssign,
    withEstimate,
    milestones,
    stateActiveMilestone,
  ]);

  return { activeMilestone, setActiveMilestone, cleanActiveMilestone };
};

const isMilestone = (
  milestoneData: Partial<DesignMilestone>,
): milestoneData is DesignMilestone => !!milestoneData && !!milestoneData.id;

export const useSaveMilestone = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const { setActiveMilestone } = useActiveMilestone();
  const queryClient = useQueryClient();

  const saveMilestoneMutation = useMutation({
    mutationFn: (milestoneData: Partial<DesignMilestone> | DesignMilestone) => {
      if (selectedProjectId) {
        let request;
        if (isMilestone(milestoneData)) {
          const endPoint = Resources.MILESTONE_BY_ID.replace(
            '<int:pk>',
            milestoneData.id.toString(),
          );
          request = ApiService.patch(endPoint, milestoneData);
        } else {
          const payload = {
            ...pick(milestoneData, ['date', 'name', 'short_name', 'actual_date']),
            project_id: selectedProjectId,
            project: selectedProjectId,
          };
          const endPoint = Resources.ALL_MILESTONES_BY_PROJECT_ID.replace(
            '<int:project_pk>',
            selectedProjectId.toString(),
          );
          request = ApiService.post(endPoint, payload);
        }
        return request.then((res) => res.data);
      } else {
        log.error('Milestone could not be save: no project selected');
        return Promise.reject(Error('Milestone could not be save: no project selected'));
      }
    },
    onMutate: async (milestoneData) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.project(selectedProjectId).milestones,
      });
      const previousMilestones: DesignMilestone[] | undefined = queryClient.getQueryData(
        queryKeys.project(selectedProjectId).milestones,
      );

      if (previousMilestones) {
        if (isMilestone(milestoneData)) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).milestones,
            (oldMilestones?: DesignMilestone[]) =>
              oldMilestones?.map((oldMilestone) =>
                oldMilestone.id === milestoneData.id
                  ? { ...oldMilestone, milestoneData }
                  : oldMilestone,
              ),
          );
        } else {
          const number = (max(previousMilestones.map((p) => p.number)) || 0) + 1;
          const code = `MS-${number?.toFixed(3)}`;
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).milestones,
            (oldMilestones?: Partial<DesignMilestone>[]) =>
              oldMilestones
                ?.concat({ ...milestoneData, number, code, id: 0 })
                ?.toSorted(sortMilestoneByDateFn),
          );
        }
        // TODO: Insert created milestone in the right place
      }
      return previousMilestones;
    },
    onSuccess: (result, milestoneData) => {
      if (!isMilestone(milestoneData)) {
        setActiveMilestone(result);
      }
      queryClient.invalidateQueries({ queryKey: queryKeys.projects.all });
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).missingData,
      });
    },
    onError: (error, _milestoneData, previousMilestones) => {
      if (previousMilestones) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).milestones,
          previousMilestones,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).componentsUsage,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).milestones,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).tvdForesite,
      });
    },
  });

  return { saveMilestoneMutation };
};

export const useDeleteMilestone = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const { activeMilestone, cleanActiveMilestone } = useActiveMilestone();
  const queryClient = useQueryClient();
  const { selectedProjectId } = useSelectedProjectId();

  const deleteMilestoneMutation = useMutation({
    mutationFn: (milestoneId: number) => {
      const endpoint = Resources.MILESTONE_BY_ID.replace(
        '<int:pk>',
        milestoneId.toString(),
      );
      return ApiService.delete(endpoint).then((res) => res.data);
    },
    onMutate: async (milestoneId) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.project(selectedProjectId).milestones,
      });
      const previousMilestones = queryClient.getQueryData(
        queryKeys.project(selectedProjectId).milestones,
      );

      if (previousMilestones) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).milestones,
          (oldMilestones?: DesignMilestone[]) =>
            oldMilestones?.filter((oldMilestone) => oldMilestone.id !== milestoneId),
        );
      }
      return previousMilestones;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.projects.all });
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).missingData,
      });
      onSuccess?.();
    },
    onError: (error, _scenarioId, previousMilestones) => {
      if (previousMilestones) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).milestones,
          previousMilestones,
        );
      }
      onError?.();
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: (_response, _error, milestoneId) => {
      if (milestoneId === activeMilestone?.id) {
        cleanActiveMilestone();
      }
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).milestones,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).componentsUsage,
      });
    },
  });

  return { deleteMilestoneMutation };
};

export const useProjectLegacyMilestone = () => {
  const { selectedProjectId } = useSelectedProjectId();

  const projectLegacyMilestoneQuery = useQuery({
    queryKey: queryKeys.legacyProject(selectedProjectId).milestone,
    queryFn: ({ signal }) => {
      const endPoint = Resources.ALL_MILESTONES_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        (selectedProjectId as unknown as number).toString(),
      );

      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data.at(0) as DesignMilestone,
      );
    },
    enabled: !!selectedProjectId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return { projectLegacyMilestoneQuery };
};

export const useMilestoneLineItems = (disabled?: boolean) => {
  const { selectedProjectId } = useSelectedProjectId();

  const { activeMilestone } = useActiveMilestone();

  const milestoneLineItemsQuery = useQuery({
    queryKey: queryKeys.milestone(activeMilestone?.id).lineItems,
    queryFn: ({ signal }) => {
      const endPoint = Resources.DESIGN_MILESTONES_LINE_ITEMS.replace(
        '<int:project_pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      ).replace(
        '<int:pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        activeMilestone!.id.toString(),
      );

      return ApiService.get(endPoint, { signal }).then((res) => res.data as LineItem[]);
    },
    enabled: !!selectedProjectId && !!activeMilestone && !disabled,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return { milestoneLineItemsQuery };
};

export const useSetActiveLastMilestoneWithEstimate = () => {
  const { milestones } = useMilestones();
  const { setActiveMilestone } = useActiveMilestone();

  const setActiveLastMilestoneWithEstimate = () => {
    const lastMilestoneWithEstimate = getLastMilestoneWithEstimate(milestones);
    if (lastMilestoneWithEstimate) {
      setActiveMilestone(lastMilestoneWithEstimate.milestone);
    }
  };

  return { setActiveLastMilestoneWithEstimate };
};
