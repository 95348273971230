import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { ItemOrScenarioStatus } from 'types/Item';
import { Scenario } from 'types/Scenario';
import { atomArrayWithReducer } from 'utils/atoms';
import { useItemsFocus } from './itemsFocus';
import log from 'loglevel';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { queryKeys } from 'utils/reactQuery';
import { useInvalidateTVDQueries } from 'features/TargetValueDesign/hooks/useInvalidateTVDQueries';
import { atom, useAtom } from 'jotai';
import { useAtomArrayWithReducerActions } from 'hooks/useAtomArrayWithReducerActions';
import { pluralize } from 'utils/pluralize';
import { useForesiteSummary } from 'features/Foresite/hooks/useForesiteSummary';
import { useBudgetAdjustedQuery } from 'features/TargetValueDesign/hooks/useBudgetAdjusted';
import { useItems } from 'features/Foresite/hooks/items';
import { sum } from 'lodash-es';

const scenariosFocusAtom = atomArrayWithReducer<number>([]);
scenariosFocusAtom.debugLabel = 'scenariosFocusAtom';
const scenariosHoverAtom = atom<Scenario | null>(null);
scenariosHoverAtom.debugLabel = 'scenariosHoverAtom';

export const useScenarios = () => {
  const { selectedProjectId } = useSelectedProjectId();

  const { itemsFocus } = useItemsFocus();

  const scenariosQuery = useQuery({
    queryKey: queryKeys.project(selectedProjectId).scenarios,
    queryFn: ({ signal }) => {
      const endPoint = Resources.ALL_SCENARIOS_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      );
      const params = {
        // Scenario status key
        status: itemsFocus,
      };
      return ApiService.get(endPoint, { signal, params }).then(
        (res) => (res.data ?? []) as Scenario[],
      );
    },
    enabled: !!selectedProjectId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return { scenariosQuery };
};

export const useScenario = (scenarioId: number | undefined) => {
  const { scenariosQuery } = useScenarios();

  const { data, ...rest } = scenariosQuery;
  return {
    scenario: data?.find((scenario: Scenario) => scenario.id === scenarioId),
    ...rest,
  };
};

export const useScenariosFocus = () => {
  const {
    array: scenariosFocus,
    toggle,
    clear: scenariosFocusClear,
    set: setScenarioFocus,
    add: addScenarioFocus,
    remove,
  } = useAtomArrayWithReducerActions(scenariosFocusAtom);
  const [scenarioHover, setScenarioHover] = useAtom(scenariosHoverAtom);

  const { resetItemsFocus } = useItemsFocus();

  const toggleScenariosFocus = (scenarioId: number) => {
    // will be removed
    if (scenariosFocus.includes(scenarioId)) {
      resetItemsFocus();
    }
    toggle(scenarioId);
  };

  const removeScenarioFocus = (scenarioIds: number[]) => {
    resetItemsFocus();
    scenarioIds.forEach((scenarioId) => {
      remove(scenarioId);
    });
  };

  return {
    scenariosFocus,
    toggleScenariosFocus,
    scenariosFocusClear,
    setScenarioFocus,
    addScenarioFocus,
    removeScenarioFocus,
    scenarioHover,
    setScenarioHover,
  };
};

const isNewScenario = (scenarioData: Partial<Scenario>) =>
  !scenarioData || scenarioData.id === undefined;

export const useSaveScenario = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const queryClient = useQueryClient();
  const { assignItemsToScenarioMutation } = useAssignItemsToScenario();
  const { itemsFocus, resetItemsFocus } = useItemsFocus();
  const { setScenarioFocus } = useScenariosFocus();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const saveScenarioMutation = useMutation({
    mutationFn: (scenarioData: Partial<Scenario>) => {
      let request;
      if (isNewScenario(scenarioData)) {
        if (selectedProjectId !== null) {
          const endPoint = Resources.ALL_SCENARIOS_BY_PROJECT_ID.replace(
            '<int:project_pk>',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selectedProjectId!.toString(),
          );
          request = ApiService.post(endPoint, scenarioData);
        } else {
          throw new Error('There is not selected Project');
        }
      } else {
        const endPoint = Resources.SCENARIO_BY_ID.replace(
          '<int:pk>',
          (scenarioData.id as number).toString(),
        );

        request = ApiService.patch(endPoint, scenarioData);
      }
      return request.then((res) => res.data);
    },
    onMutate: async (scenarioData) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.project(selectedProjectId).scenarios,
      });
      const previousScenarios = queryClient.getQueryData(
        queryKeys.project(selectedProjectId).scenarios,
      );

      if (previousScenarios && !isNewScenario(scenarioData)) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).scenarios,
          (oldScenarios?: Scenario[]) =>
            oldScenarios?.map((oldScenario) =>
              oldScenario.id === scenarioData.id
                ? { ...oldScenario, ...scenarioData }
                : oldScenario,
            ),
        );
      }
      return previousScenarios;
    },
    onSuccess: (result: Scenario, scenarioData) => {
      if (isNewScenario(scenarioData)) {
        const scenarioId = result.id;
        assignItemsToScenarioMutation.mutate({ scenarioId, items: itemsFocus });
        setScenarioFocus([scenarioId]);
        resetItemsFocus();
      }
    },
    onError: (error, _scenarioData, previousScenarios) => {
      if (previousScenarios) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).scenarios,
          previousScenarios,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).scenarios,
      });
      invalidateTVDQueries(selectedProjectId);
      // TODO: invalidate items?
    },
  });

  return { saveScenarioMutation };
};

export const useDeleteScenario = () => {
  const queryClient = useQueryClient();
  const { resetItemsFocus } = useItemsFocus();
  const { removeScenarioFocus } = useScenariosFocus();
  const { selectedProjectId } = useSelectedProjectId();
  const { successToast } = useToastDialogs();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const deleteScenarioMutation = useMutation({
    mutationFn: (scenarioId: number) => {
      const endPoint = Resources.SCENARIO_BY_ID.replace(
        '<int:pk>',
        scenarioId.toString(),
      );
      return ApiService.delete(endPoint, {}).then((res) => res.data);
    },
    onMutate: async (scenarioId) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.project(selectedProjectId).scenarios,
      });
      const previousScenarios = queryClient.getQueryData(
        queryKeys.project(selectedProjectId).scenarios,
      ) as Scenario[] | undefined;

      if (previousScenarios) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).scenarios,
          (oldScenarios: Scenario[] | undefined) =>
            oldScenarios?.filter((oldScenario) => oldScenario.id !== scenarioId),
        );
      }
      return previousScenarios;
    },
    onSuccess: (_data, scenarioId) => {
      successToast({
        title: 'Scenario deleted',
        text: 'Scenario has been deleted successfully',
      });
      removeScenarioFocus([scenarioId]);
      resetItemsFocus();
    },
    onError: (error, _scenarioId, previousScenarios) => {
      if (previousScenarios) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).scenarios,
          previousScenarios,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).scenarios,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).items,
      });
      invalidateTVDQueries(selectedProjectId);
    },
  });

  return { deleteScenarioMutation };
};

type AssignItemsToScenarioArgs = {
  scenarioId: number;
  items: number[];
};

export const useAssignItemsToScenario = () => {
  const queryClient = useQueryClient();
  const { selectedProjectId } = useSelectedProjectId();

  const assignItemsToScenarioMutation = useMutation({
    mutationFn: ({ scenarioId, items }: AssignItemsToScenarioArgs) => {
      const endPoint = Resources.BATCH_ASSIGN_BY_SCENARIO_ID.replace(
        '<int:scenario_pk>',
        scenarioId.toString(),
      );
      return ApiService.put(endPoint, { items }).then((res) => res.data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).items,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).scenarios,
      });
    },
  });
  return { assignItemsToScenarioMutation };
};

export const useApproveScenario = () => {
  const queryClient = useQueryClient();
  const { removeScenarioFocus } = useScenariosFocus();
  const { resetItemsFocus } = useItemsFocus();
  const { selectedProjectId } = useSelectedProjectId();
  const { successToast } = useToastDialogs();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const approveScenarioMutation = useMutation({
    mutationFn: ({
      scenarioIds,
      milestoneId,
    }: {
      scenarioIds: number[];
      milestoneId: number | null;
    }) => {
      return Promise.all(
        scenarioIds.map((scenarioId) => {
          const endPoint = Resources.APPROVE_SCENARIO_BY_ID.replace(
            '<int:pk>',
            scenarioId.toString(),
          );
          return ApiService.patch(endPoint, {
            milestone: milestoneId,
          }).then((res) => res.data);
        }),
      );
    },
    onMutate: async ({ scenarioIds }) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.project(selectedProjectId).scenarios,
      });
      const previousScenarios = queryClient.getQueryData(
        queryKeys.project(selectedProjectId).scenarios,
      );

      if (previousScenarios) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).scenarios,
          (oldScenarios: Scenario[] | undefined) =>
            oldScenarios?.map((oldScenario) =>
              scenarioIds.includes(oldScenario.id)
                ? { ...oldScenario, status: 'APPROVED' as ItemOrScenarioStatus }
                : oldScenario,
            ),
        );
      }
      return previousScenarios;
    },
    onSuccess: (_data, { scenarioIds }) => {
      successToast({
        title: `${pluralize('Scenario', scenarioIds.length)} approved`,
        text: `${pluralize('Scenario has', scenarioIds.length, false, 'Scenarios have')} been approved successfully`,
      });
      removeScenarioFocus(scenarioIds);
      resetItemsFocus();
    },
    onError: (error, _scenarioId, previousScenarios) => {
      if (previousScenarios) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).scenarios,
          previousScenarios,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).scenarios,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).items,
      });
      invalidateTVDQueries(selectedProjectId);
    },
  });
  return { approveScenarioMutation };
};

export const useRejectScenario = () => {
  const queryClient = useQueryClient();
  const { removeScenarioFocus } = useScenariosFocus();
  const { resetItemsFocus } = useItemsFocus();
  const { selectedProjectId } = useSelectedProjectId();
  const { successToast } = useToastDialogs();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const rejectScenarioMutation = useMutation({
    mutationFn: ({ scenarioIds }: { scenarioIds: number[] }) => {
      return Promise.all(
        scenarioIds.map((scenarioId) => {
          const endPoint = Resources.REJECT_SCENARIO_BY_ID.replace(
            '<int:pk>',
            scenarioId.toString(),
          );
          return ApiService.patch(endPoint).then((res) => res.data);
        }),
      );
    },
    onMutate: async ({ scenarioIds }) => {
      removeScenarioFocus(scenarioIds);
      resetItemsFocus();

      await queryClient.cancelQueries({
        queryKey: queryKeys.project(selectedProjectId).scenarios,
      });
      const previousScenarios = queryClient.getQueryData(
        queryKeys.project(selectedProjectId).scenarios,
      );

      if (previousScenarios) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).scenarios,
          (oldScenarios: Scenario[] | undefined) =>
            oldScenarios?.map((oldScenario) =>
              scenarioIds.includes(oldScenario.id)
                ? { ...oldScenario, status: 'REJECTED' as ItemOrScenarioStatus }
                : oldScenario,
            ),
        );
      }
      return previousScenarios;
    },
    onSuccess: (_data, { scenarioIds }) => {
      successToast({
        title: `${pluralize('Scenario', scenarioIds.length)} rejected`,
        text: `${pluralize('Scenario has', scenarioIds.length, false, 'Scenarios have')} been rejected successfully`,
      });
    },
    onError: (error, _scenarioIds, previousScenarios) => {
      if (previousScenarios) {
        queryClient.setQueryData(
          queryKeys.project(selectedProjectId).scenarios,
          previousScenarios,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).scenarios,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).items,
      });
      invalidateTVDQueries(selectedProjectId);
    },
  });
  return { rejectScenarioMutation };
};

export const useScenarioSummary = ({ scenarios }: { scenarios: Scenario[] }) => {
  const { scenariosFocus } = useScenariosFocus();

  const {
    foresiteSummaryQuery: { data: foresiteSummary, isLoading: isForesiteSummaryLoading },
  } = useForesiteSummary();
  const { budgetAdjustedQuery } = useBudgetAdjustedQuery();
  const { items, isItemsLoading } = useItems();
  const isBusy =
    isForesiteSummaryLoading ||
    !foresiteSummary ||
    budgetAdjustedQuery.isFetching ||
    budgetAdjustedQuery.isLoading ||
    isItemsLoading;

  if (isBusy) {
    return { isBusy };
  }

  let costVariation = 0;
  let indirectCostVariation = 0;
  let subtotalCostVariation = 0;
  let squareFootVariation = 0;

  const filteredScenarios = scenarios.filter((scenario) =>
    scenariosFocus.includes(scenario.id),
  );

  const cost = Math.trunc(
    filteredScenarios.reduce((acc, scenario) => acc + scenario.cost, 0),
  );
  const subtotalCost = Math.trunc(
    filteredScenarios.reduce(
      (acc, scenario) =>
        acc +
        sum(
          scenario.items.map(
            (itemId) => items?.find((item) => item.id === itemId)?.subtotal_cost || 0,
          ),
        ),
      0,
    ),
  );
  const indirectCost = Math.trunc(cost - subtotalCost);

  const currentBudgetCost = budgetAdjustedQuery.data?.cost ?? 0;
  const revisedCost = cost + currentBudgetCost;
  const revisedSubtotalCost = subtotalCost + currentBudgetCost;
  const revisedIndirectCost = indirectCost + currentBudgetCost;

  const revisedSqft = Math.trunc(
    filteredScenarios.reduce((acc, scenario) => acc + (scenario.square_foot ?? 0), 0) +
      (foresiteSummary.program_value ?? 0),
  );

  if (currentBudgetCost) {
    costVariation = (revisedCost * 100) / currentBudgetCost - 100;
    subtotalCostVariation = (revisedSubtotalCost * 100) / currentBudgetCost - 100;
    indirectCostVariation = (revisedIndirectCost * 100) / currentBudgetCost - 100;
  }

  if (foresiteSummary?.program_value) {
    squareFootVariation = (revisedSqft * 100) / foresiteSummary.program_value;
  }

  return {
    currentBudgetCost,
    subtotalCost,
    revisedSubtotalCost,
    subtotalCostVariation,
    indirectCost,
    indirectCostVariation,
    revisedIndirectCost,
    cost,
    costVariation,
    squareFootVariation,
    revisedCost,
    revisedSqft,
    isBusy,
  };
};
