import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { Project } from 'types/Project';
import { queryKeys } from 'utils/reactQuery';
import { useSelectedProjectId } from './useSelectedProjectId';

export const useProject = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const {
    data: project,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
    ...rest
  } = useQuery({
    queryKey: queryKeys.project(selectedProjectId).details,
    queryFn: ({ signal }) => {
      const endPoint = Resources.PROJECT_BY_ID.replace(
        '<int:pk>',
        (selectedProjectId ?? '') as string,
      );
      return ApiService.get(endPoint, { signal }).then((res) => res.data as Project);
    },
    staleTime: Infinity,
    retryOnMount: false,
    enabled: !!selectedProjectId,
  });

  return { ...rest, project, isProjectLoading, isProjectFetching };
};
