import { TYPOGRAPHY_VARIANTS } from 'utils/constants';

const fontFamilies = {
  raleway: "Raleway, 'Helvetica Neue', Arial, sans-serif",
  lato: "Lato, Courier, 'Liberation Mono', monospace",
};

const fontFamily = fontFamilies.raleway;

const defaults = {
  fontFamily: fontFamilies.raleway,
  fontWeight: 500,
};

const h1 = {
  fontSize: '2.25rem',
  lineHeight: '2.5rem',
  ...defaults,
};

const h1SemiBold = {
  ...h1,
  fontWeight: 600,
};

const h2 = {
  fontSize: '1.75rem',
  lineHeight: '2rem',
  ...defaults,
};

const h2Bold = {
  ...h2,
  fontWeight: 700,
};

const h2SemiBold = {
  ...h2,
  fontWeight: 600,
};

const h3 = {
  fontSize: '1.5rem',
  lineHeight: '1.875rem',
  ...defaults,
};

const h3Medium = {
  ...h3,
  fontWeight: 500,
};

const h3SemiBold = {
  ...h3,
  fontWeight: 600,
};

const h3Bold = {
  ...h3,
  fontWeight: 700,
};

const h4 = {
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  ...defaults,
};

const h4Semibold = {
  ...h4,
  fontWeight: 500,
};

const h4Bold = {
  ...h4,
  fontWeight: 700,
};

const h5 = {
  fontSize: '1.125rem',
  lineHeight: '1.625rem',
  ...defaults,
};

const h5SemiBold = {
  ...h5,
  fontWeight: 600,
};

const h5Bold = {
  ...h5,
  fontWeight: 700,
};

const body1 = {
  ...defaults,
  fontSize: '0.875rem',
};

const textDefault = body1;

const textDefaultMedium = {
  ...body1,
  fontWeight: 500,
};

const textDefaultSemiBold = {
  ...body1,
  fontWeight: 600,
};

const textDefaultBold = {
  ...body1,
  fontWeight: 700,
};

const textSmall = {
  ...body1,
  fontSize: '0.75rem',
};
const textSmallBold = { ...textSmall, fontWeight: 700 };
const textSmallSemiBold = { ...textSmall, fontWeight: 600 };

const textExtraSmall = {
  ...body1,
  fontSize: '0.625rem',
};
const textExtraSmallBold = { ...textExtraSmall, fontWeight: 700 };
const textExtraSmallSemiBold = { ...textExtraSmall, fontWeight: 600 };

const textLarge = {
  fontSize: '1rem',
  fontWeight: 500,
};
const textLargeBold = { ...textLarge, fontWeight: 700 };
const textLargeSemiBold = { ...textLarge, fontWeight: 600 };

const textExtraLarge = {
  fontSize: '1.125rem',
};
const textExtraLargeBold = { ...textExtraLarge, fontWeight: 700 };
const textExtraLargeSemiBold = { ...textExtraLarge, fontWeight: 600 };

const monospaceDefault = { ...defaults, fontFamily: fontFamilies.lato };

export const themeOptionsTypography = {
  [TYPOGRAPHY_VARIANTS.fontFamily]: fontFamily,
  [TYPOGRAPHY_VARIANTS.h1]: h1,
  [TYPOGRAPHY_VARIANTS.h1SemiBold]: h1SemiBold,
  [TYPOGRAPHY_VARIANTS.h2]: h2,
  [TYPOGRAPHY_VARIANTS.h2Bold]: h2Bold,
  [TYPOGRAPHY_VARIANTS.h2SemiBold]: h2SemiBold,
  [TYPOGRAPHY_VARIANTS.h3]: h3,
  [TYPOGRAPHY_VARIANTS.h3Medium]: h3Medium,
  [TYPOGRAPHY_VARIANTS.h3SemiBold]: h3SemiBold,
  [TYPOGRAPHY_VARIANTS.h3Bold]: h3Bold,
  [TYPOGRAPHY_VARIANTS.h4]: h4,
  [TYPOGRAPHY_VARIANTS.h4Semibold]: h4Semibold,
  [TYPOGRAPHY_VARIANTS.h4Bold]: h4Bold,
  [TYPOGRAPHY_VARIANTS.h5]: h5,
  [TYPOGRAPHY_VARIANTS.h5SemiBold]: h5SemiBold,
  [TYPOGRAPHY_VARIANTS.h5Bold]: h5Bold,
  [TYPOGRAPHY_VARIANTS.body1]: body1,
  [TYPOGRAPHY_VARIANTS.textDefault]: textDefault,
  [TYPOGRAPHY_VARIANTS.textDefaultMedium]: textDefaultMedium,
  [TYPOGRAPHY_VARIANTS.textDefaultSemiBold]: textDefaultSemiBold,
  [TYPOGRAPHY_VARIANTS.textDefaultBold]: textDefaultBold,
  [TYPOGRAPHY_VARIANTS.textSmall]: textSmall,
  [TYPOGRAPHY_VARIANTS.textSmallSemiBold]: textSmallSemiBold,
  [TYPOGRAPHY_VARIANTS.textSmallBold]: textSmallBold,
  [TYPOGRAPHY_VARIANTS.textExtraSmall]: textExtraSmall,
  [TYPOGRAPHY_VARIANTS.textExtraSmallSemiBold]: textExtraSmallSemiBold,
  [TYPOGRAPHY_VARIANTS.textExtraSmallBold]: textExtraSmallBold,
  [TYPOGRAPHY_VARIANTS.textLarge]: textLarge,
  [TYPOGRAPHY_VARIANTS.textLargeSemiBold]: textLargeSemiBold,
  [TYPOGRAPHY_VARIANTS.textLargeBold]: textLargeBold,
  [TYPOGRAPHY_VARIANTS.textExtraLarge]: textExtraLarge,
  [TYPOGRAPHY_VARIANTS.textExtraLargeSemiBold]: textExtraLargeSemiBold,
  [TYPOGRAPHY_VARIANTS.textExtraLargeBold]: textExtraLargeBold,
  [TYPOGRAPHY_VARIANTS.monospaceDefault]: monospaceDefault,
} as const;

export type TypographyKeys = keyof typeof TYPOGRAPHY_VARIANTS;

type TypographyVariantsType = {
  [key in TypographyKeys]: React.CSSProperties;
};
type TypographyVariantsOverrideType = {
  [key in TypographyKeys]: true;
};

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariants extends TypographyVariantsType {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariantsOptions extends Partial<TypographyVariantsType> {}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography/Typography' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyPropsVariantOverrides extends TypographyVariantsOverrideType {}
}
