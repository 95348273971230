import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

export function SearchForTextField(
  props: Pick<
    TextFieldProps,
    | 'value'
    | 'onChange'
    | 'inputRef'
    | 'disabled'
    | 'onKeyPress'
    | 'onKeyDown'
    | 'placeholder'
  >,
) {
  return (
    <TextField
      {...props}
      fullWidth
      placeholder={props.placeholder ?? 'Search for...'}
      data-testid="dropdown-search"
      sx={{
        '& .MuiInputBase-root fieldset': {
          borderRadius: 0,
          borderWidth: 0,
          borderBottomWidth: '1px',
        },
        '& .MuiInputBase-root.Mui-focused': {
          backgroundColor: 'blue.50',
          borderRadius: '0.5rem 0.5rem 0 0',
          '.MuiOutlinedInput-notchedOutline': (theme) => ({
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderBottom: '1px solid',
            borderColor: `${theme.palette.grey['200']} !important`,
          }),
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ color: 'grey.700', pr: 0.25 }}>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </InputAdornment>
        ),
        value: props.value,
        sx: { minWidth: '7rem' },
      }}
    />
  );
}
