import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Layout, PrivateURL, PublicURL } from 'Urls';
import PublicLayout from 'layouts/Public.layout';
import log from 'loglevel';
import { LAST_VISITED_PROJECT_ID, LAST_VISITED_PAGE } from 'utils/constants';
import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import { NotFoundView } from 'views/auth/NotFound404View';
import { isEmpty } from 'lodash-es';
import { getProjectCurrentStep, replaceProjectIdPath } from 'hooks/navigate';
import { useProjectById } from 'features/Projects/hook/useProjectById';
import { hasSession } from 'features/Auth/hook/sessionHelpers';

export const PublicRoute = (props: RouteProps) => {
  const location = useLocation();
  const localProjectId = localStorage.getItem(LAST_VISITED_PROJECT_ID);
  const shouldRedirectOnSession =
    hasSession() &&
    ([Layout.ROOT, PublicURL.LOGIN] as string[]).includes(location.pathname);
  const { projectByIdQuery } = useProjectById({
    id: localProjectId ? parseInt(localProjectId) : undefined,
    enabled: shouldRedirectOnSession,
  });

  if (projectByIdQuery.status === 'error') {
    localStorage.removeItem(LAST_VISITED_PROJECT_ID);
    return <NotFoundView />;
  }

  const isBusy = localProjectId && projectByIdQuery.isLoading;

  let redirectToPath: string | null = null;
  if (shouldRedirectOnSession) {
    log.debug('Set redirect as there is session');
    if (!isBusy) {
      if (!isEmpty(projectByIdQuery.data) && localProjectId) {
        redirectToPath =
          sessionStorage.getItem(LAST_VISITED_PAGE) ||
          replaceProjectIdPath(
            getProjectCurrentStep(projectByIdQuery.data),
            localProjectId,
          );
      } else {
        log.debug('Would redirect projects list');
        redirectToPath = PrivateURL.PROJECT_LIST;
      }
    }
  }

  return shouldRedirectOnSession && isBusy ? (
    <LoadingPage />
  ) : (
    <Route
      {...props}
      render={(renderProps) =>
        redirectToPath ? (
          <Redirect
            to={{
              pathname: redirectToPath,
              state: { from: renderProps.location },
            }}
          />
        ) : (
          <PublicLayout />
        )
      }
    />
  );
};
