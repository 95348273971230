import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { AddProjectMembersFlyout } from 'features/Projects/components/AddProjectMembersFlyout';
import { useState } from 'react';
import { SideBarListItem } from './SideBarListItem';

export const InviteMemberLinkItemAndModal = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleListItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFlyoutClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SideBarListItem
        icon={faUserPlus}
        label="Manage members"
        onClick={handleListItemClick}
      />

      <AddProjectMembersFlyout
        open={Boolean(anchorEl)}
        onClose={handleFlyoutClose}
        width="smplus"
        title="Manage members"
        data-testid="add-project-members-flyout-header"
      />
    </>
  );
};
