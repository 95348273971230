import { Button } from '@mui/material';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { useEffect } from 'react';
import { usePwa } from 'components/PwaProvider/usePwa';

function ReloadPrompt() {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = usePwa();

  const { infoToast } = useToastDialogs();

  useEffect(() => {
    if (!needRefresh) return;

    infoToast({
      title: 'New version available',
      text: 'An improved version of ConCntric was released, click on refresh to update!',
      actions: (
        <Button
          sx={{ mt: 1 }}
          onClick={async () => {
            await updateServiceWorker(true);
            setNeedRefresh(false);
            if (typeof window !== 'undefined') window.location.reload();
          }}
        >
          Refresh
        </Button>
      ),
      autoClose: false,
      sx: {
        height: '9rem',
      },
      onClose: () => {
        setNeedRefresh(false);
      },
    });
  }, [infoToast, needRefresh, setNeedRefresh, updateServiceWorker]);

  return null;
}

export default ReloadPrompt;
