import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import reportRepresentation1 from 'assets/img/report-representation-1.png';
import reportRepresentation2 from 'assets/img/report-representation-2.png';
import reportRepresentation3 from 'assets/img/report-representation-3.png';
import reportRepresentation4 from 'assets/img/report-representation-4.png';
import reportRepresentation5 from 'assets/img/report-representation-5.png';
import reportRepresentation6 from 'assets/img/report-representation-6.png';

import { ReportDownloadButtons } from 'features/Reports/components/ReportDownloadButtons';
import { ReportListProps } from 'types/Reports';

const images = [
  reportRepresentation1,
  reportRepresentation2,
  reportRepresentation3,
  reportRepresentation4,
  reportRepresentation5,
  reportRepresentation6,
];

export const ReportCardList = ({ reports }: ReportListProps) => {
  return (
    <Grid container sx={{ pt: 2.5 }} gap={3} justifyContent="start">
      {reports.map((report, index) => (
        <Grid data-testid="reports-cards" key={report.id} item xs={4} md={4} lg={3}>
          <Paper
            sx={{
              height: '100%',
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Stack direction="column">
              <Box
                component="img"
                src={images[index % images.length]}
                sx={{ height: 160, width: '100%', mb: 3 }}
              />

              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mb: 1,
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="textExtraLargeSemiBold"
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis', mr: 1 }}
                >
                  {report.name}
                </Typography>
              </Stack>

              <Typography
                variant="textDefault"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {report.description}
              </Typography>
            </Stack>
            <ReportDownloadButtons report={report} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
