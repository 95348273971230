import { Box, Paper, Stack, SxProps, Theme, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLockKeyhole,
  faDiamond as faRegularDiamond,
} from '@fortawesome/pro-regular-svg-icons';
import { faDiamond as faSolidDiamond } from '@fortawesome/pro-solid-svg-icons';

import { TimelineMarkerProps } from 'features/TimelineSetup/TimelineTable/MilestoneMarker/type';
import { joinSx } from 'utils/helpers';

export function MilestoneMarkerIcon({
  milestoneMarkerType,
  hideBackground,
  actualType,
  size = 15,
  sx,
}: Pick<TimelineMarkerProps, 'milestoneMarkerType' | 'hideBackground' | 'actualType'> & {
  size?: number;
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();

  let iconMode: 'solid' | 'regular' = 'solid';
  let iconType: 'diamond' | 'lock' = 'diamond';
  let iconColor = theme.palette.successGreen['700'];
  let backgroundColor = theme.palette.common.white;

  if (actualType === 'no_value') {
    iconColor = theme.palette.categoricPurple['200'];
  } else if (actualType === 'with_value') {
    iconColor = theme.palette.categoricPurple['400'];
  } else if (milestoneMarkerType === 'GMP_LOCK_DATE') {
    iconColor = theme.palette.primary.main;
    iconType = 'lock';
  } else if (
    milestoneMarkerType === 'current' ||
    milestoneMarkerType === 'has_estimate'
  ) {
    iconColor = theme.palette.successGreen['700'];
  } else if (milestoneMarkerType === 'forecasted') {
    iconColor = theme.palette.primary.main;
    backgroundColor = theme.palette.blue['100'];
  } else if (milestoneMarkerType === 'pending' || !milestoneMarkerType) {
    iconColor = theme.palette.grey['500'];
    iconMode = 'regular';
  } else {
    iconColor = theme.palette.grey['500'];
  }

  return (
    <Stack sx={joinSx({ height: `${size}px` }, sx)} direction="row" alignItems="center">
      <Box
        sx={{
          height: `${size}px`,
          width: `${size}px`,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {iconType === 'lock' ? (
          <>
            {!hideBackground ? (
              <Box
                sx={{
                  position: 'absolute',
                  width: `${size}px`,
                  height: `${size}px`,
                  borderRadius: '50%',
                  backgroundColor,
                }}
              />
            ) : null}

            <Box
              sx={{
                position: 'absolute',
                width: 14,
              }}
              component={FontAwesomeIcon}
              icon={faLockKeyhole}
              color={iconColor}
            />
          </>
        ) : (
          <>
            {!hideBackground ? (
              <Paper
                sx={{
                  position: 'absolute',
                  width: `${size}px`,
                  height: `${size}px`,
                  borderRadius: '50%',
                  backgroundColor,
                }}
              />
            ) : null}

            <Box
              sx={{
                position: 'absolute',
                height: `${size * 0.8}px`,
                width: `${size * 0.8}px`,
              }}
              component={FontAwesomeIcon}
              icon={iconMode === 'regular' ? faRegularDiamond : faSolidDiamond}
              color={iconColor}
            />
          </>
        )}
      </Box>
    </Stack>
  );
}
