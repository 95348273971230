import { faHexagonXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, AlertProps, styled } from '@mui/material';

export const Toast = styled((props: AlertProps) => (
  <Alert
    {...props}
    iconMapping={
      props.iconMapping ?? {
        error: <FontAwesomeIcon icon={faHexagonXmark} />,
      }
    }
  />
))(({ theme }) => {
  return {
    width: '21.875rem',
    height: '8.25rem',
    position: 'fixed',
    background: 'white',
    top: `calc(${theme.navBarHeight} + 1rem)`,
    right: '1rem',
    zIndex: 1500,
    paddingTop: 1,
    boxShadow: '0px 2px 16px rgba(12, 14, 19, 0.18)',
    borderRadius: '0.625rem',
    color: `${theme.palette.text.primary}`,
    borderTopStyle: 'solid',
    borderTopWidth: '0.625rem',
    overflow: 'hidden',
    '&.MuiAlert-standardSuccess': {
      borderTopColor: theme.palette.successGreen[700],
    },
    '&.MuiAlert-standardSuccess .MuiAlert-icon': {
      color: `${theme.palette.successGreen[700]}`,
    },
    '&.MuiAlert-standardInfo': {
      borderTopColor: theme.palette.blue[700],
    },
    '&.MuiAlert-standardInfo .MuiAlert-icon': {
      color: `${theme.palette.blue[700]}`,
    },
    '&.MuiAlert-standardError': {
      borderTopColor: theme.palette.cautionRed[700],
    },
    '&.MuiAlert-standardError .MuiAlert-icon': {
      color: `${theme.palette.cautionRed[700]}`,
    },

    '.MuiAlertTitle-root ': {
      marginTop: '0px',
      ...theme.typography.textDefaultBold,
    },
    ...theme.typography.textDefault,
  };
});
