import { atomWithStorage } from 'jotai/utils';
import { DEFAULT_PROJECT_DASHBOARD_PORTFOLIO, ProjectDashboard } from 'types/Dashboard';
import { useAtomWithUserId } from 'hooks/useAtomWithUserId';
import usePermissions from 'features/Auth/hook/usePermissions';
import { pick } from 'lodash-es';
import { useMemo } from 'react';
import { useProjectDashboards } from 'features/project-dashboard/hooks/project-dashboard';
import { isPortfolioDashboard } from 'features/project-dashboard/helpers';

const recentsProjectDashboardAtom = atomWithStorage<ProjectDashboard[]>(
  'concntric-recent-project-dashboard-4',
  [],
  undefined,
  { getOnInit: true },
);

export function useRecentProjectDashboard() {
  const [recentsProjectDashboard, setRecentsProjectDashboard]: [
    ProjectDashboard[],
    (update: ProjectDashboard[]) => void,
  ] = useAtomWithUserId(recentsProjectDashboardAtom);
  const { hasPerm, Permissions } = usePermissions();
  const { projectDashboardsQuery } = useProjectDashboards();

  const dashboardOpened = (dashboard: ProjectDashboard) => {
    let updatedDashboards =
      recentsProjectDashboard?.filter((d) => d.id !== dashboard.id) || [];
    if (updatedDashboards.length < 4) {
      updatedDashboards = [
        ...updatedDashboards,
        pick(dashboard, ['id', 'name', 'type'] as (keyof ProjectDashboard)[]),
      ];
    } else {
      updatedDashboards = [
        ...updatedDashboards.slice(1),
        pick(dashboard, ['id', 'name', 'type'] as (keyof ProjectDashboard)[]),
      ];
    }
    setRecentsProjectDashboard(updatedDashboards);
  };
  const hasPortfolioAccess = hasPerm(Permissions.PORTFOLIO_ACCESS);

  const recents = useMemo(() => {
    const dashboards = projectDashboardsQuery.data;
    if (!projectDashboardsQuery.isSuccess || !dashboards) return [];

    const recentFilteredDashboards =
      recentsProjectDashboard?.filter(
        (recent) =>
          !isPortfolioDashboard(recent.id) &&
          dashboards.find((pd) => pd.id === recent.id),
      ) || [];
    if (
      !recentFilteredDashboards.find(
        (p) => p.id === DEFAULT_PROJECT_DASHBOARD_PORTFOLIO,
      ) &&
      recentFilteredDashboards.length < 4 &&
      hasPortfolioAccess
    ) {
      return [
        ...recentFilteredDashboards,
        {
          id: DEFAULT_PROJECT_DASHBOARD_PORTFOLIO,
          name: 'Portfolio',
          type: 'INTERNAL',
        } as ProjectDashboard,
      ];
    } else {
      return recentFilteredDashboards;
    }
  }, [
    hasPortfolioAccess,
    projectDashboardsQuery.data,
    projectDashboardsQuery.isSuccess,
    recentsProjectDashboard,
  ]);
  return {
    recentsProjectDashboard: recents || [],
    dashboardOpened,
  };
}
