import { TextFieldClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { themeOptionsTypography } from '../typography';

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    small: true;
    medium: true;
    large: true;
  }
}

type MuiTextFieldStyleOverrides = Partial<
  OverridesStyleRules<keyof TextFieldClasses, 'MuiTextField', Theme>
>;

const DEFAULT_SIZE = 'medium';

export const MuiTextField = {
  defaultProps: {
    InputLabelProps: {
      shrink: true,
    },
    inputProps: {
      'data-1p-ignore': true,
    },
    size: DEFAULT_SIZE,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      // this handles the case when the textfield is rendered via another
      // component, eg: DesktopDatePicker
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const textFieldSlotProps = ownerState.slotProps?.textField as unknown;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      const hasHelperText = ownerState.helperText || textFieldSlotProps?.helperText;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const hasError = ownerState.error || textFieldSlotProps?.error;

      return {
        // Set place to the potential error message to avoid the size change jump
        '&.gapForHelperText': {
          marginBottom: !hasHelperText && !hasError ? '26.234px' : 0,
        },
        '& .MuiInputLabel-root': {
          // CPE-664: override label styles to display them always above the input.
          fontSize: theme.textField.label.fontSize,
          fontWeight: 600,
          color: `${theme.palette.text.primary} !important`,
          position: 'relative',
          transform: 'none',
          marginBottom: theme.textField.label.mb,
          overflow: 'inherit',
        },
        '& .MuiInputLabel-asterisk': {
          color: `${theme.palette.text.primary} !important`,
        },
        '& .MuiFormHelperText-root': {
          ...themeOptionsTypography.textDefault,
          marginLeft: 0,
        },
        '& .MuiInputBase-root': {
          // We only define height here. For other customizations, use MuiOutlinedInput
          height: ownerState.multiline
            ? undefined
            : theme.textField.size[ownerState.size ?? DEFAULT_SIZE],

          // bug?
          '& .MuiOutlinedInput-input.MuiInputBase-input::placeholder': {
            opacity: '0.43 !important',
          },
        },
        // Seems to be a bug where size is ignored in some components, like DatePicker
        '&.large': {
          '& .MuiInputBase-root': {
            height: theme.textField.size.large,
          },
        },
      };
    },
  } as MuiTextFieldStyleOverrides,
};
