import { Button, ButtonProps, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSidebarFlip } from '@fortawesome/pro-regular-svg-icons';
import { useMixpanel } from 'hooks/useMixpanel';

export function OpenFlyoutButton({
  tooltipText,
  trackingTag,
  ...props
}: ButtonProps & { to?: string; tooltipText?: string; trackingTag: string }) {
  const { mixpanel } = useMixpanel();

  return (
    <Tooltip title={tooltipText ?? 'Open in side peek'} placement="right" arrow>
      <Button
        variant="greyGhost"
        size="small"
        startIcon={<FontAwesomeIcon icon={faSidebarFlip} />}
        {...props}
        onClick={(...params) => {
          mixpanel.click('open', trackingTag);
          props.onClick?.(...params);
        }}
      >
        Open
      </Button>
    </Tooltip>
  );
}
