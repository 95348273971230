import { useTheme } from '@mui/material';
import { atom, useAtom } from 'jotai';
import { useQueryParam } from './useQueryParam';

type SidebarState = 'hidden' | 'collapsed' | 'expanded';

const sidebarStateAtom = atom<SidebarState>('hidden');

export const useSidebarState = () => {
  const [sidebarState, setSidebarState] = useAtom(sidebarStateAtom);

  return { sidebarState, setSidebarState };
};

export const useSidebar = () => {
  const { sidebarState, setSidebarState } = useSidebarState();
  const theme = useTheme();

  const isSidebarCollapsed = sidebarState === 'collapsed';
  const isSidebarHidden = sidebarState === 'hidden';

  const toggleSidebar = () => {
    if (isSidebarCollapsed) {
      setSidebarState('expanded');
    } else {
      setSidebarState('collapsed');
    }
  };

  const sidebarWidths: Record<SidebarState, number | string> = {
    hidden: 0,
    expanded: theme.sideBarWidth,
    collapsed: theme.sideBarCollapsedWidth,
  };

  const sidebarWidth = sidebarWidths[sidebarState];
  return {
    sidebarState,
    setSidebarState,
    toggleSidebar,
    sidebarWidth,
    isSidebarCollapsed,
    isSidebarHidden,
  };
};

export const useShowStepper = () => {
  const query = useQueryParam<{ showStepper?: string }>();
  const showStepperQueryParam = query?.showStepper;
  return showStepperQueryParam === 'true';
};
