import { Box, LinearProgress } from '@mui/material';
import LogoImage from 'assets/img/concntric-logo-light.svg';

export const LoadingPage = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }}
  >
    <Box
      component="img"
      alt="ConCntric"
      src={LogoImage}
      sx={{ height: '8rem', mt: '10rem', mb: '5rem' }}
    />
    <Box sx={{ width: '50%' }}>
      <LinearProgress />
    </Box>
  </Box>
);
