import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { Project } from 'types/Project';
import { queryKeys } from 'utils/reactQuery';

export function useProjectById({
  id,
  enabled = true,
}: {
  id?: number;
  enabled?: boolean;
}) {
  const projectByIdQuery = useQuery({
    queryKey: queryKeys.project(id).details,
    queryFn: ({ signal }) => {
      const endPoint = Resources.PROJECT_BY_ID.replace('<int:pk>', `${id}`);
      return ApiService.get(endPoint, { signal }).then((res) => res.data as Project);
    },
    staleTime: Infinity,
    retryOnMount: false,
    enabled: Boolean(enabled && !!id),
  });

  return { projectByIdQuery };
}
