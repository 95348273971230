import { useToastDialogs } from 'hooks/useToastDialogs';
import { useMemo, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { isEqual, pick } from 'lodash-es';
import { FlyoutDialog } from 'components/FlyoutDialog';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { FlyoutDialogTitleIcon } from 'components/FlyoutDialog/FlyoutDialogTitleIcon';
import { faChartSimple } from '@fortawesome/pro-regular-svg-icons';
import {
  isNewProjectDashboardByDetailsId,
  useProjectDashboard,
  useSaveProjectDashboard,
} from 'features/project-dashboard/hooks/project-dashboard';
import { useForesiteFlyout } from 'features/Foresite/hooks/ui';
import { ProjectDashboard } from 'types/Dashboard';
import {
  EditingProjectDashboard,
  formValuesKeys,
  projectDashboardSchema,
} from 'features/project-dashboard/helpers';
import { ProjectDashboardModalActions } from 'features/project-dashboard/modal/ProjectDashboardModalActions';
import { DebouncedTextField } from 'components/DebouncedTextField/DebouncedTextField';
import { ProjectDashboardVisibilitySelect } from 'features/project-dashboard/modal/ProjectDashboardVisibilitySelect';
import { ProjectDashboardGroupSelect } from 'features/project-dashboard/table/ProjectDashboardGroupSelect';
import { ProjectDashboardProjectsSection } from 'features/project-dashboard/modal/ProjectDashboardProjectsSection';
import { ProjectDashboardMembersSection } from 'features/project-dashboard/modal/ProjectDashboardMembersSection';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { useProjectGroups } from 'features/Projects/hook/project-groups';
import { useIsExternalUser } from 'hooks/useIsExternalUser';
import { hasEditDashboardSettingPermission } from 'features/project-dashboard/hooks/dashboard-permission';
import { ImageUploader } from 'features/Projects/components/ImageUploader/ImageUploader';

export const ProjectDashboardDetailsModal = () => {
  const { projectDashboardDetailsId, closeFlyouts } = useForesiteFlyout();
  const { saveProjectDashboardMutation } = useSaveProjectDashboard();
  const { projectDashboardQuery } = useProjectDashboard({
    projectDashboardId: projectDashboardDetailsId,
  });
  const projectDashboard = projectDashboardQuery.data;
  const { currentUser } = useCurrentUser();
  const { confirmToast } = useToastDialogs();
  const formikRef = useRef<FormikProps<EditingProjectDashboard>>(null);
  const { projectGroupsQuery } = useProjectGroups();
  const isExternalUser = useIsExternalUser();

  const isNewItem = isNewProjectDashboardByDetailsId(projectDashboardDetailsId);
  const isLoading =
    (!isNewItem && projectDashboardQuery.isLoading) || projectGroupsQuery.isLoading;
  const isSaving = saveProjectDashboardMutation.isPending;
  const isBusy = isLoading || isSaving;
  const readOnly =
    isBusy ||
    (projectDashboard &&
      !hasEditDashboardSettingPermission({
        user: currentUser,
        dashboard: projectDashboard,
      }));

  const formInitialValues = useMemo(() => {
    return (
      projectDashboard
        ? {
            ...(pick<ProjectDashboard>(
              projectDashboard,
              formValuesKeys,
            ) as EditingProjectDashboard),
            project_group_ids: projectDashboard.project_groups?.map((p) => p.id) ?? [],
            project_ids: projectDashboard.projects?.map((p) => p.id) ?? [],
            users: projectDashboard.users || [],
            memberships: projectDashboard.memberships || [],
            created_at: projectDashboard.created_at,
            created_by: projectDashboard.created_by,
          }
        : {
            name: '',
            description: '',
            type: 'EXTERNAL',
            project_group_ids: [],
            project_ids: [],
            related_projects: [],
            projects: [],
            users: [],
            memberships: [],
            created_at: new Date().toISOString(),
            created_by: currentUser?.id,
          }
    ) as EditingProjectDashboard;
  }, [currentUser?.id, projectDashboard]);

  const formLeavingConfirmation = (callback?: () => void) => {
    const initialValue = {
      ...formInitialValues,
    };
    if (!isEqual(initialValue, formikRef.current?.values)) {
      confirmToast({
        title: "Your changes won't be saved",
        message: "We won't be able to save your data if you move away from this page.",
        variant: 'info',
        cancelButtonLabel: 'Go back',
        buttonLabel: 'Discard changes',
        handleSuccessCallback: () => {
          callback?.();
        },
      });
    } else {
      setTimeout(() => {
        callback?.();
      }, 0);
    }
  };

  const handleModalClose = () => formLeavingConfirmation(closeFlyouts);
  const handleDone = () => {
    setTimeout(() => formikRef.current?.handleSubmit(), 0);
  };

  return (
    <FlyoutDialog
      open={!!projectDashboardDetailsId}
      onClose={handleModalClose}
      width="sm"
      titleActions={
        <>
          {!isNewItem && !readOnly && projectDashboard ? (
            <ProjectDashboardModalActions
              projectDashboard={projectDashboard}
              closeFlyout={closeFlyouts}
            />
          ) : null}
        </>
      }
    >
      <>
        <DialogTitle sx={{ px: 3, pt: 2, pb: 2 }}>
          {isNewItem ? (
            <>
              <FlyoutDialogTitleIcon icon={faChartSimple} />
              <Typography variant="h3" component="span">
                Add new dashboard
              </Typography>
            </>
          ) : projectDashboard ? (
            <>
              <FlyoutDialogTitleIcon icon={faChartSimple} />
              <Typography variant="h3" component="span">
                {projectDashboard.name}
              </Typography>
            </>
          ) : null}
          {isSaving && <LinearProgress />}
        </DialogTitle>

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress size={30} />
          </Box>
        ) : (
          <DialogContent
            sx={{
              pb: 5,
              px: 3,
            }}
            key={projectDashboardDetailsId}
          >
            <Formik
              initialValues={formInitialValues}
              validationSchema={projectDashboardSchema}
              onSubmit={(values) => {
                saveProjectDashboardMutation.mutate(values, {
                  onSuccess: () => {
                    closeFlyouts();
                  },
                });
              }}
              innerRef={formikRef}
              enableReinitialize
            >
              {({ values, setFieldValue, handleBlur, handleChange, touched, errors }) => {
                return (
                  <Grid
                    container
                    columnSpacing={2}
                    rowSpacing={2}
                    sx={{ position: 'relative' }}
                  >
                    <Grid item xs={5}>
                      <Box sx={{ pt: 1 }}>
                        <ImageUploader
                          onDrop={(picture) => setFieldValue('pictureFile', picture)}
                          picture={values.picture}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                    >
                      <DebouncedTextField
                        value={values.name}
                        placeholder="Name..."
                        label="Name"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        fullWidth
                        error={!!(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        required
                        disabled={readOnly}
                      />
                      <TextField
                        id="description"
                        name="description"
                        multiline
                        rows={2}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        type="text"
                        fullWidth
                        placeholder="Add a description..."
                        error={!!(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                        label="Description"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ProjectDashboardVisibilitySelect
                        value={values.type}
                        readOnly={readOnly || isExternalUser || !!projectDashboard?.id}
                        onChange={(type) => setFieldValue('type', type)}
                        onBlur={handleBlur}
                        name="type"
                        error={Boolean(touched.type && errors.type)}
                        helperText={touched.type && errors.type}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectDashboardGroupSelect
                        label="Project Groups"
                        disabled={readOnly}
                        helperText={
                          touched.project_group_ids
                            ? errors.project_group_ids?.toString()
                            : ''
                        }
                        selectedFields={values.project_group_ids}
                        setSelectedFields={(updatedGroups) => {
                          setFieldValue('project_group_ids', updatedGroups);
                          setFieldValue(
                            'project_groups',
                            updatedGroups.map((gId) =>
                              projectGroupsQuery.data?.find((pg) => pg.id === gId),
                            ),
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectDashboardProjectsSection readOnly={readOnly} />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectDashboardMembersSection readOnly={readOnly} />
                    </Grid>
                  </Grid>
                );
              }}
            </Formik>
          </DialogContent>
        )}

        {!isLoading && !readOnly && (
          <DialogActions
            sx={{
              padding: ({ spacing }) => `${spacing(2)} ${spacing(6)}`,
              borderTop: 1,
              borderColor: 'divider',
              justifyContent: 'flex-start',
              gap: 3,
            }}
          >
            <Button onClick={handleDone}>Save</Button>
          </DialogActions>
        )}
      </>
    </FlyoutDialog>
  );
};
