import { Box, DialogTitle, IconButton, Typography, Stack } from '@mui/material';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactNode } from 'react';

type ModalTitleWithIconAndCloseProps = {
  icon?: IconProp;
  title: ReactNode;
  subTitle?: string;
  onModalClose?: () => void;
};

export const ModalTitleWithIconAndClose = (props: ModalTitleWithIconAndCloseProps) => {
  return (
    <DialogTitle>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          data-testid="dialog-header"
        >
          {props.icon && (
            <Box
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: (theme) => theme.palette.blue[200],
                color: (theme) => theme.palette.blue[700],
                width: '2rem',
                height: '2rem',
                borderRadius: '50%',
                padding: '3px',
                marginRight: (theme) => theme.spacing(2),
              }}
            >
              <FontAwesomeIcon size="xs" icon={props.icon} />
            </Box>
          )}
          <Box
            component="span"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: 1,
            }}
            data-test-id="modal-title"
          >
            {props.title}
          </Box>

          {props.onModalClose && (
            <IconButton
              onClick={props.onModalClose}
              sx={{ height: '2rem', width: '2rem', fontSize: '1.5rem' }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          )}
        </Box>

        {props.subTitle !== '' ? (
          <Typography variant="body1">{props.subTitle}</Typography>
        ) : null}
      </Stack>
    </DialogTitle>
  );
};
