import log from 'loglevel';
import { isPreview, isProdBuild } from 'utils/environment';
import Cookies from 'js-cookie';
import { TOKEN_SS_KEY } from '../constants';
import { Session } from '../types';

const getDomain = () => {
  if (isPreview()) {
    return import.meta.env.VITE_VERCEL_BRANCH_URL;
  }

  // Get the 3 last elements of the hostname
  return new URL(window.location.href).hostname.split('.').slice(-3).join('.');
};

const cookieConfig = {
  domain: `.${getDomain()}`,
  path: '/',
  secure: !isPreview() && isProdBuild() && import.meta.env.VITE_E2E !== 'true',
};

export const setCookie = (key: string, value: string) => {
  Cookies.set(key, value, cookieConfig);
};

export const getCookie = (key: string) => {
  return Cookies.get(key);
};

export const removeCookie = (key: string) => {
  Cookies.remove(key, cookieConfig);
};

export const hasSession = () => !!getSession();

export const setSession = (session: Session) => {
  if (session.access) {
    setCookie(TOKEN_SS_KEY, JSON.stringify(session));
  }
};

export const getSession = (): Session | null => {
  const sessionCookie = Cookies.get(TOKEN_SS_KEY);
  return sessionCookie ? JSON.parse(sessionCookie) : null;
};

export const clearSession = () => {
  log.debug('Clearing session');
  removeCookie(TOKEN_SS_KEY);
};
