import { isArray } from 'lodash-es';
import { AccessScopeBase, AccessScopeTree } from 'types/User';

export const mapAccessScopesByParent = (accessScopes: AccessScopeBase[]) =>
  accessScopes.reduce(
    (acc, accessScope) => {
      const map = { ...acc };
      if (!Array.isArray(map[accessScope.parent ?? 0])) {
        map[accessScope.parent ?? 0] = [];
      }
      return {
        ...map,
        [accessScope.parent ?? 0]: map[accessScope.parent ?? 0].concat(accessScope),
      };
    },
    {} as Record<number, AccessScopeBase[]>,
  );

export const makeAccessScopeTree = (
  currentAccessScope: AccessScopeTree | AccessScopeBase,
  parentMap: Record<number, AccessScopeBase[]>,
  level = 0,
): AccessScopeTree => {
  const tree = { ...currentAccessScope, level } as AccessScopeTree;
  if (parentMap[currentAccessScope.id]) {
    tree.children = parentMap[currentAccessScope.id].map((scope) =>
      makeAccessScopeTree(scope, parentMap, level + 1),
    );
  }
  return tree;
};

export const findAccessScope = (
  accessScopeId: number,
  accessScopesTree: AccessScopeTree | AccessScopeTree[],
): AccessScopeTree | undefined => {
  const accessScopeArray: AccessScopeTree[] = isArray(accessScopesTree)
    ? accessScopesTree
    : [accessScopesTree];

  for (let index = 0; index < accessScopeArray.length; index++) {
    const accessScope = accessScopeArray[index];

    if (accessScope.id === accessScopeId) {
      return accessScope;
    }
    const foundChildAccessScope =
      accessScope.children && findAccessScope(accessScopeId, accessScope.children);
    if (foundChildAccessScope) return foundChildAccessScope;
  }
  return undefined;
};

export const getPlainAccessScopes = (accessScope: AccessScopeTree) => {
  const plainAccessScopes = [accessScope];
  accessScope.children?.forEach((child) => {
    plainAccessScopes.push(...getPlainAccessScopes(child));
  });
  return plainAccessScopes;
};

export const getAccessScopeFullPath = (
  accessScopeId: number,
  accessScopeTreeHash: Record<number, AccessScopeTree>,
) => {
  let currentNode = accessScopeTreeHash[accessScopeId];
  if (!currentNode) return '';
  if (currentNode.parent === null) return currentNode.name;
  const path = [];
  while (currentNode && currentNode.parent !== null) {
    path.unshift(currentNode.name);
    currentNode = accessScopeTreeHash[currentNode.parent];
  }
  return path.join(' / ');
};
