import {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {
  faAlignLeft,
  faArrowRight,
  faCheck,
  faCirclePlus,
  faDiagramProject,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryAccordion } from './CategoryAccordion';
import { escapeRegExp } from 'lodash-es';
import { ModalTitleWithIconAndClose } from 'components/ModalTitleWithIconAndClose/ModalTitleWithIconAndClose';
import { OmniClassCategory } from 'types/OmniClassCategory';
import { useDebouncedCallback } from 'use-debounce';
import { HighlightSearch } from './HighlightSearch';
import { getCategoryAccordionClass, selectedCategoryClass } from './utils';
import { isElementInViewport } from 'utils/helpers';
import { FavoriteCategories } from './FavoriteCategories';
import { RecentCategories } from './RecentCategories';

// Function that gets an array of the search string matches and the matches counter
// Every match is an array with the category full path
const filterCategoriesBySearchString = (
  hashedCategories: Record<number, OmniClassCategory>,
  filterString: string,
  parentPath?: OmniClassCategory[],
): [OmniClassCategory[][], number] => {
  return Object.values(hashedCategories).reduce(
    ([filteredCategories, counter], category) => {
      let nextFilteredCategories = [...filteredCategories];
      let nextCounter = counter;

      // Check if the current Category name match the search string
      // It does not mather if it is an leaf or a node

      const words = filterString.trim().split(/\s+/);
      if (
        words.every((word) => new RegExp(escapeRegExp(word), 'gi').test(category.name))
      ) {
        const fullCategoryPath = parentPath ? parentPath.concat(category) : [category];

        nextFilteredCategories = nextFilteredCategories.concat([fullCategoryPath]);
        nextCounter += 1;
      }
      // Search though its children node/leaf, if it has.
      if (category.subCategories) {
        const [filteredSubcategories, subcategoriesCounter] =
          filterCategoriesBySearchString(
            category.subCategories,
            filterString,
            parentPath ? parentPath.concat(category) : [category],
          );

        nextFilteredCategories = nextFilteredCategories.concat(filteredSubcategories);
        nextCounter += subcategoriesCounter;
      }

      return [nextFilteredCategories, nextCounter];
    },
    [[] as OmniClassCategory[][], 0],
  );
};

const MIN_SEARCH_REQUIRED_LENGTH = 3;

type CategorySelectModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  dialogTitle?: string;
  categories: Record<number, OmniClassCategory>;
  selectedCategories: OmniClassCategory[];
  onCategorySelection: (category: OmniClassCategory) => void;
  allowSelectAny?: boolean;
};

export const CategorySelectModal = (props: CategorySelectModalProps) => {
  const searchField = useRef<HTMLInputElement | null>(null);

  const [searchString, setSearchString] = useState('');
  const [filteredCategories, setFilteredCategories] = useState<OmniClassCategory[][]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [matchCounter, setMatchCounter] = useState<number>();
  const [categoryToOpen, setCategoryToOpen] = useState<OmniClassCategory[]>([]);

  const filterCategories = useCallback(
    (value: string) => {
      if (value) {
        const [tc, counter] = filterCategoriesBySearchString(props.categories, value);
        setFilteredCategories(tc);
        setMatchCounter(counter);
      } else {
        setFilteredCategories([]);
        setMatchCounter(0);
      }
      setIsSearching(false);
    },
    [props.categories],
  );

  const debouncedSearchOnChange = useDebouncedCallback(filterCategories, 500);

  const handleSearchOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setSearchString(value);
    setCategoryToOpen([]);
    if (value.length >= MIN_SEARCH_REQUIRED_LENGTH) {
      setIsSearching(true);
      debouncedSearchOnChange(value);
    } else {
      debouncedSearchOnChange('');
    }
  };

  const resetStates = () => {
    setCategoryToOpen([]);
    setSearchString('');
  };

  const handleOnModalClose = () => {
    resetStates();
    props.onClose();
  };

  const handleOnModalDone = () => {
    resetStates();
    props.onDone();
  };

  const isSearchStringLongEnough =
    searchString.trim().length >= MIN_SEARCH_REQUIRED_LENGTH;

  useEffect(() => {
    if (props.isOpen && !isSearchStringLongEnough) {
      // Wait for element to render and retry
      const scrollToElement = (timeout = 250, max = 3) => {
        if (max <= 0) return;
        setTimeout(() => {
          let element: Element | null = null;
          if (categoryToOpen.at(-1)) {
            element = document.querySelector(
              `.${getCategoryAccordionClass(categoryToOpen.at(-1)!.id)}`,
            );
          } else {
            element = document.querySelector(`.${selectedCategoryClass}`);
          }
          if (element && isElementInViewport(element)) return;
          element?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
          scrollToElement(timeout * 3, max - 1);
        }, timeout);
      };

      scrollToElement();
    }
  }, [categoryToOpen, isSearchStringLongEnough, props.isOpen]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      searchField?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [props.isOpen]);

  const isAnySelected = props.selectedCategories.length > 0;

  const handleSelectClick = (category: OmniClassCategory) => {
    resetStates();
    props.onCategorySelection(category);
  };

  const handleOpenClick = (categories: OmniClassCategory[]) => {
    setSearchString('');
    setCategoryToOpen(categories);
  };

  return (
    <Dialog open={props.isOpen} onClose={handleOnModalClose} fullWidth maxWidth="xxl">
      <ModalTitleWithIconAndClose
        icon={faCirclePlus}
        title={props.dialogTitle ?? 'Select category'}
        onModalClose={handleOnModalClose}
      />
      <Box sx={{ px: 3 }}>
        {!!props.selectedCategories.length && (
          <Box sx={{ px: 2, py: 1, mb: 1 }}>
            <Typography variant="textDefaultBold" sx={{ color: 'grey.400' }}>
              Selected
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FontAwesomeIcon icon={faDiagramProject} />
              <Typography>
                {props.selectedCategories.map(({ name }) => name).join(' / ')}
              </Typography>
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            inputRef={searchField}
            placeholder="Quick search"
            value={searchString}
            onChange={handleSearchOnChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </InputAdornment>
              ),
              endAdornment: isSearching && (
                <InputAdornment position="end">
                  <CircularProgress size={22} />
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: '19.5rem' }}
            autoFocus
          />
          <Box ml={2}>
            {isSearchStringLongEnough ? (
              <Typography variant="textDefaultBold">
                {matchCounter} result{matchCounter ?? 0 > 1 ? 's' : ''}...
              </Typography>
            ) : searchString.length > 0 ? (
              `Search values must be at least ${MIN_SEARCH_REQUIRED_LENGTH} characters long`
            ) : null}
          </Box>
        </Box>
        <Divider sx={{ ml: -3, width: 'calc(100% + 3rem)' }} />
      </Box>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Collapse in={isSearchStringLongEnough}>
          <Paper
            sx={{
              mt: 2,
              py: 0.5,
              overflow: 'scroll',
              minHeight: '20rem',
            }}
          >
            {isSearchStringLongEnough && matchCounter === 0 ? (
              <Box p={1}>There are no matches</Box>
            ) : (
              filteredCategories.map((categories, i) => {
                const currentCategory = categories.at(-1)!;
                return (
                  <Box
                    key={currentCategory.id}
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                      gap: 2,
                      px: 2,
                      py: 0.5,
                      '& .actionButtons': {
                        visibility: 'hidden',
                      },
                      '&:hover': {
                        backgroundColor: 'blue.50',
                        '& .actionButtons': {
                          visibility: 'visible',
                        },
                      },
                    }}
                    data-testid="category-search-result-entry"
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        overflow: 'hidden',
                      }}
                    >
                      {categories.reduce((acc, category, j) => {
                        const children = [...acc];
                        if (j > 0)
                          children.push(<Box key={`slash-${category.id}`}>/</Box>);
                        if (!category.subCategories)
                          children.push(
                            <FontAwesomeIcon
                              icon={faAlignLeft}
                              key={`icon-${category.id}`}
                            />,
                          );
                        return children.concat(
                          <HighlightSearch
                            key={`text-${i}-${j}-${category.id}`}
                            text={category.name}
                            highlight={searchString}
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          />,
                        );
                      }, [] as ReactNode[])}
                    </Box>
                    <Box className="actionButtons" sx={{ marginLeft: 'auto' }}>
                      {currentCategory.subCategories && (
                        <Button
                          variant="blueText"
                          endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                          sx={{ height: '1.75rem', px: 1.5 }}
                          onClick={() => handleOpenClick(categories)}
                        >
                          Open
                        </Button>
                      )}
                      {(props.allowSelectAny || !currentCategory.subCategories) && (
                        <Button
                          variant="contained"
                          startIcon={<FontAwesomeIcon icon={faCheck} />}
                          sx={{ height: '1.75rem', px: 1.5, ml: 1 }}
                          onClick={() => handleSelectClick(currentCategory)}
                        >
                          Select
                        </Button>
                      )}
                    </Box>
                  </Box>
                );
              })
            )}
          </Paper>
        </Collapse>
        <Collapse in={!isSearchStringLongEnough}>
          <FavoriteCategories
            selectedCategories={props.selectedCategories}
            onCategorySelection={props.onCategorySelection}
            availableCategories={props.categories}
          />
          <RecentCategories
            selectedCategories={props.selectedCategories}
            onCategorySelection={props.onCategorySelection}
            availableCategories={props.categories}
          />
          <Typography variant="textDefaultBold" sx={{ color: 'grey.400' }}>
            All Categories
          </Typography>
          <Paper
            sx={{
              mt: 1,
              borderRadius: '0.625rem',
              py: 0.5,
              minHeight: '30rem',
              overflow: 'scroll',
            }}
          >
            {Object.values(props.categories).map((category, index, categoriesArray) => (
              <Box key={category.id}>
                <CategoryAccordion
                  category={category}
                  selectedCategories={props.selectedCategories}
                  onCategorySummaryClick={props.onCategorySelection}
                  allowSelectAny={props.allowSelectAny}
                  expand={categoryToOpen}
                />
                {index < categoriesArray.length - 1 && <Divider />}
              </Box>
            ))}
          </Paper>
        </Collapse>
      </DialogContent>
      <DialogActions
        sx={{
          borderTop: `1px solid`,
          borderColor: 'grey.200',
          py: 1.75,
          px: 7,
          boxShadow: '0px -2px 2px rgba(12, 14, 19, 0.1)',
          xIndex: 10,
        }}
      >
        {!isAnySelected && (
          <Typography sx={{ mr: 4, color: 'grey.600' }}>
            Please select to continue...
          </Typography>
        )}
        <Button
          autoFocus
          variant="greyGhost"
          onClick={handleOnModalClose}
          sx={{
            px: 2,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOnModalDone}
          sx={{
            px: 4,
          }}
          disabled={!isAnySelected}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
