import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { FormHelperText, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useProjectGroups } from 'features/Projects/hook/project-groups';
import { sortBy } from 'lodash-es';
import { joinSx } from 'utils/helpers';

type Option = {
  label: string;
  value: number;
  searchableLabel: string;
  disabled: boolean;
};

type ProjectDetailFieldFilterProps = {
  selectedFields: number[];
  setSelectedFields: (value: number[]) => void;
  label: string;
  inputPropsSx?: SxProps<Theme>;
  disabled?: boolean;
  helperText?: string;
};

export const ProjectDashboardGroupSelect = (props: ProjectDetailFieldFilterProps) => {
  const { projectGroupsQuery } = useProjectGroups();

  const options: Option[] = projectGroupsQuery.data
    ? sortBy(
        projectGroupsQuery.data.map((p) => {
          return {
            label: p.name,
            searchableLabel: p.name.toLowerCase(),
            value: p.id,
            disabled: !p.has_access,
            disabledTooltip:
              "You don't have access to all the projects in this group. You may request an admin to add or remove this group from your dashboard.",
          };
        }),
        'searchableLabel',
      )
    : [];

  const handleChange = (newValue: number[]) => {
    props.setSelectedFields(newValue);
  };

  return (
    <Stack direction="column">
      <Typography variant="textDefaultSemiBold" sx={{ mb: 0.25 }}>
        {props.label}
      </Typography>
      <MultiSelect
        loading={projectGroupsQuery.isLoading}
        options={options}
        title="Groups..."
        value={props.selectedFields}
        onChange={handleChange}
        enableSearch
        inputPropsSx={joinSx(props.inputPropsSx, {
          maxWidth: '100%',
        })}
        disabled={props.disabled}
      />
      {props.helperText ? (
        <FormHelperText sx={{ color: 'error.main' }}>{props.helperText}</FormHelperText>
      ) : null}
    </Stack>
  );
};
