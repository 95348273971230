import { useRef, useEffect } from 'react';
import { capitalize, keyBy } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material';
import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import { LoadingButton } from '@mui/lab';

import { useProject } from 'features/Projects/hook/useProject';
import { useProjectMemberInvite } from 'features/ManageUsers/hooks/projectMemberInvite';
import { useProjectMembers } from 'features/Projects/hook/members';
import { SelectLabel } from 'components/SelectLabel/SelectLabel';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { AccessScopeSelect } from 'features/ManageUsers/components/AccessScopeSelect/AccessScopeSelect';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { useCurrentCompany } from 'features/Company/hooks/useCurrentCompany';
import { getUserFullnameAndEmail, isExternalUser } from 'utils/helpers';
import { useCompanyUsers } from 'features/ManageUsers/hooks/companyUsers';
import { RoleSelect } from './RoleSelect';

type FormType = {
  email: string;
  role: number | null;
  accessScope: number | null;
};

export const InviteMemberForm = () => {
  const { currentCompany } = useCurrentCompany();
  const { sendInviteMutation } = useProjectMemberInvite();
  const { projectMembers } = useProjectMembers();
  const { currentUser } = useCurrentUser();
  const { companyUsersQuery } = useCompanyUsers();

  const textFieldRef = useRef<HTMLElement>();

  const { successToast } = useToastDialogs();

  const errorMessage = capitalize(
    (sendInviteMutation.error as Error | undefined)?.message,
  );

  const { project } = useProject();

  const companyUsersHash = companyUsersQuery.data
    ? keyBy(companyUsersQuery.data, 'email')
    : {};
  const isCompanyUserEmail = (userEmail: string) => !!companyUsersHash[userEmail];

  const InviteSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required('E-mail is required')
      .notOneOf(
        projectMembers ? projectMembers.map(({ email }: { email: string }) => email) : [],
        'email already registered',
      ),
    role: Yup.number().when('email', {
      is: (email: string | null) =>
        !!companyUsersQuery.data?.find((user) => user.email === email),
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(),
    }),
    accessScope: Yup.number().nullable(),
  });

  useEffect(() => {
    // As requested by Mary, make sure that the active input gains focus (CPE-576)
    textFieldRef.current && textFieldRef.current.focus();
  }, []);

  // Function called when the user submits the form.
  const handleFormSubmit = (
    { email, role, accessScope }: FormType,
    { resetForm }: { resetForm: () => void },
  ) => {
    const isCompanyUser = isCompanyUserEmail(email);
    if (project?.id) {
      sendInviteMutation.mutate(
        {
          projectId: project.id,
          email,
          roleId: isCompanyUser ? null : role,
          accessScopeId:
            isExternalUser(currentCompany, email) || isCompanyUser ? null : accessScope,
        },
        {
          onSuccess: () => {
            successToast({
              title: 'Project Member',
              text: 'Project member successfully invited',
            });
            resetForm();
          },
        },
      );
    }
  };

  const initialValues = { email: '', role: null, accessScope: null };

  const projectMembersHash = keyBy(projectMembers, 'email');

  const options = companyUsersQuery.data
    ? companyUsersQuery.data
        .map((user) => ({
          value: user.email,
          label: getUserFullnameAndEmail(user),
        }))
        .filter((option) => !projectMembersHash[option.value])
    : [];

  return (
    <Formik<FormType>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={InviteSchema}
      validateOnMount
    >
      {(formikProps) => {
        const {
          isValid,
          setFieldValue,
          handleSubmit,
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
        } = formikProps;

        const isExternal = isExternalUser(currentCompany, values.email);

        const isCompanyUser = isCompanyUserEmail(values.email);

        const isButtonDisabled =
          sendInviteMutation.isPending ||
          !isValid ||
          (!isCompanyUser && values.role == null) ||
          (!isCompanyUser && !isExternal && values.accessScope === null);

        return (
          <form onSubmit={handleSubmit}>
            <Grid columnSpacing={2} container alignItems="flex-end">
              <Grid item xs={12}>
                <Autocomplete
                  value={values.email}
                  onChange={(_event, selectedOption) => {
                    if (typeof selectedOption === 'string') {
                      setFieldValue('email', selectedOption);
                    } else if (typeof selectedOption?.value === 'string') {
                      setFieldValue('email', selectedOption.value);
                    } else {
                      setFieldValue('email', '');
                    }
                  }}
                  filterOptions={(options, params) => {
                    const { inputValue } = params;
                    const filtered = options.filter((option) =>
                      option.label.toLowerCase().includes(inputValue.toLowerCase()),
                    );

                    const isExisting =
                      options.some((option) => inputValue === option.value) ||
                      projectMembers?.some((member) => inputValue === member.email);

                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        value: inputValue,
                        label: `Send invitation to "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  handleHomeEndKeys
                  options={options}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return option.value;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      data-testid="email-input"
                      label="Select a company user or enter a new e-mail"
                      inputRef={textFieldRef}
                      fullWidth
                      error={!!((touched.email && errors.email) || errorMessage)}
                      helperText={(touched.email && errors.email) || errorMessage}
                    />
                  )}
                  loading={companyUsersQuery.isLoading}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectLabel id="select-role-label">Role</SelectLabel>
                <Tooltip
                  arrow
                  title={
                    isCompanyUser
                      ? 'Existing company user will remain his/her assigned Role'
                      : ''
                  }
                >
                  <span>
                    <RoleSelect
                      roleId={isCompanyUser ? null : values.role}
                      isExternalUser={isExternal}
                      disabled={
                        sendInviteMutation.isPending || isCompanyUser || !values.email
                      }
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip
                  arrow
                  title={
                    isExternal
                      ? `Business Units are only for internal users, those which email domain is/are "@${currentCompany?.tld}"`
                      : isCompanyUser
                        ? 'Existing company user will remain his/her assigned Business Unit'
                        : ''
                  }
                >
                  <AccessScopeSelect
                    filterByMemberId={currentUser?.id}
                    label="Business Unit"
                    placeholder="Business Unit"
                    value={isExternal || isCompanyUser ? '' : (values.accessScope ?? '')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="accessScope"
                    fullWidth
                    disabled={isExternal || isCompanyUser}
                  />
                </Tooltip>
              </Grid>
              <Grid container item xs={4} direction="column" justifyContent="flex-end">
                <LoadingButton
                  disabled={isButtonDisabled}
                  type="submit"
                  loading={sendInviteMutation.isPending}
                  startIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
                  sx={{ width: '100%' }}
                >
                  {sendInviteMutation.isPending ? 'Sending...' : 'Send Invite'}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
