import {
  CleanAccordion,
  CleanAccordionDetails,
  CleanAccordionSummary,
} from 'components/CleanAccordion';
import { Box, Button, Chip, SxProps, Theme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { joinSx, metricPrefixFormatter } from 'utils/helpers';
import { useFormikContext } from 'formik';
import { EditingProjectDashboard } from 'features/project-dashboard/helpers';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { NONE_OUTLINED_SELECTED_CELL } from 'style/DataGridCommonStyles';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { GridColumns } from 'types/helpers';
import { DashboardRelatedProject } from 'types/Dashboard';
import { ProjectDashboardProjectsSectionManage } from 'features/project-dashboard/modal/ProjectDashboardProjectsSectionManage';
import { ProjectGroup } from 'types/Project';
import { useProjectGroups } from 'features/Projects/hook/project-groups';
import { sortBy } from 'lodash-es';

type ProjectDashboardProjectsSectionProps = {
  sx?: SxProps<Theme>;
  readOnly?: boolean;
};
export function ProjectDashboardProjectsSection({
  sx,
  readOnly,
}: ProjectDashboardProjectsSectionProps) {
  const { values } = useFormikContext<EditingProjectDashboard>();
  const { projectGroupsQuery } = useProjectGroups();

  const { groups, projects } = useMemo(() => {
    const selectedProjectGroups = (values.project_group_ids
      .map((groupId) => projectGroupsQuery.data?.find((pg) => pg.id === groupId))
      .filter(Boolean) || []) as ProjectGroup[];

    const selectedProjects: DashboardRelatedProject[] = [...(values.projects || [])];
    selectedProjectGroups
      .map((g) => g.projects)
      .flat()
      .forEach((rp) => {
        const exist = selectedProjects.find((p) => p.id === rp.id);
        if (!exist) {
          selectedProjects.push({
            ...rp,
            has_access: undefined,
          });
        }
      });

    return {
      groups: selectedProjectGroups,
      projects: sortBy(selectedProjects, (u) => u.name.toLowerCase()),
    };
  }, [projectGroupsQuery.data, values.project_group_ids, values.projects]);

  const groupsByProjects = groups.reduce(
    (acc, group) => {
      group.projects.forEach((p) => {
        const relatedGroups = acc[p.id] || [];
        acc[p.id] = [...relatedGroups, group];
      });
      return acc;
    },
    {} as Record<number, ProjectGroup[]>,
  );

  const [isExpanded, setIsExpanded] = useState(true);
  const [showManageProjects, setShowManageProjects] = useState(false);

  const columns: GridColumns<DashboardRelatedProject, 'actions' | 'groups'> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return params.row.name;
      },
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 100,
    },
    {
      field: 'groups',
      headerName: 'Groups',
      width: 100,
      renderCell: (params) => {
        return (
          groupsByProjects[params.id as number]?.map((gr) => gr.name).join(', ') || '-'
        );
      },
    },
    {
      field: 'target_budget',
      headerName: 'Cost',
      valueFormatter: (value) =>
        value == null ? '-' : metricPrefixFormatter(value ?? 0),
    },
    {
      field: 'target_square_footage',
      headerName: 'GSF',
      valueFormatter: (value) =>
        value == null ? '-' : metricPrefixFormatter(value ?? 0),
    },
  ];

  return (
    <Box sx={joinSx(sx, { position: 'relative' })}>
      <CleanAccordion expanded={isExpanded}>
        <CleanAccordionSummary
          expanded={isExpanded}
          aria-controls="projects-section-panel"
          id="projects-section-header"
          onClick={() => setIsExpanded((prev) => !prev)}
          label="Projects"
          sx={{ ml: -1 }}
        >
          <Chip
            label={projects.length || 0}
            sx={{
              ml: 1,
              '& .MuiChip-label': {
                px: 0.75,
              },
            }}
          />
        </CleanAccordionSummary>
        <CleanAccordionDetails
          sx={joinSx(
            { p: 0, overflow: 'auto' },
            projects.length === 0
              ? {}
              : { height: `${Math.min(60 + 40 * projects.length, 350)}px` },
          )}
        >
          <DataGridPremium
            rows={projects}
            autoHeight={projects.length === 0}
            columns={columns}
            hideFooter
            disableColumnMenu
            initialState={{
              pinnedColumns: {
                right: ['actions'],
              },
            }}
            sx={NONE_OUTLINED_SELECTED_CELL}
          />
        </CleanAccordionDetails>
      </CleanAccordion>

      {!readOnly ? (
        <Box
          sx={{
            display: 'inline-block',
            position: 'absolute',
            left: 132,
            top: 12,
          }}
        >
          <Button
            variant="blueGhost"
            sx={{
              ml: 0,
              maxWidth: '24px',
              minWidth: '24px',
              height: '24px',
              borderRadius: '4px',
              p: 0,
            }}
            onClick={() => {
              setIsExpanded(true);
              setShowManageProjects((prev) => !prev);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          {showManageProjects ? (
            <ProjectDashboardProjectsSectionManage
              groups={groups}
              projects={projects}
              onClose={() => setShowManageProjects(false)}
            />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}
