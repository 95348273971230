import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { ForesiteSummary } from 'types/ForeSite';
import { queryKeys } from 'utils/reactQuery';

export const useForesiteSummary = (options?: { projectId?: number }) => {
  const { selectedProjectId } = useSelectedProjectId();

  const projectId = options?.projectId || selectedProjectId;

  const foresiteSummaryQuery = useQuery({
    queryKey: queryKeys.project(projectId).foresiteSummary,
    queryFn: ({ signal }) => {
      const endPoint = Resources.FORESITE_SUMMARY_BY_ID.replace(
        '<int:pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        projectId!.toString(),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ForesiteSummary,
      );
    },
    staleTime: Infinity,
    enabled: !!projectId,
  });

  return { foresiteSummaryQuery };
};
