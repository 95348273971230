import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { findLevelOneCategories } from 'features/Foresite/utils/omniClassCategory';
import { isEmpty, keyBy } from 'lodash-es';
import { OmniClassCategory } from 'types/OmniClassCategory';
import { useMemo } from 'react';
import { persistedQueryOptions, queryKeys } from 'utils/reactQuery';
import { findNodeAndChildren } from 'utils/categories';

const commonCategory: OmniClassCategory = {
  id: -1,
  name: 'Common',
  code: '00-00 00 00',
  description: '',
  level: 1,
  parent_category: null,
  base_unit: null,
  classification: 'TABLE_11',
  is_favorite: false,
};

export const useOmniClassCategory = () => {
  const omniClassCategoryQuery = useQuery({
    queryKey: queryKeys.categories,
    queryFn: () =>
      ApiService.get(Resources.ALL_CATEGORIES).then((res) => {
        const omniClassCategories = res.data as OmniClassCategory[];
        return {
          categories: omniClassCategories,
          mappedCategories: mapCategories({
            categories: omniClassCategories,
            addTable13Categories: true,
          }),
        };
      }),
    ...persistedQueryOptions,
  });

  const { data, ...rest } = omniClassCategoryQuery;

  return {
    ...rest,
    omniClassCategories: data?.categories,
    mappedOmniClassCategories: data?.mappedCategories,
  };
};

const populateNodesSubcategories = (
  indexedNodes: Record<number, OmniClassCategory>,
  mappedCategories: Record<number, OmniClassCategory[]>,
) => {
  const populatedNodes = { ...indexedNodes };
  Object.values(indexedNodes).forEach((node) => {
    if (mappedCategories[node.id]) {
      const indexedSubcategories = keyBy(mappedCategories[node.id], 'id');
      populatedNodes[node.id].subCategories = populateNodesSubcategories(
        indexedSubcategories,
        mappedCategories,
      );
    }
  });
  return populatedNodes;
};

function mapCategories({
  categories,
  addCommonCategory,
  categoryIdsToFilter = [],
  addTable13Categories,
}: {
  categoryIdsToFilter?: number[];
  addCommonCategory?: boolean;
  addTable13Categories?: boolean;
  categories: OmniClassCategory[];
}) {
  const categoryIdsToFilterMap = categoryIdsToFilter.reduce(
    (map, categoryId) => ({ ...map, [categoryId]: true }),
    {} as { [key: number]: boolean },
  );

  const categoriesWithTable13: OmniClassCategory[] = addTable13Categories
    ? categories
    : categories.filter((c) => c.classification !== 'TABLE_13');

  // We have to map the categories by parent category to associate them
  // as subcategories when we are making the categories tree
  const omniClassCategoriesWithCommon: OmniClassCategory[] = addCommonCategory
    ? categoriesWithTable13.concat(commonCategory)
    : categoriesWithTable13;

  const mappedCategoriesByParentCategory = omniClassCategoriesWithCommon.reduce(
    (mapped, category) => {
      if (categoryIdsToFilterMap[category.id]) {
        return mapped;
      }
      if (!mapped[category.parent_category ?? 0]) {
        // 0 --> no parent, first level category
        mapped[category.parent_category ?? 0] = [];
      }
      return {
        ...mapped,
        [category.parent_category ?? 0]:
          mapped[category.parent_category ?? 0].concat(category),
      };
    },
    {} as Record<number, OmniClassCategory[]>,
  );

  const firstLevelIndexedCategories = keyBy(mappedCategoriesByParentCategory[0], 'id');

  return populateNodesSubcategories(
    firstLevelIndexedCategories,
    mappedCategoriesByParentCategory,
  ) as Record<number, OmniClassCategory>;
}

export const useMappedOmniClassCategories = (
  categoryIdsToFilter: number[] = [],
  addCommonCategory = false,
  addTable13Categories = false,
) => {
  const { isLoading, isError, omniClassCategories, isFetching } = useOmniClassCategory();
  const mappedOmniClassCategories = useMemo(() => {
    if (!isLoading && !isError && omniClassCategories) {
      return mapCategories({
        categories: omniClassCategories,
        addCommonCategory,
        addTable13Categories,
        categoryIdsToFilter,
      });
    }
    return {} as Record<number, OmniClassCategory>;
  }, [
    addCommonCategory,
    addTable13Categories,
    categoryIdsToFilter,
    isError,
    isLoading,
    omniClassCategories,
  ]);
  return {
    mappedOmniClassCategories,
    isError,
    isLoading,
    isFetching,
  };
};

export const useFilteredFirstLevelCategories = () => {
  const { mappedOmniClassCategories } = useMappedOmniClassCategories();
  const getFilteredFirstLevelCategories = (categoriesToKeep: number[]) => {
    if (isEmpty(mappedOmniClassCategories)) {
      return [];
    } else {
      const notRepeatedCategories = [...new Set(categoriesToKeep)];
      const match = findLevelOneCategories(
        mappedOmniClassCategories,
        notRepeatedCategories,
      );
      return match;
    }
  };
  return {
    getFilteredFirstLevelCategories,
  };
};

export const useFindCategoryNodeAndChildren = (optionalOptions?: {
  addCommonCategory?: boolean;
  addTable13Categories?: boolean;
}) => {
  const { addCommonCategory, addTable13Categories } = {
    ...{ addCommonCategory: true, addTable13Categories: true },
    ...optionalOptions,
  };
  const { mappedOmniClassCategories } = useMappedOmniClassCategories(
    [],
    addCommonCategory,
    addTable13Categories,
  );
  const findCategoryNodeAndChildren = (selectedConstructionCategory?: number) =>
    findNodeAndChildren(mappedOmniClassCategories, selectedConstructionCategory);

  return { findCategoryNodeAndChildren };
};
