import { Project, ProjectGroup, ProjectLean } from 'types/Project';
import { UserBrief } from 'types/User';
import { TimelineGenericContractEvent, TimelineGMPContractEvent } from 'types/Timeline';
import { ComparisonBaseUnit } from 'types/Calibrate';
import { EntityCustomAttributeValue } from 'types/CustomAttributes';
import { Issue, IssueCountStatus, IssueStatus } from 'types/Issue';
import { Risk, RiskCountStatus, RiskStatus } from 'types/Risk';
import { DesignMilestone } from 'types/DesignMilestones';

export const DEFAULT_PROJECT_DASHBOARD_PORTFOLIO = -1;

export type ProjectDashboardVisibility = 'INTERNAL' | 'EXTERNAL';

export type DashboardRelatedProject = Pick<Project, 'id' | 'name' | 'code' | 'status'> &
  Partial<Pick<Project, 'target_square_footage' | 'target_budget'>> & {
    has_access: boolean | undefined;
  };

export type DashboardPermission = {
  id: number;
  codename: 'edit_dashboard_settings' | 'edit_dashboard_cards';
  name: string;
};

export type DashboardMembership = {
  id?: number;
  user_id: number;
  permission_ids: number[];
  permissions: DashboardPermission[];
};

export type SaveDashboardMembership = {
  id?: number;
  user_id: number;
  permission_ids: number[];
};

export type UserDashboardMembership = UserBrief & {
  membership: DashboardMembership | undefined;
};

export type ProjectDashboard = {
  id: number;
  name: string;
  description: string | undefined;
  picture?: string;
  type: ProjectDashboardVisibility;
  project_groups?: ProjectGroup[];
  related_projects?: DashboardRelatedProject[];
  projects?: DashboardRelatedProject[];
  users?: UserBrief[];
  memberships?: DashboardMembership[];
  created_at?: string;
  created_by?: UserBrief['id'];
};

export type DashboardMetricKey =
  | 'kpis'
  | 'all_projects'
  | 'active_projects'
  | 'projects_scope'
  | 'value_options_generation'
  | 'contingency_evolution'
  | 'portfolio_diversity'
  | 'estimate_summary'
  | 'fee_overtime'
  | 'cost_breakdown'
  | 'risks_summary'
  | 'issues_summary'
  | 'milestones_summary'
  | 'health_metrics';

export type DashboardMetric = {
  id: number;
  name: string;
  metric_key: DashboardMetricKey;
  description?: string;
  type: ProjectDashboardVisibility;
};

export type ProjectDashboardMetricSetting = {
  id: number;
  name: string;
  metric: DashboardMetric;
};

export type ProjectDashboardSummary = {
  backlog: {
    projects_amount: number;
    cost: number;
  };
  under_contract: {
    projects_amount: number;
    cost: number;
  };
  aggregate_fee_per_gross_margin: number;
  ideas: {
    total: number;
    approved: number;
    pending: number;
    rejected: number;
  };
  projects_next_to_gmp: number;
};

export type FeeOverTime = {
  fee_percentage: number;
  date: string;
};
export type ProjectDashboardFeeOverTime = {
  projects: Record<Project['id'], FeeOverTime[]>;
  avg: FeeOverTime[] | undefined;
};

export type ContingencyOverTime = {
  value: number;
  date: string;
};

export type ProjectDashboardContingencyOverTime = {
  contingencies:
    | {
        'CONT-B': ContingencyOverTime[] | undefined;
        'CONT-D': ContingencyOverTime[] | undefined;
        'CONT-C': ContingencyOverTime[] | undefined;
      }
    | undefined;
  avg: ContingencyOverTime[] | undefined;
};

export type ProjectValueOptionGeneration = {
  approved: number;
  pending: number;
  rejected: number;
  past_due: number;
};

export type ProjectDashboardValueOptionGeneration = Record<
  Project['id'],
  ProjectValueOptionGeneration | undefined
>;
export type ProjectDashboardEstimateSummaryCostGroupDivision = {
  cost: number;
  div_code: string;
  id: number;
  name: string;
};
export type ProjectDashboardEstimateSummaryMarkup = {
  code: string;
  name: string;
  cost_group_id: number;
  value: number;
};
export type ProjectDashboardEstimateSummaryOwnerCost = {
  id: number;
  code: string;
  name: string;
  value: number;
};

export type ProjectDashboardEstimateSummaryComponent = {
  id: number;
  name: string;
  value: number;
};

export type ProjectDashboardEstimateSummaryCostGroup = {
  definition_id: number;
  definition_name: string;
  divisions: ProjectDashboardEstimateSummaryCostGroupDivision[];
};

export type ProjectDashboardEstimateSummary = {
  project_id: number;
  project_name: string;
  total_cost_of_work: number;
  total_markups: number;
  total_owner_costs: number;
  cost_groups: ProjectDashboardEstimateSummaryCostGroup[];
  markups: ProjectDashboardEstimateSummaryMarkup[];
  owner_costs: ProjectDashboardEstimateSummaryOwnerCost[];
  components: ProjectDashboardEstimateSummaryComponent[];
};

export type ProjectDashboardProjectMetric = Pick<
  Project,
  | 'id'
  | 'name'
  | 'status'
  | 'picture'
  | 'picture_thumbnail'
  | 'delta'
  | 'members'
  | 'milestones'
  | 'date_created'
  | 'target_square_footage'
  | 'target_budget'
> & {
  contract_events?: {
    gmpContractEvent: TimelineGMPContractEvent | null;
    genericContractEvents: TimelineGenericContractEvent[];
  };
  has_access: boolean;
};

export type ProjectDashboardProjectScopeMetric = Pick<
  Project,
  'id' | 'name' | 'status' | 'picture' | 'picture_thumbnail' | 'completion_date'
> & {
  has_access: boolean;
  cost: number | undefined;
  gsf: number | undefined;
  target: number | undefined;
  base_unit: ComparisonBaseUnit | null;
  customAttributes?: Pick<
    EntityCustomAttributeValue,
    'custom_attribute_id' | 'name' | 'value' | 'type'
  >[];
};

export type ProjectDashboardDetailsId = number | 'new';

export type ProjectDashboardPortfolioDiversity = {
  category_name: string;
  projects_amount: number;
  backlog_cost: number;
  under_contract_cost: number;
};

export type ProjectDashboardIssueSummary = Issue & { project: ProjectLean };
export type UnparsedProjectDashboardIssuesSummary = {
  issue_by_status: Record<IssueStatus, number>;
  past_due_issues: ProjectDashboardIssueSummary[];
  active_issues: ProjectDashboardIssueSummary[];
  backlog_issues: ProjectDashboardIssueSummary[];
};

export type ProjectDashboardIssuesSummary = {
  totalIssues: number;
  issueCountStatuses: IssueCountStatus[];
  pastDueIssues: ProjectDashboardIssueSummary[];
  activeIssues: ProjectDashboardIssueSummary[];
  backlogIssues: ProjectDashboardIssueSummary[];
};

export type ProjectDashboardRiskSummary = Pick<
  Risk,
  'id' | 'name' | 'level' | 'owner'
> & {
  value_estimation: number | undefined;
  project: ProjectLean;
};
export type UnparsedProjectDashboardRisksSummary = {
  risk_by_status: Record<RiskStatus, number>;
  risks: ProjectDashboardRiskSummary[];
};
export type ProjectDashboardRisksSummary = {
  totalRisks: number;
  risks: ProjectDashboardRiskSummary[];
  riskCountStatuses: RiskCountStatus[];
};

export type ProjectDashboardMilestoneSummary = DesignMilestone & {
  project: ProjectLean;
};

export type ProjectDashboardMilestonesSummary = {
  past_due_milestones: ProjectDashboardMilestoneSummary[];
  upcoming_milestones: ProjectDashboardMilestoneSummary[];
  closest_milestones: ProjectDashboardMilestoneSummary[];
};

type ProjectDashboardHealthMetricUnit = {
  unit_id: number;
  name: string;
  value: number;
  key?: string;
};
export type ProjectDashboardHealthMetrics = {
  target_budget: number;
  forecasted_budget: number;
  current_budget: number;
  target_program: number;
  forecasted_program: number;
  current_program: number;
  custom_units: ProjectDashboardHealthMetricUnit[];
  base_units: ProjectDashboardHealthMetricUnit[];
};
