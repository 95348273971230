import { useAtom } from 'jotai';
import { Item } from 'types/Item';
import { atomWithArraySet } from 'utils/atoms';
import { useForesiteSummary } from './useForesiteSummary';
import { useItems } from './items';
import { useEffect } from 'react';
import { useBudgetAdjustedQuery } from 'features/TargetValueDesign/hooks/useBudgetAdjusted';
import { getItemSelectableConditions } from 'features/Foresite/utils/getItemSelectableConditions';

// Used by items and scenarios. Formerly known as `scenarios_filter`
// TODO: This atom should be just a state
const itemsFocusAtom = atomWithArraySet<number>([]);
itemsFocusAtom.debugLabel = 'itemsFocusAtom';

export const useItemsFocus = () => {
  const [itemsFocus, setItemFocus] = useAtom(itemsFocusAtom);
  const { items } = useItems();

  const resetItemsFocus = () => {
    setItemFocus({ nextValue: [] });
  };
  const unfocusItem = (itemId: number) => {
    setItemFocus({ nextValue: itemId, add: false });
  };

  const focusItem = (itemId: number) => {
    // Un select all Mutually Exclusive items
    const itemSelected = (items ?? []).find((item: Item) => item.id === itemId);
    for (const item of items ?? []) {
      if (
        itemSelected &&
        item.id !== itemSelected.id &&
        itemSelected.me_groups &&
        itemSelected.me_groups.includes(item.id) &&
        itemsFocus.includes(item.id)
      ) {
        unfocusItem(item.id);
      }
    }
    setItemFocus({ nextValue: itemId, add: true });
  };

  useEffect(() => {
    // CPE-1995 CPE-6625 not selectable items should be unfocused
    const notSelectableItems = itemsFocus.filter((itemId) => {
      const item = (items ?? []).find((item) => item.id === itemId);
      return getItemSelectableConditions({
        item,
      }).some((c) => !c.isFulfill);
    });
    // unfocus them
    notSelectableItems.forEach((itemId) => {
      unfocusItem(itemId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return {
    itemsFocus,
    focusItem,
    unfocusItem,
    resetItemsFocus,
  };
};

export const useItemFocusSummary = () => {
  const { items } = useItems();
  const { itemsFocus } = useItemsFocus();
  const filteredItems = (items ?? []).filter((item: Item) =>
    itemsFocus.includes(item.id),
  );

  return useItemsSummary({ items: filteredItems });
};

export const useItemsSummary = ({ items }: { items: Item[] }) => {
  const {
    foresiteSummaryQuery: { data: foresiteSummary, isLoading: isForesiteSummaryLoading },
  } = useForesiteSummary();
  const { budgetAdjustedQuery } = useBudgetAdjustedQuery();

  const isBusy =
    isForesiteSummaryLoading ||
    !foresiteSummary ||
    budgetAdjustedQuery.isFetching ||
    budgetAdjustedQuery.isLoading;

  if (isBusy) {
    return { isBusy };
  }

  let costVariation = 0;
  let indirectCostVariation = 0;
  let subtotalCostVariation = 0;
  let squareFootVariation = 0;

  const filteredItems = items ?? [];

  // Fix CPE-1741 & CPE-2005: truncate numbers as the backend does
  // see https://github.com/concntric/be-app/blob/b0d9c534795d9d5d93ee1149e17face25917e930/projects/models.py#L1459

  const cost = Math.trunc(filteredItems.reduce((acc, item) => acc + item.cost, 0));

  const subtotalCost = Math.trunc(
    filteredItems.reduce((acc, item) => acc + item.subtotal_cost, 0),
  );
  const indirectCost = Math.trunc(cost - subtotalCost);

  const currentBudgetCost = budgetAdjustedQuery.data?.cost ?? 0;
  const revisedCost = cost + currentBudgetCost;
  const revisedSubtotalCost = subtotalCost + currentBudgetCost;
  const revisedIndirectCost = indirectCost + currentBudgetCost;

  const revisedSqft = Math.trunc(
    filteredItems.reduce(
      (acc: number, item: Item) => acc + (item.estimated_sq_ft ?? 0),
      0,
    ) + (foresiteSummary.program_value ?? 0),
  );

  if (currentBudgetCost) {
    costVariation = (revisedCost * 100) / currentBudgetCost - 100;
    subtotalCostVariation = (revisedSubtotalCost * 100) / currentBudgetCost - 100;
    indirectCostVariation = (revisedIndirectCost * 100) / currentBudgetCost - 100;
  }

  if (foresiteSummary?.program_value) {
    squareFootVariation = (revisedSqft * 100) / foresiteSummary.program_value;
  }

  return {
    currentBudgetCost,
    subtotalCost,
    revisedSubtotalCost,
    subtotalCostVariation,
    indirectCost,
    indirectCostVariation,
    revisedIndirectCost,
    cost,
    costVariation,
    squareFootVariation,
    revisedCost,
    revisedSqft,
    isBusy,
  };
};
