import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { PrivateURL } from 'Urls';
import { ProjectPicture } from 'components/ProjectPicture';
import { Permissions } from 'features/Auth/Permissions';
import usePermissions from 'features/Auth/hook/usePermissions';
import { useProject } from 'features/Projects/hook/useProject';
import { usePathWithSelectedProjectId } from 'hooks/navigate';
import { useSidebar } from 'hooks/ui';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const PROJECT_PICTURE_SIZE = 24;

export const CurrentProjectItem = () => {
  const { project } = useProject();
  const location = useLocation();
  const { getPathWithSelectedProjectId } = usePathWithSelectedProjectId();
  const { HasPerm } = usePermissions();
  const { isSidebarCollapsed } = useSidebar();

  const isSelected =
    project?.id &&
    location.pathname ===
      PrivateURL.OVERVIEW.replace(':projectId', project.id.toString());

  return (
    <HasPerm permission={Permissions.OVERVIEW_ACCESS}>
      <Link to={getPathWithSelectedProjectId(PrivateURL.OVERVIEW)}>
        <ListItem
          sx={[
            {
              pl: isSidebarCollapsed ? 1.5 : 1,
              ml: isSidebarCollapsed ? 0 : 0.5,
              cursor: 'pointer',
            },
            !!isSelected && { backgroundColor: 'grey.700' },
          ]}
        >
          <Tooltip title={project?.name} placement="bottom" arrow>
            <Box
              sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, width: '100%' }}
            >
              <Box
                sx={{
                  backgroundColor: 'grey.400',
                  boxShadow: (theme) => `0px 2px 8px ${theme.palette.grey[700]}`,
                  height: '1.5rem',
                  width: '1.5rem',
                  borderRadius: '4px',
                }}
              >
                {!project?.picture_thumbnail ? (
                  <Box
                    component={FontAwesomeIcon}
                    icon={faBuilding}
                    sx={{ fontSize: '1rem', color: 'grey.700', py: 0.5, px: 0.75 }}
                  />
                ) : (
                  <ProjectPicture
                    picture={project?.picture_thumbnail}
                    sx={{
                      minWidth: PROJECT_PICTURE_SIZE,
                      minHeight: PROJECT_PICTURE_SIZE,
                      width: PROJECT_PICTURE_SIZE,
                      height: PROJECT_PICTURE_SIZE,
                      mr: 0,
                      objectFit: 'cover',
                    }}
                  />
                )}
              </Box>

              <Stack sx={{ ml: 2 }}>
                <Typography variant="textExtraSmall">PROJECT</Typography>
                <Typography
                  variant="textDefaultBold"
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {project?.name}
                </Typography>
              </Stack>
            </Box>
          </Tooltip>
        </ListItem>
      </Link>
    </HasPerm>
  );
};
