import { projectIdAtom } from 'components/AtomsProvider/atoms';
import { store as providerStore } from 'components/AtomsProvider/store';
import { useStore } from 'jotai';

export const useSelectedProjectId = () => {
  const store = useStore({ store: providerStore });

  const selectedProjectId = store.get(projectIdAtom);

  return {
    selectedProjectId,
  };
};
