import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Stack, Box, MenuItem } from '@mui/material';

type MenuItemWithIconProps = {
  icon: IconProp;
  label: string;
  description: string;
};
export const MenuItemWithIcon = ({ icon, label, description }: MenuItemWithIconProps) => {
  return (
    <Box
      component={MenuItem}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 1,
      }}
    >
      <Box component={FontAwesomeIcon} icon={icon} sx={{ pt: 0.5, color: 'grey.500' }} />
      <Stack>
        <Typography variant="textDefaultSemiBold" sx={{ textWrap: 'wrap' }}>
          {label}
        </Typography>
        <Typography variant="textSmall" sx={{ textWrap: 'wrap' }}>
          {description}
        </Typography>
      </Stack>
    </Box>
  );
};
