import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {
  useFavoriteOmniClassCategories,
  useUpdateFavoriteOmniClassCategory,
} from 'hooks/omni-class-category/useFavoriteOmniClassCategories';
import { OmniClassCategory } from 'types/OmniClassCategory';
import { StarButton } from './StarButton';
import { MouseEvent } from 'react';
import { findCategoryById } from 'utils/categories';
import { CategoryFullPath } from './CategoryFullPath';
import { useMixpanel } from 'hooks/useMixpanel';

type FavoriteCategoriesProps = {
  selectedCategories: OmniClassCategory[];
  onCategorySelection: (category: OmniClassCategory) => void;
  availableCategories: Record<number, OmniClassCategory>;
};

export const FavoriteCategories = (props: FavoriteCategoriesProps) => {
  const { favoriteOmniClassCategoriesQuery } = useFavoriteOmniClassCategories();
  const { updateFavoriteOmniClassCategoryQuery } = useUpdateFavoriteOmniClassCategory();
  const { mixpanel } = useMixpanel();

  const handleStartClick = (
    event: MouseEvent<HTMLElement>,
    category: OmniClassCategory,
  ) => {
    event.stopPropagation();
    mixpanel.track('update category favorite status from favorite categories', {
      categoryId: category.id,
      isFavorite: false,
    });

    updateFavoriteOmniClassCategoryQuery.mutate({
      categoryId: category.id,
      isFavorite: false,
    });
  };

  // There is the change that the selected as favorite categories are not in the main
  // list and we should remove them from here
  const filteredCategories =
    favoriteOmniClassCategoriesQuery.data?.filter(
      (category) => !!findCategoryById(props.availableCategories, category.id),
    ) ?? [];

  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <Typography variant="textDefaultBold" sx={{ color: 'grey.400' }}>
          Starred
        </Typography>
        <Chip label={filteredCategories.length ?? 0} />
      </Box>
      {favoriteOmniClassCategoriesQuery.isLoading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          component={Paper}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
          <Table aria-label="starred omniclass table" size="small">
            <TableBody>
              {filteredCategories.length ? (
                filteredCategories
                  .toSorted((a, d) => a.id - d.id)
                  .map((category) => {
                    const isSelected =
                      !!props.selectedCategories.length &&
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      props.selectedCategories.at(-1)!.id === category.id;

                    return (
                      <TableRow
                        key={category.id}
                        onClick={() => {
                          mixpanel.click('select', 'favorite categories', {
                            isSelected,
                            isFavorite: true,
                            categoryId: category.id,
                          });
                          props.onCategorySelection(category);
                        }}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: isSelected ? 'blue.50' : 'none',
                          '&:hover': {
                            backgroundColor: isSelected ? 'blue.50' : 'grey.50',
                            '& .selectTag': {
                              visibility: 'visible',
                            },
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <CategoryFullPath categoryId={category.id} />
                        </TableCell>
                        <TableCell sx={{ width: '7.5rem' }}>
                          <Box
                            alignSelf="center"
                            display="flex"
                            ml={2}
                            color="primary.main"
                            fontSize="1.5rem"
                            alignItems="center"
                            visibility={isSelected ? 'visible' : 'hidden'}
                            className="selectTag"
                          >
                            <Box mr={1}>
                              <FontAwesomeIcon icon={isSelected ? faCheck : faPlus} />
                            </Box>
                            <Typography variant="textDefaultBold">
                              {isSelected ? 'Selected' : 'Select'}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ width: '5rem' }}>
                          <StarButton
                            isFavorite
                            onClick={(event) => handleStartClick(event, category)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow sx={{ height: '44.5px' }}>
                  <TableCell component="th" scope="row">
                    <Typography variant="textDefaultSemiBold" sx={{ color: 'grey.400' }}>
                      Add stars to categories that you want to easily find later...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
