import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, AlertProps, SxProps, Theme } from '@mui/material';
import { joinSx } from 'utils/helpers';

type Severity = 'tooltip' | 'info' | 'info2' | 'warning' | 'error' | 'success';
type Customization = {
  icon: JSX.Element;
  color: string;
  borderColor: string;
  backgroundColor: string;
};
const ICON_SIZE = 'xs';
const severityPropsMap: Record<Severity, Customization> = {
  tooltip: {
    icon: <FontAwesomeIcon icon={faCircleInfo} size={ICON_SIZE} />,
    color: 'grey.400',
    borderColor: 'grey.100',
    backgroundColor: 'common.white',
  },
  info: {
    icon: <FontAwesomeIcon icon={faCircleInfo} size={ICON_SIZE} />,
    color: 'blue.700',
    borderColor: 'grey.100',
    backgroundColor: 'common.white',
  },
  info2: {
    icon: <FontAwesomeIcon icon={faCircleInfo} size={ICON_SIZE} />,
    color: 'blue.700',
    borderColor: 'blue.100',
    backgroundColor: 'blue.50',
  },
  warning: {
    icon: <FontAwesomeIcon icon={faCircleExclamation} size={ICON_SIZE} />,
    color: 'categoricYellow.700',
    borderColor: 'categoricYellow.300',
    backgroundColor: 'categoricYellow.50',
  },
  error: {
    icon: <FontAwesomeIcon icon={faCircleExclamation} size={ICON_SIZE} />,
    color: 'cautionRed.700',
    borderColor: 'cautionRed.100',
    backgroundColor: 'cautionRed.50',
  },
  success: {
    icon: <FontAwesomeIcon icon={faCircleCheck} size={ICON_SIZE} />,
    color: 'successGreen.700',
    borderColor: 'successGreen.100',
    backgroundColor: 'successGreen.50',
  },
};

type AlertBannerProps = Omit<AlertProps, 'severity' | 'variant'> & {
  severity?: Severity;
};

export const AlertBanner = ({ severity = 'success', sx, ...rest }: AlertBannerProps) => {
  const { icon, color, borderColor, backgroundColor } = severityPropsMap[severity];

  const customSx: SxProps<Theme> = {
    borderColor,
    backgroundColor,
    color: 'grey.700',
    '& .MuiAlert-icon': {
      color,
      alignItems: 'center',
    },
    '& .MuiAlert-message': {
      fontWeight: 500,
      py: 0.5,
      lineHeight: 1.6,
    },
    '& .MuiAlert-action': {
      pt: 0,
    },
  };

  return <Alert icon={icon} {...rest} sx={joinSx(customSx, sx)} />;
};
