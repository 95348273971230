import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import usePermissions from 'features/Auth/hook/usePermissions';
import { isEmpty } from 'lodash-es';
import { isLegacyOrDraftProject } from 'mappings/project';
import { routePermissions } from 'routePermissions';
import { PrivateURL } from 'Urls';
import { queryKeys } from 'utils/reactQuery';
import { MissingStep } from '../types';
import { useProject } from './useProject';
import { useSelectedProjectId } from './useSelectedProjectId';

const optionalSteps = ['TIMELINE', 'COMPONENTS', 'ESTIMATE'] as const;

export type OptionalProjectData = (typeof optionalSteps)[number][0];

export const useProjectMissingData = (): {
  isMissingDataLoading: boolean;
  hasMissingData: boolean;
  missingData: MissingStep[];
} => {
  const { project, isProjectLoading } = useProject();
  const {
    missingDataQuery: { data: missingDataInfo, isLoading: isDataLoading },
  } = useMissingData();

  const isProjectStatusLegacy = isLegacyOrDraftProject(project);

  const { hasPerm } = usePermissions();

  const isMissingDataLoading = isProjectLoading || isDataLoading;

  const predicates: Record<OptionalProjectData, () => boolean> = {
    TIMELINE: () => !missingDataInfo?.has_milestones,
    COMPONENTS: () => !missingDataInfo?.has_components && !isProjectStatusLegacy,
    ESTIMATE: () => !project || !missingDataInfo?.has_estimate,
  };
  const missingData = optionalSteps
    .filter((step) => predicates[step]())
    .map((step) => {
      const path = PrivateURL[step];
      const permission = routePermissions[path];
      return {
        step,
        hasPermission: hasPerm(permission),
      };
    });

  return {
    isMissingDataLoading,
    hasMissingData: !isEmpty(missingData),
    missingData,
  };
};

const useMissingData = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const missingDataQuery = useQuery({
    queryKey: queryKeys.project(selectedProjectId).missingData,
    queryFn: ({ signal }) => {
      const endPoint = Resources.PROJECT_MISSING_DATA_INFO.replace(
        '<int:project_pk>',
        (selectedProjectId ?? '') as string,
      );
      return ApiService.get(endPoint, { signal }).then((res) => res.data);
    },
    staleTime: Infinity,
    retryOnMount: false,
    enabled: !!selectedProjectId,
  });

  return { missingDataQuery };
};
