import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { addSearchParamsToUrl } from 'utils/helpers';
import log from 'loglevel';

import { CustomForesiteReportModalOptions } from 'types/Reports';
import { useState } from 'react';
import { getForesiteSearchParams } from '../helpers';

export const useForesiteReportInfo = () => {
  const { selectedProjectId } = useSelectedProjectId();

  const [isLoading, setIsLoading] = useState(false);

  const hasItemReportInfo = async (options: CustomForesiteReportModalOptions) => {
    setIsLoading(true);
    const searchParams = getForesiteSearchParams(options);

    let url = Resources.ITEM_REPORTS_INFO.replace(
      '<int:project_pk>',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedProjectId!.toString(),
    );

    url = addSearchParamsToUrl({
      url,
      searchParams,
    });

    try {
      const response = await ApiService.get(url).then(({ data }) => data);
      if (!response) {
        return;
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      const message =
        error instanceof Error
          ? error.message
          : 'The service is temporarily unavailable. Try again later.';

      log.error(message);
    }
  };

  return { hasItemReportInfo, isLoading };
};
