import { useQueryParamState } from 'hooks/useQueryParamState';
import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import { betterParseInt } from 'utils/helpers';

const selectedRoleDetailsIdAtom = atom<number | undefined>(undefined);

export const useSelectedRoleDetails = () => {
  const [selectedRoleDetailsIdQuery, setSelectedRoleDetailsIdQuery] =
    useQueryParamState<number>({
      name: 'roleDetailsId',
    });

  const [selectedRoleDetailsId, setSelectedRoleDetailsId] = useAtom(
    selectedRoleDetailsIdAtom,
  );

  useEffect(() => {
    if (selectedRoleDetailsIdQuery) {
      setSelectedRoleDetailsId(betterParseInt(selectedRoleDetailsIdQuery));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoleDetailsIdQuery]);

  const setBothRoleDetailsId = (value: number | undefined) => {
    setSelectedRoleDetailsIdQuery(value);
    setSelectedRoleDetailsId(value);
  };

  const cleanRoleDetails = () => setBothRoleDetailsId(undefined);

  return {
    selectedRoleDetailsId,
    setSelectedRoleDetailsId: setBothRoleDetailsId,
    cleanRoleDetails,
  } as {
    selectedRoleDetailsId?: number;
    setSelectedRoleDetailsId: (roleId: number) => void;
    cleanRoleDetails: () => void;
  };
};
