import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { isNumber } from 'lodash-es';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { joinSx } from 'utils/helpers';

export type NumberFormatProps = Omit<NumericFormatProps, 'customInput' | 'type'> & {
  customInput?: React.ComponentType;
  sx?: SxProps<Theme>;
  parenthesisNegationFormat?: boolean;
  currencyDecimalFormat?: boolean;
  showDecimalLargeNumber?: boolean;
  parenthesisZeroValue?: boolean;
  type?: 'currency';
};

export function NumberFormat({
  currencyDecimalFormat,
  showDecimalLargeNumber,
  parenthesisNegationFormat,
  parenthesisZeroValue,
  value,
  type,
  ...props
}: NumberFormatProps) {
  const isValueNumber = typeof value === 'number' && value != null;
  const isNegative = isValueNumber && value < 0;
  const shouldAddParenthesis =
    parenthesisNegationFormat && (isNegative || (parenthesisZeroValue && !value));

  const shouldShowDecimals =
    currencyDecimalFormat &&
    isValueNumber &&
    (props.displayType !== 'text' ||
      showDecimalLargeNumber ||
      (!showDecimalLargeNumber && Math.abs(value) < 1000));

  let thousandSeparator, decimalScale, fixedDecimalScale;
  if (type === 'currency') {
    thousandSeparator = true;
    if (shouldShowDecimals) {
      decimalScale = 2;
      fixedDecimalScale = props.displayType === 'text';
    } else {
      decimalScale = 0;
    }
  } else {
    decimalScale = props.decimalScale ?? 0;
  }

  const parsedValue = shouldAddParenthesis && isValueNumber ? Math.abs(value) : value;

  return (
    <NumericFormat
      value={parsedValue}
      renderText={(formattedValue, otherProps) => {
        // hack to pass the props to the text, when component is used with
        // material ui component prop
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const boxProps = otherProps as any;

        return (
          <Box
            component="span"
            className={boxProps.className}
            sx={joinSx(
              {
                fontFamily: (theme) => theme.typography.monospaceDefault,
                fontWeight: 'inherit',
              },
              props.sx,
            )}
          >
            {shouldAddParenthesis ? `(${formattedValue})` : `${formattedValue}`}
          </Box>
        );
      }}
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      isAllowed={(values) => {
        return isNumber(values.floatValue ?? 0);
      }}
      {...props}
    />
  );
}
