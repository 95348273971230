import { useSidebar } from 'hooks/ui';
import { Box, Container, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { joinSx } from 'utils/helpers';

type PageContainerProps = {
  sx?: SxProps<Theme>;
  children: ReactNode;
  fullWidth?: boolean;
};

export const PageContainer = ({
  fullWidth = false,
  children,
  sx,
}: PageContainerProps) => {
  const { isSidebarHidden } = useSidebar();

  if (isSidebarHidden)
    return (
      <Container
        sx={joinSx(
          {
            pt: 4,
          },
          (theme) => ({
            maxWidth: fullWidth ? 'inherit !important' : undefined,
            [theme.breakpoints.up('xxl')]: {
              px: '150px',
            },
          }),
          sx,
        )}
      >
        {children}
      </Container>
    );
  return (
    <Box
      sx={joinSx(
        (theme) => ({
          px: 4,
          pt: 2,
          pb: 10,
          ml: 0,
          maxWidth: fullWidth
            ? 'inherit'
            : `calc(${theme.breakpoints.values.xxl}px - 300px)`,
          [theme.breakpoints.up('xxl')]: {
            mx: fullWidth ? 'auto' : '118px',
          },
        }),
        sx,
      )}
    >
      {children}
    </Box>
  );
};
