import { Box, Typography } from '@mui/material';
import { User } from '@sentry/react';
import { getUserFullName } from 'utils/helpers';

export const MemberChipTooltipTitle = ({
  user,
}: {
  user: Partial<Pick<User, 'first_name' | 'last_name' | 'email'>>;
}) => {
  const fullName = getUserFullName(user);
  return fullName ? (
    <Box>
      <Typography variant="textSmallSemiBold">{fullName}</Typography>
      <Typography variant="textSmall"> ({user.email})</Typography>
    </Box>
  ) : (
    <Typography variant="textSmall">{user.email}</Typography>
  );
};
