import { createContext, useContext } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

export const PwaContext = createContext<ReturnType<typeof useRegisterSW> | null>(null);

export function usePwa() {
  const pwaContext = useContext(PwaContext);
  if (!pwaContext) {
    throw new Error('Component should be child of PwaProvider');
  }
  return pwaContext;
}
