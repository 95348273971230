import { atom, useAtom } from 'jotai';

export const isUserGuidingBlockedAtom = atom(false);

export function useIsUserGuidingBlocked() {
  const [isUserGuidingBlocked, setIsUserGuidingBlocked] = useAtom(
    isUserGuidingBlockedAtom,
  );

  return { isUserGuidingBlocked, setIsUserGuidingBlocked };
}
