import { Switch, Route } from 'react-router-dom';
import { useTitle } from 'hooks/useTitle';

import { ToastDialogs } from 'components/ToastDialogs';
import { UrlRoute } from 'Urls';
import { SideBar } from './SideBar';

import { Box, CircularProgress } from '@mui/material';
import usePermissions from 'features/Auth/hook/usePermissions';
import { ForbiddenView } from 'views/auth/ForbiddenView';
import { useSidebar } from 'hooks/ui';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import { Suspense, useEffect } from 'react';
import { useMixpanel } from 'hooks/useMixpanel';
import ReloadPrompt from 'components/ReloadPrompt/ReloadPrompt';
import { NavBar } from 'components/NavBar/NavBar';
import { BuildingConnected } from 'features/BuildingConnected/components/BuildingConnected';
import { PrivateRoutes } from 'Routes';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { LAST_VISITED_PROJECT_ID, LAST_VISITED_PAGE } from 'utils/constants';

export default function PrivateLayout() {
  const { currentUser, validateTenantAndLoadUser } = useCurrentUser();
  useEffect(() => {
    validateTenantAndLoadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isSidebarHidden } = useSidebar();

  return !currentUser ? (
    <LoadingPage />
  ) : (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        height: '100vh',
        background: 'linear-gradient(0deg, #2F3340 0%, #495164 100%)',
      }}
      className="privateLayout"
    >
      <NavBar />
      <SideBar />
      <ReloadPrompt />
      <BuildingConnected />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.common.white
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: 'auto',
          marginTop: (theme) => theme.navBarHeight,
          borderBottomLeftRadius: isSidebarHidden ? 0 : '10px',
          position: 'relative',
        }}
      >
        <ToastDialogs />
        <Box
          sx={{
            minHeight: 'calc(100vh - 85px)',
            height: '100%',
          }}
        >
          <Suspense fallback={<LoadingPage />}>
            <Switch>
              {PrivateRoutes.map((route, key) => (
                <Route
                  path={route.path}
                  render={({ location }) => (
                    <RouteRender route={route} pathname={location.pathname} />
                  )}
                  key={key}
                />
              ))}
            </Switch>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
}

function RouteRender({ pathname, route }: { pathname: string; route: UrlRoute }) {
  const { hasPerm, isRouteEnable } = usePermissions();
  const { enabled: routeEnabled, isLoading } = isRouteEnable(route);
  const { selectedProjectId } = useSelectedProjectId();
  const { mixpanel } = useMixpanel();
  const shouldRenderComponent =
    routeEnabled && (!route.requiredPermission || hasPerm(route.requiredPermission));

  useEffect(() => {
    if (shouldRenderComponent && route.name) {
      mixpanel.pageLoaded(route.name);
    }
  }, [mixpanel, route.name, shouldRenderComponent]);

  useEffect(() => {
    if (shouldRenderComponent) {
      sessionStorage.setItem(LAST_VISITED_PAGE, pathname);
      if (route.path.includes(':projectId') && selectedProjectId) {
        localStorage.setItem(LAST_VISITED_PROJECT_ID, `${selectedProjectId}`);
      } else {
        localStorage.removeItem(LAST_VISITED_PROJECT_ID);
      }
    }
  }, [pathname, route.path, selectedProjectId, shouldRenderComponent]);

  useTitle(shouldRenderComponent ? route.name : 'Forbidden');

  if (isLoading) return <CircularProgress size={32} sx={{ ml: '50%', mt: 2 }} />;

  return shouldRenderComponent ? <route.component /> : <ForbiddenView />;
}
