import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, TypographyProps } from '@mui/material';
import { joinSx } from 'utils/helpers';

type KbdProps = TypographyProps;

export const Kbd = ({ icon, children, sx, ...rest }: KbdProps & { icon?: IconProp }) => {
  const finalChildren = icon ? <Box component={FontAwesomeIcon} icon={icon} /> : children;
  const typographyProps = { ...rest, children: finalChildren };
  return (
    <Typography
      component="kbd"
      variant="textDefaultMedium"
      sx={joinSx(
        {
          backgroundColor: 'grey.600',
          borderRadius: '0.35rem',
          px: 1,
          py: 0.25,
          textColor: 'text.primary',
        },
        sx,
      )}
      {...typographyProps}
    />
  );
};
