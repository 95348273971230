import { useBaseUnits } from 'hooks/useBaseUnits';
import { useOmniClassCategory } from 'hooks/omni-class-category/useOmniClassCategory';
import { useMemo } from 'react';
export const useBaseUnitsFromCategoryId = (categoryId?: number) => {
  const { baseUnits, isLoading: isLoadingBaseUnits } = useBaseUnits();
  const { omniClassCategories, isLoading: isLoadingCategories } = useOmniClassCategory();

  const units = useMemo(() => {
    if (
      isLoadingBaseUnits ||
      isLoadingCategories ||
      !categoryId ||
      !omniClassCategories ||
      !baseUnits
    ) {
      return null;
    }
    const category = omniClassCategories.find((category) => category.id === categoryId);
    return category
      ? baseUnits.find((baseUnit) => category.base_unit === baseUnit.id) ?? null
      : null;
  }, [
    baseUnits,
    isLoadingBaseUnits,
    omniClassCategories,
    isLoadingCategories,
    categoryId,
  ]);

  return { baseUnits: units };
};
