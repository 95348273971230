import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { faCircle, faRhombus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { joinSx } from 'utils/helpers';

type ProjectPictureProps = {
  picture?: string | null;
  sx?: SxProps<Theme>;
  legendColor?: string;
  currentProject?: boolean;
  initials?: string;
};

export const ProjectPicture = (props: ProjectPictureProps) => {
  return (
    <Box
      sx={joinSx(
        {
          position: 'relative',
          borderRadius: '6px',
          minHeight: 158,
          width: 140,
          minWidth: 140,
          mr: 2,
          backgroundImage: `url('${props.picture}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'grey.50',
          border: (theme) =>
            props.picture ? 'none' : `1px dashed ${theme.palette.grey[200]}`,
          fontSize: '1.5rem',
          color: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 150,
        },
        props.sx,
      )}
    >
      {!props.picture && !props.initials && (
        <Box
          component={FontAwesomeIcon}
          icon={faBuilding}
          className="project-picture-icon"
        />
      )}
      {props.initials && !props.picture && (
        <Typography component={Box} sx={{ backgroundColor: 'white' }}>
          {props.initials}
        </Typography>
      )}
      {props.legendColor && (
        <Box
          sx={{
            color: props.legendColor,
            position: 'absolute',
            bottom: -4,
            right: 0,
            fontSize: '1rem',
            height: '100%',
          }}
        >
          <FontAwesomeIcon icon={props.currentProject ? faRhombus : faCircle} size="sm" />
        </Box>
      )}
    </Box>
  );
};
