import { atomArrayWithReducerLocalStorage } from 'utils/atoms';
import { atom, useAtom } from 'jotai';
import { intersection, isEmpty } from 'lodash-es';
import { combineFilters } from 'utils/helpers';
import { useAtomArrayWithReducerActions } from 'hooks/useAtomArrayWithReducerActions';
import { ProjectDashboard } from 'types/Dashboard';

const searchValueAtom = atom('');
searchValueAtom.debugLabel = 'searchValueAtom';
const selectedProjectGroupsAtom = atomArrayWithReducerLocalStorage<number>(
  'project-dashboard-filter-groups',
  [],
);
selectedProjectGroupsAtom.debugLabel = 'selectedProjectGroupsAtom';
const selectedProjectsAtom = atomArrayWithReducerLocalStorage<number>(
  'project-dashboard-filter-projects',
  [],
);
selectedProjectsAtom.debugLabel = 'selectedProjectsAtom';
const selectedMembersAtom = atomArrayWithReducerLocalStorage<number>(
  'project-dashboard-filter-members',
  [],
);
selectedMembersAtom.debugLabel = 'selectedMembersAtom';
const selectedTypeAtom = atomArrayWithReducerLocalStorage<string>(
  'project-dashboard-filter-type',
  [],
);
selectedTypeAtom.debugLabel = 'selectedTypeAtom';

export const untaggedId = -1;
export const useProjectDashboardTableFilters = () => {
  const [searchValue, setSearchValue] = useAtom(searchValueAtom);
  const { array: selectedProjectGroups, set: setSelectedProjectGroups } =
    useAtomArrayWithReducerActions(selectedProjectGroupsAtom);
  const { array: selectedProjects, set: setSelectedProjects } =
    useAtomArrayWithReducerActions(selectedProjectsAtom);
  const { array: selectedMembers, set: setSelectedMembers } =
    useAtomArrayWithReducerActions(selectedMembersAtom);
  const { array: selectedTypes, set: setSelectedTypes } =
    useAtomArrayWithReducerActions(selectedTypeAtom);

  const resetFilters = () => {
    setSearchValue('');
    setSelectedProjectGroups([]);
    setSelectedProjects([]);
    setSelectedMembers([]);
    setSelectedTypes([]);
  };

  const filterBySearch = searchValue
    ? (projectDashboard: ProjectDashboard) =>
        projectDashboard.name.toLowerCase().includes(searchValue.toLowerCase())
    : null;

  const filterByProjectGroups = isEmpty(selectedProjectGroups)
    ? null
    : (projectDashboard: ProjectDashboard) => {
        const groupsSet =
          new Set(projectDashboard.project_groups?.map((group) => group.id)) || [];
        return (
          !isEmpty(intersection(Array.from(groupsSet), selectedProjectGroups)) ||
          (selectedProjectGroups.includes(untaggedId) &&
            !projectDashboard.project_groups?.length)
        );
      };

  const filterByProjects = isEmpty(selectedProjects)
    ? null
    : (projectDashboard: ProjectDashboard) => {
        const projectsSet =
          new Set(projectDashboard.related_projects?.map((project) => project.id)) || [];
        return (
          !isEmpty(intersection(Array.from(projectsSet), selectedProjects)) ||
          (selectedProjects.includes(untaggedId) &&
            !projectDashboard.related_projects?.length)
        );
      };

  const filterByMembers = isEmpty(selectedMembers)
    ? null
    : (projectDashboard: ProjectDashboard) => {
        const membersSet = new Set(projectDashboard.users?.map((user) => user.id)) || [];
        return (
          !isEmpty(intersection(Array.from(membersSet), selectedMembers)) ||
          (selectedMembers.includes(untaggedId) && !projectDashboard.users?.length)
        );
      };

  const filterByTypes = isEmpty(selectedTypes)
    ? null
    : (projectDashboard: ProjectDashboard) => {
        const typesSet = new Set(projectDashboard.type ? [projectDashboard.type] : []);
        return !isEmpty(intersection(Array.from(typesSet), selectedTypes));
      };

  const combinedFilters = combineFilters<ProjectDashboard>(
    filterBySearch,
    filterByProjectGroups,
    filterByProjects,
    filterByMembers,
    filterByTypes,
  );

  return {
    searchValue,
    setSearchValue,
    selectedProjectGroups,
    setSelectedProjectGroups,
    selectedProjects,
    setSelectedProjects,
    selectedMembers,
    setSelectedMembers,
    selectedTypes,
    setSelectedTypes,
    combinedFilters,
    resetFilters,
  };
};
