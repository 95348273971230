import { Chip, styled } from '@mui/material';
import theme from 'style/theme';

// Currently features the specific styling for task https://concntric.atlassian.net/browse/CPE-820
// We could generalize & promote it to `/components` dir if we need it for more features.
export const CircleChip = styled(Chip)(() => ({
  width: '1.75rem',
  height: '1.75rem',
  borderRadius: '0.875rem',
  userSelect: 'none',
  '& .MuiChip-label': {
    paddingLeft: 0,
    paddingRight: 0,
    ...theme.typography.textExtraSmallSemiBold,
  },
}));
