import { useEffect } from 'react';

import { useIntercom } from 'react-use-intercom';
import { isProd, isTesting } from 'utils/environment';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { getUserFullName } from 'utils/helpers';

export const Init = () => {
  const { boot: bootIntercom, shutdown: shutdownIntercom } = useIntercom();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    // Whenever the user changes, shutdown Intercom and boot it again
    // tried to use the `update` method but it doesn't work. No intercom call is sent.
    shutdownIntercom();
    if (isProd() || isTesting()) {
      if (currentUser) {
        const { id, email } = currentUser;
        bootIntercom({
          name: getUserFullName(currentUser),
          email,
          userId: id.toString(),
          userHash: currentUser.intercom_token,
        });
      } else {
        // Anonymous user
        bootIntercom();
      }
    }
  }, [bootIntercom, shutdownIntercom, currentUser]);
  return null;
};
