import { createStore, PrimitiveAtom } from 'jotai';
import { WritableAtom } from 'jotai/vanilla/atom';
import { values } from 'lodash-es';
import { queryParamAtoms } from 'hooks/useQueryParamState';

export const store = createStore();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Atom = PrimitiveAtom<unknown> | WritableAtom<unknown, any, unknown>;

export const createStoreWithAtoms = (...atoms: Atom[]) => {
  const newStore = createStore();

  const subscribeAtom = (atom: Atom) => {
    store.sub(atom, () => {
      newStore.set(atom, store.get(atom));
    });
  };

  atoms.forEach(subscribeAtom);
  values(queryParamAtoms).forEach(subscribeAtom);

  return newStore;
};
