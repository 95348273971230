import { useAtomWithUserId } from 'hooks/useAtomWithUserId';
import { atomWithStorage } from 'jotai/utils';
import { ConceptAnalysis } from '../type';
import { useMemo } from 'react';
import { useConceptLabAnalysis } from './analysis';

const recentConceptAnalysisAtom = atomWithStorage<ConceptAnalysis[]>(
  'concntric-recent-concept-analysis',
  [],
  undefined,
  { getOnInit: true },
);

export function useRecentConceptAnalysis() {
  const [recentConceptAnalysis, setRecentConceptAnalysis]: [
    ConceptAnalysis[],
    (update: ConceptAnalysis[]) => void,
  ] = useAtomWithUserId(recentConceptAnalysisAtom);

  const { conceptAnalysisQuery } = useConceptLabAnalysis();

  const conceptAnalysisOpened = (conceptAnalysis: ConceptAnalysis) => {
    let updatedAnalysis =
      recentConceptAnalysis?.filter((d) => d.id !== conceptAnalysis.id) ?? [];
    if (updatedAnalysis.length < 4) {
      updatedAnalysis = [...updatedAnalysis, conceptAnalysis];
    } else {
      updatedAnalysis = [...updatedAnalysis.slice(1), conceptAnalysis];
    }
    setRecentConceptAnalysis(updatedAnalysis);
  };

  const filteredRecentConceptAnalysis = useMemo(() => {
    if (
      !conceptAnalysisQuery.isSuccess ||
      !conceptAnalysisQuery.data ||
      !recentConceptAnalysis
    )
      return [];

    return recentConceptAnalysis.filter((r) =>
      conceptAnalysisQuery.data.find((d) => d.id === r.id),
    );
  }, [conceptAnalysisQuery.isSuccess, conceptAnalysisQuery.data, recentConceptAnalysis]);

  return {
    recentConceptAnalysis: filteredRecentConceptAnalysis,
    conceptAnalysisOpened,
  };
}
