import { TextField, TextFieldProps } from '@mui/material';
import { debounce } from 'lodash-es';
import { useState } from 'react';

type DebouncedTextFieldProps = TextFieldProps & {
  debounceTime?: number;
};

export const DebouncedTextField = (props: DebouncedTextFieldProps) => {
  const { debounceTime, onChange, value, ...rest } = props;
  const [cachedValue, setCachedValue] = useState(value);
  const debouncedOnChange = onChange && debounce(onChange, debounceTime ?? 500);

  const handleOnChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = (event) => {
    setCachedValue(event.target.value);
    debouncedOnChange?.(event);
  };

  return <TextField {...rest} value={cachedValue} onChange={handleOnChange} />;
};
