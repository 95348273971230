import { faFileExcel, faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { ReportFileType } from 'types/Reports';

type ModalDownloadButtonsProps<T> = {
  isDownloading: boolean;
  values: T;
  types: ReportFileType[];
  handleSubmit: (values: T) => void;
};

export const ModalDownloadButtons = <T extends object>(
  props: ModalDownloadButtonsProps<T>,
) => (
  <Stack sx={{ mt: 4, gap: 1 }}>
    <Typography variant="textDefaultSemiBold">Download as:</Typography>
    <Box display="flex" gap={2}>
      {props.types.find((t) => t === 'xlsx') ? (
        <LoadingButton
          type="button"
          variant="blueGhost"
          startIcon={<FontAwesomeIcon icon={faFileExcel} />}
          className="selectButton"
          loading={props.isDownloading}
          onClick={() => props.handleSubmit({ ...props.values, type: 'xlsx' })}
        >
          Excel
        </LoadingButton>
      ) : null}
      {props.types.find((t) => t === 'pdf') ? (
        <LoadingButton
          type="button"
          variant="blueGhost"
          startIcon={<FontAwesomeIcon icon={faFilePdf} />}
          className="selectButton"
          loading={props.isDownloading}
          onClick={() => props.handleSubmit({ ...props.values, type: 'pdf' })}
        >
          PDF
        </LoadingButton>
      ) : null}
    </Box>
  </Stack>
);
