import { Box, SxProps, Theme } from '@mui/material';
import { escapeRegExp } from 'lodash-es';

type HighlightSearchProps = {
  text?: string;
  highlight?: string;
  sx?: SxProps<Theme>;
};

export const HighlightSearch = ({
  text = '',
  highlight = '',
  sx,
}: HighlightSearchProps) => {
  if (!highlight.trim()) {
    return (
      <Box display="inline" sx={sx}>
        {text}
      </Box>
    );
  }
  const words = highlight
    .trim()
    .replace(/\s+/, '|')
    .split('|')
    .map((word) => escapeRegExp(word))
    .join('|');

  const regex = new RegExp(`(${words})`, 'gi');
  const parts = text.split(regex);

  return (
    <Box display="inline" sx={sx}>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? (
          <mark key={i}>{part}</mark>
        ) : (
          <span key={i}>{part}</span>
        );
      })}
    </Box>
  );
};
