import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Layout, PrivateURL, PublicURL } from 'Urls';
import PrivateLayout from 'layouts/Private.layout';
import { getProjectCurrentStep, replaceProjectIdPath } from 'hooks/navigate';
import queryString from 'query-string';
import {
  LAST_VISITED_PROJECT_ID,
  LAST_VISITED_PAGE,
  PENDING_PROJECT_REDIRECT,
} from 'utils/constants';
import { isEmpty, omit } from 'lodash-es';
import { useSwitchProject } from 'features/Projects/hook/project';
import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import log from 'loglevel';
import { NotFoundView } from 'views/auth/NotFound404View';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { useProjectById } from 'features/Projects/hook/useProjectById';
import { hasSession } from 'features/Auth/hook/sessionHelpers';

const Redirects = {
  SIGNUP: 'signup',
  RESET: 'reset',
  LOGIN: 'login',
  PROJECT: 'project',
};

export const PrivateRoute = (props: RouteProps) => {
  const location = useLocation();
  const { selectedProjectId } = useSelectedProjectId();

  const localProjectId = localStorage.getItem(LAST_VISITED_PROJECT_ID);
  const projectId =
    selectedProjectId || (localProjectId ? parseInt(localProjectId) : undefined);
  const { projectByIdQuery } = useProjectById({
    id: projectId,
  });

  let redirectToPath: string | null = null;
  let queryParams: string | null = null;
  let token: string | (string | null)[] = '';
  const query = queryString.parse(location.search);
  const { switchToProject } = useSwitchProject();
  const pendingRedirect = localStorage.getItem(PENDING_PROJECT_REDIRECT);

  if (projectByIdQuery.status === 'error') {
    localStorage.removeItem(LAST_VISITED_PROJECT_ID);
    return <NotFoundView />;
  }
  const isBusy = projectByIdQuery.isLoading;

  if (query?.redirect === Redirects.SIGNUP) {
    redirectToPath = PublicURL.COMPLETE_SIGNUP;
    queryParams = new URLSearchParams(
      omit(query, ['redirect']) as Record<string, string>,
    ).toString();
    log.debug('Would redirect to ', Redirects.SIGNUP);
  } else if (query?.redirect === Redirects.RESET) {
    redirectToPath = PublicURL.PASSWORD;
    token = query?.token ?? '';
    log.debug('Would redirect to ', Redirects.RESET);
  } else if (query?.redirect === Redirects.LOGIN) {
    redirectToPath = PublicURL.LOGOUT;
    log.debug('Would redirect to ', PublicURL.LOGOUT);
  } else if (hasSession()) {
    if (pendingRedirect || (query.redirect === Redirects.PROJECT && query.id)) {
      if (pendingRedirect) {
        log.debug('There is a pending redirect');
        localStorage.removeItem(PENDING_PROJECT_REDIRECT);
      }

      const projectId = query.id || pendingRedirect;
      if (projectId && !isNaN(parseInt(projectId as string))) {
        log.debug('Would redirect to project ID ', projectId);
        switchToProject(parseInt(projectId as string), {
          to: replaceProjectIdPath(PrivateURL.PROJECT_UPDATE, projectId as string),
        });
      }
    } else if (location.pathname === Layout.ROOT) {
      if (!isBusy) {
        if (!isEmpty(projectByIdQuery.data) && projectId) {
          redirectToPath = selectedProjectId
            ? replaceProjectIdPath(
                getProjectCurrentStep(projectByIdQuery.data),
                projectId,
              )
            : sessionStorage.getItem(LAST_VISITED_PAGE) ||
              replaceProjectIdPath(
                getProjectCurrentStep(projectByIdQuery.data),
                projectId,
              );
        } else {
          log.debug('Would redirect projects list');
          redirectToPath =
            sessionStorage.getItem(LAST_VISITED_PAGE) || PrivateURL.PROJECT_LIST;
        }
      } else {
        log.debug('This should be a transient state...');
      }
    }
  } else {
    log.debug('There is no session, redirecting to login');
    redirectToPath = PublicURL.LOGOUT;
  }
  if (redirectToPath) log.debug('redirectToPath: ', redirectToPath);

  return hasSession() && isBusy ? (
    <LoadingPage />
  ) : (
    <Route
      {...props}
      render={(renderProps) =>
        redirectToPath ? (
          <Redirect
            to={{
              pathname: redirectToPath,
              state: { from: renderProps.location, token },
              search: queryParams ?? '',
            }}
          />
        ) : (
          <PrivateLayout />
        )
      }
    />
  );
};
