import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { useMemo } from 'react';
import { Option } from 'components/MultiSelect/MultiSelect.type';
import { useProjectDashboardTableFilters } from 'features/project-dashboard/hooks/useProjectDashboardTableFilters';
import { sortBy, uniqBy } from 'lodash-es';
import { useProjectDashboards } from 'features/project-dashboard/hooks/project-dashboard';
import { ProjectDashboard } from 'types/Dashboard';
import { getUserFullnameOrEmail } from 'utils/helpers';

type ItemOption = Option<number>;
type MemberFilterProps = {
  isBusy?: boolean;
};

export const MemberFilter = (props: MemberFilterProps) => {
  const { selectedMembers, setSelectedMembers } = useProjectDashboardTableFilters();
  const { projectDashboardsQuery } = useProjectDashboards();

  const options: ItemOption[] = useMemo(() => {
    const r = !projectDashboardsQuery.data
      ? []
      : (uniqBy(
          projectDashboardsQuery.data
            .map((d) => d.users)
            .filter(Boolean)
            .flat(),
          'id' as keyof Exclude<ProjectDashboard['users'], undefined>[number],
        )
          .map((p) => {
            if (!p) return null;
            const label = getUserFullnameOrEmail(p);
            return {
              label,
              searchableLabel: label,
              value: p.id,
            };
          })
          .filter(Boolean) as ItemOption[]);
    return sortBy(r, 'label');
  }, [projectDashboardsQuery.data]);

  return (
    <MultiSelect
      loading={props.isBusy || projectDashboardsQuery.isLoading}
      options={options}
      title="Member"
      value={selectedMembers}
      onChange={setSelectedMembers}
      enableSearch
      enableSelectAll
      prependTitleOnSelect
      renderOnlyValueCount
      sx={{ maxWidth: '6.5rem' }}
    />
  );
};
