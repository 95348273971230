import { SetStateAction, useCallback, useState } from 'react';

type Value = string | number | boolean;

export function useStorage<T extends Value>(
  key: string,
  initialValue: T,
  storage: Storage,
) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = storage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that persists
  // the new value to localStorage.
  const setValue = useCallback(
    (value: SetStateAction<T> | T) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        if (typeof window !== 'undefined') {
          storage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {}
    },
    [key, storage, storedValue],
  );
  return [storedValue, setValue] as const;
}
