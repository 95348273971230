import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Menu, Typography } from '@mui/material';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { DeleteMenuItem } from 'components/IconMenuItem/DeleteMenuItem';
import { ProjectDashboard } from 'types/Dashboard';
import { useDeleteProjectDashboard } from 'features/project-dashboard/hooks/project-dashboard';
import { useState } from 'react';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { hasEditDashboardSettingPermission } from 'features/project-dashboard/hooks/dashboard-permission';

type ActionsMenuProps = {
  projectDashboard: ProjectDashboard;
  closeFlyout: () => void;
};

export const ProjectDashboardModalActions = (props: ActionsMenuProps) => {
  const { deleteProjectDashboardMutation } = useDeleteProjectDashboard();
  const { confirmToast } = useToastDialogs();
  const { currentUser } = useCurrentUser();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleDelete = () => {
    confirmToast({
      title: 'Delete Dashboard?',
      message: (
        <>
          {'The dashboard '}
          <Typography variant="textDefaultBold">{`“${props.projectDashboard.name}”`}</Typography>
          {' will be '}
          <Typography variant="textDefaultBold">{`permanently deleted`}</Typography>
          {', and it won’t be restored.'}
        </>
      ),
      handleSuccessCallback: () => {
        deleteProjectDashboardMutation.mutate(props.projectDashboard.id, {
          onSuccess: () => {
            props.closeFlyout();
            handleCloseMenu();
          },
        });
      },
    });
  };
  const hasDeletePerm = hasEditDashboardSettingPermission({
    user: currentUser,
    dashboard: props.projectDashboard,
  });
  return hasDeletePerm ? (
    <>
      <IconButton
        id="ellipsis-button"
        aria-controls={isMenuOpen ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleMenuClick}
        sx={{ padding: '3px 10px', mr: 1 }}
      >
        <FontAwesomeIcon icon={faEllipsisVertical} size="xs" />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'ellipsis-button',
        }}
      >
        <DeleteMenuItem
          onClick={handleDelete}
          disabled={deleteProjectDashboardMutation.isPending}
        >
          Delete
        </DeleteMenuItem>
      </Menu>
    </>
  ) : null;
};
