import { Accordion as MuiAccordion, styled } from '@mui/material';

import { AccordionProps } from '@mui/material/Accordion/Accordion';

export const CleanAccordion = styled(({ children, ...props }: AccordionProps) => {
  return (
    <MuiAccordion disableGutters elevation={0} {...props}>
      {children}
    </MuiAccordion>
  );
})(() => ({
  '&::before': {
    display: 'none',
  },
  border: 'none',
  boxShadow: 'none',
}));
