import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { OmniClassCategory } from 'types/OmniClassCategory';
import { useRecentOmniClassCategories } from 'hooks/omni-class-category/useRecentOmniClassCategories';
import { CategoryFullPath } from './CategoryFullPath';
import { findCategoryById } from 'utils/categories';
import { useMixpanel } from 'hooks/useMixpanel';

type RecentCategoriesProps = {
  selectedCategories: OmniClassCategory[];
  onCategorySelection: (category: OmniClassCategory) => void;
  availableCategories: Record<number, OmniClassCategory>;
};

export const RecentCategories = (props: RecentCategoriesProps) => {
  const { recentOmniClassCategoriesQuery } = useRecentOmniClassCategories();
  const { mixpanel } = useMixpanel();

  // There is the change that the recently used categories are not in the main
  // list and we should remove them from here
  const filteredCategories =
    recentOmniClassCategoriesQuery.data?.filter(
      (category) => !!findCategoryById(props.availableCategories, category.id),
    ) ?? [];
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <Typography variant="textDefaultBold" sx={{ color: 'grey.400' }}>
          Recently used
        </Typography>
        <Chip label={filteredCategories.length} />
      </Box>
      {recentOmniClassCategoriesQuery.isLoading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          component={Paper}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
          <Table aria-label="recently used omniclass table" size="small">
            <TableBody>
              {filteredCategories.length ? (
                filteredCategories.map((category) => {
                  const isSelected =
                    !!props.selectedCategories.length &&
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    props.selectedCategories.at(-1)!.id === category.id;

                  return (
                    <TableRow
                      key={category.id}
                      onClick={() => {
                        mixpanel.click('select', 'recently used category', {
                          isSelected,
                          categoryId: category.id,
                        });
                        props.onCategorySelection(category);
                      }}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: isSelected ? 'blue.50' : 'none',
                        '&:hover': {
                          backgroundColor: isSelected ? 'blue.50' : 'grey.50',
                          '& .selectTag': {
                            visibility: 'visible',
                          },
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <CategoryFullPath categoryId={category.id} />
                      </TableCell>
                      <TableCell sx={{ width: '7.5rem' }}>
                        <Box
                          alignSelf="center"
                          display="flex"
                          ml={2}
                          color="primary.main"
                          fontSize="1.5rem"
                          alignItems="center"
                          visibility={isSelected ? 'visible' : 'hidden'}
                          className="selectTag"
                        >
                          <Box mr={1}>
                            <FontAwesomeIcon icon={isSelected ? faCheck : faPlus} />
                          </Box>
                          <Typography variant="textDefaultBold">
                            {isSelected ? 'Selected' : 'Select'}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow sx={{ height: '44.5px' }}>
                  <TableCell component="th" scope="row">
                    <Typography variant="textDefaultSemiBold" sx={{ color: 'grey.400' }}>
                      Here you will find all categories recently selected...
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
