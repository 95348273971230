import { Box, SxProps, Theme, Typography } from '@mui/material';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MemberChip } from 'components/CustomChips/MemberChip';
import { Notification } from 'types/Notification';
import { Link } from 'react-router-dom';
import moment from 'moment';

type NotificationItemProps = {
  sx?: SxProps<Theme>;
  notification: Notification;
  onClick: () => void;
};

export function NotificationItem({ notification, sx, onClick }: NotificationItemProps) {
  return (
    <Box
      component={Link}
      to={notification.link}
      onClick={() => {
        onClick();
      }}
      sx={[
        {
          display: 'flex',
          p: 2.5,
          '&:hover': {
            '& .item-action': {
              textDecoration: 'underline',
            },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box sx={{ mr: 1.5 }}>
        <MemberChip
          member={notification.creator}
          sx={{
            width: '2.5rem',
            height: '2.5rem',
            borderRadius: '100%',
            fontSize: '0.875rem',
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Typography variant="textDefaultBold" sx={{ color: 'grey.700' }}>
            {notification.event_data.title}
          </Typography>
          <Typography
            variant="textDefaultBold"
            sx={{ color: 'grey.400', pl: 0.875, whiteSpace: 'nowrap' }}
          >
            {moment(notification.date_created).fromNow()}
          </Typography>
        </Box>

        <Typography
          className="item-action"
          variant="textDefaultMedium"
          sx={{ color: 'blue.700' }}
        >
          {notification.event_data.action}
        </Typography>
        <Typography variant="textSmall" sx={{ color: 'grey.600' }}>
          {notification.event_data.subtitle}
        </Typography>
      </Box>
      {!notification.is_read ? (
        <Box
          sx={{
            color: 'blue.700',
            ml: 'auto',
            pt: 0.375,
          }}
        >
          <FontAwesomeIcon icon={faCircle} />
        </Box>
      ) : null}
    </Box>
  );
}
