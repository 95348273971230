import { OmniClassCategory } from 'types/OmniClassCategory';
import { omit } from 'lodash-es';

// Find the passed value (a category id) deep in the object structure
// and set it as a selected categories array, stating from the parent until the
// selected node
export const findCategoryBranch = (
  indexedCategories: Record<number, OmniClassCategory>,
  id?: number,
) => {
  if (id) {
    const findSelectedArray = (
      indexedSubcategories: Record<number, OmniClassCategory>,
      selected: OmniClassCategory[],
    ): OmniClassCategory[] | null => {
      const keys = Object.keys(indexedSubcategories);
      for (let i = 0; i < keys.length; i++) {
        const key = parseInt(Object.keys(indexedSubcategories)[i]);
        const newSelectedArray = selected.concat(
          omit(indexedSubcategories[key], 'subCategories'),
        );
        if (key === id) {
          return newSelectedArray;
        } else if (indexedSubcategories[key].subCategories) {
          const deepSelectedArray = findSelectedArray(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            indexedSubcategories[key].subCategories!,
            newSelectedArray,
          );
          if (deepSelectedArray) return deepSelectedArray;
        }
      }
      return null;
    };
    return findSelectedArray(indexedCategories, []) || [];
  }
  return [];
};

export const findCategoryById = (
  indexedSubCategories: Record<number, OmniClassCategory>,
  idToFind: number,
): OmniClassCategory | null => {
  if (indexedSubCategories[idToFind]) {
    return indexedSubCategories[idToFind];
  } else {
    const keys = Object.keys(indexedSubCategories).map((v) => parseInt(v));
    let foundNode = null;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const node = indexedSubCategories[key];
      if (node.subCategories) {
        foundNode = findCategoryById(node.subCategories, idToFind);
      }
      if (foundNode) break;
    }
    return foundNode;
  }
};

// Search for the selected node and return an array of the selected one and all its children
// in no particular order to be use as a search filter
export const findNodeAndChildren = (
  indexedCategories: Record<number, OmniClassCategory>,
  id?: number,
) => {
  if (id) {
    const selectedNode = findCategoryById(indexedCategories, id);
    const nodeIds = [];
    if (selectedNode) {
      nodeIds.push(selectedNode.id);
      const pendingNodes = selectedNode.subCategories
        ? Object.values(selectedNode.subCategories)
        : [];
      while (pendingNodes.length) {
        const pendingNode = pendingNodes.pop() as OmniClassCategory;
        nodeIds.push(pendingNode.id);
        if (pendingNode?.subCategories) {
          pendingNodes.push(...Object.values(pendingNode?.subCategories));
        }
      }
    }
    return nodeIds;
  }
  return [];
};
