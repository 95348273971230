import { useTheme } from '@mui/material/styles';
import { toastAtom, useToastDialogs } from 'hooks/useToastDialogs';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useAtomValue } from 'jotai';

const Z_INDEX = 1500;
const dialogPx = '32px';

export function ConfirmToastDialog() {
  const theme = useTheme();
  const toast = useAtomValue(toastAtom);
  const { resetToast } = useToastDialogs();
  if (!toast || toast.type !== 'confirm') return null;

  const { params } = toast;
  const confirmVariant = params.variant === 'info' ? 'text' : 'redFilled';

  return (
    <Dialog
      open={true}
      onClose={() => {
        resetToast();
        if (params.handleCancelCallback) {
          params.handleCancelCallback();
        }
      }}
      fullWidth
      maxWidth={toast.params.maxWidth ?? 'xs'}
      sx={{ zIndex: Z_INDEX }}
    >
      <DialogTitle
        sx={{
          px: dialogPx,
          display: 'flex',
          gap: '12px',
          py: '24px',
          alignItems: 'center',
        }}
      >
        {params.variant === 'info' ? (
          <FontAwesomeIcon
            icon={faCircleInfo}
            size="sm"
            color={theme.palette.blue[700]}
          />
        ) : (
          <FontAwesomeIcon
            icon={faDiamondExclamation}
            size="sm"
            color={theme.palette.cautionRed[700]}
          />
        )}

        <Typography variant="h3SemiBold">{params.title || 'Are you sure?'}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: dialogPx, pb: '48px' }}>
        <Typography variant="textDefaultMedium">{params.message}</Typography>
      </DialogContent>
      <DialogActions sx={{ px: dialogPx, py: '16px' }}>
        <Button
          variant="greyGhost"
          sx={{ px: '16px' }}
          onClick={() => {
            resetToast();
            if (params.handleCancelCallback) {
              params.handleCancelCallback();
            }
          }}
        >
          {params.cancelButtonLabel || 'Cancel'}
        </Button>
        <Button
          variant={confirmVariant}
          sx={{ px: '30px' }}
          onClick={() => {
            resetToast();
            if (params.handleSuccessCallback) {
              params.handleSuccessCallback();
            }
          }}
        >
          {params.buttonLabel || 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
