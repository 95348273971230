import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ChipClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    blueGhost: true;
    outlined: true;
    green: true;
    red: true;
    yellow: true;
    lightGrey: true;
  }
  interface ChipPropsSizeOverrides {
    extraSmall: true;
    small: true;
    medium: true;
    large: true;
  }
}

const customSizes = {
  extraSmall: '1rem',
  small: '1.25rem',
  medium: '1.375rem',
  large: '1.5rem',
};

const customFontSizes = {
  extraSmall: '0.75rem',
} as const;

type MuiChipStyleOverrides = Partial<
  OverridesStyleRules<keyof ChipClasses, 'MuiChip', Theme>
>;

export const MuiChip = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      fontSize:
        ownerState.size && ownerState.size in customFontSizes
          ? customFontSizes[ownerState.size as keyof typeof customFontSizes]
          : '0.875rem',
      height: ownerState.size ? customSizes[ownerState.size] : ownerState.size,
      lineHeight: ownerState.size ? customSizes[ownerState.size] : ownerState.size,
      '& .MuiChip-label': {
        fontWeight: 600,

        ...(ownerState.size === 'extraSmall'
          ? {
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
            }
          : {}),
      },
      '& .MuiChip-icon': {
        height: '66.66%',
      },
      '& .MuiChip-deleteIcon ': {
        visibility: 'hidden',
        width: '0px',
      },
      // Add padding to avoid having the chip bumping when it's hovered
      // And the delete icon appears
      paddingLeft: ownerState.onDelete ? '6px' : null,
      paddingRight: ownerState.onDelete ? '6px' : null,
      '&:hover': {
        paddingLeft: null,
        paddingRight: null,
      },
      '&:hover .MuiChip-deleteIcon': {
        color: theme.palette.grey[500],
        visibility: 'visible',
        width: '12px',
      },
    }),
  } as MuiChipStyleOverrides,
  defaultProps: {
    deleteIcon: (
      <Box
        sx={{
          borderRadius: '50%',
          background: 'white',
          p: '1px',
          height: '12px',
          width: '12px',
        }}
        component={FontAwesomeIcon}
        icon={faXmark}
      />
    ),
  },
  variants: [
    {
      props: { variant: 'blueGhost' },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.blue[50],
        border: 'none',
        color: theme.palette.primary.main,
        '& .MuiChip-icon': {
          color: theme.palette.primary.main,
        },
      }),
    },
    {
      props: { variant: 'green' },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.successGreen[100],
        border: 'none',
        color: theme.palette.successGreen[700],
        '& .MuiChip-icon': {
          color: theme.palette.successGreen[700],
        },
      }),
    },
    {
      props: { variant: 'red' },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.cautionRed[100],
        border: 'none',
        color: theme.palette.cautionRed[700],
        '& .MuiChip-icon': {
          color: theme.palette.cautionRed[700],
        },
      }),
    },
    {
      props: { variant: 'yellow' },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.categoricYellow[100],
        border: 'none',
        color: theme.palette.categoricYellow[900],
        '& .MuiChip-icon': {
          color: theme.palette.categoricYellow[900],
        },
      }),
    },
    {
      props: { variant: 'filled' },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.grey[100],
        border: 'none',
        color: theme.palette.grey[600],
        '& .MuiChip-icon': {
          color: theme.palette.grey[600],
        },
      }),
    },
  ],
};
