import { ReactNode } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { PwaContext } from './usePwa';

const intervalMS = 60 * 60 * 1000;

export default function PwaProvider({ children }: { children: ReactNode }) {
  const registerSW = useRegisterSW({
    immediate: false,
    onRegisteredSW(swUrl, r) {
      r &&
        setInterval(async () => {
          if (!(!r.installing && navigator)) return;
          if ('connection' in navigator && !navigator.onLine) return;

          try {
            const resp = await fetch(swUrl, {
              cache: 'no-store',
              headers: {
                cache: 'no-store',
                'cache-control': 'no-cache',
              },
            });
            if (resp?.status === 200) await r.update();
          } catch (e) {}
        }, intervalMS);
    },
  });

  return <PwaContext.Provider value={registerSW}>{children}</PwaContext.Provider>;
}
