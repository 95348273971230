import { Box, Stack, Typography } from '@mui/material';
import { OpenFlyoutButton } from 'components/FlyoutDialog/OpenFlyoutButton';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { joinSx } from 'utils/helpers';
import { DEFAULT_PROJECT_DASHBOARD_PORTFOLIO, ProjectDashboard } from 'types/Dashboard';
import { getProjectDashboardUrl } from 'features/project-dashboard/helpers';

export function ProjectDashboardNameTableCell({
  projectDashboard,
}: {
  projectDashboard: ProjectDashboard;
}) {
  const [isHover, setIsHover] = useState(false);
  const isDefault = projectDashboard.id === DEFAULT_PROJECT_DASHBOARD_PORTFOLIO;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <Stack
        sx={joinSx(
          {
            position: 'relative',
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            alignItems: 'center',
          },
          isHover
            ? {
                '& .open-button': {
                  opacity: 0,
                  transition: '100ms ease-out opacity',
                },
                '&:hover .open-button, & .open-button:focus': {
                  opacity: 1,
                  transition: '100ms ease-in opacity',
                },
                '&:hover .tooltip-content, &:focus-within .tooltip-content': {
                  pr: 10,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }
            : {},
        )}
      >
        <Typography
          sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          {projectDashboard.name}
          {isDefault ? (
            <>
              {' '}
              •{' '}
              <Typography
                variant="textSmall"
                color="grey.600"
                sx={{ fontStyle: 'italic' }}
              >
                Default
              </Typography>
            </>
          ) : null}
        </Typography>
        {isHover ? (
          <OpenFlyoutButton
            component={Link}
            to={getProjectDashboardUrl(projectDashboard)}
            className="open-button"
            tooltipText="Open dashboard"
            trackingTag="project dashboards table"
            sx={{
              mx: 1,
              my: 'auto',
            }}
          />
        ) : null}
      </Stack>
    </Box>
  );
}
