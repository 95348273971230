import { useAtom, WritableAtom } from 'jotai';

import { Action, AtomOptions } from 'utils/atoms';

export const useAtomArrayWithReducerActions = <K>(
  arrayAtomWithReducer: WritableAtom<K[], [Action<K>], void>,
  options?: AtomOptions,
) => {
  const [array, dispatch] = useAtom(arrayAtomWithReducer, options);
  const toggle = (value: K) => {
    dispatch({ value, type: 'toggle' });
  };

  const clear = () => dispatch({ type: 'clear' });
  const set = (value: K | K[]) => dispatch({ value, type: 'set' });
  const remove = (value: K) => dispatch({ value, type: 'remove' });
  const add = (value: K) => dispatch({ value, type: 'add' });

  return { array, toggle, clear, set, add, remove };
};
