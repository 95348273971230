import { OutlinedInputClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
declare module '@mui/material/TextField' {
  interface OutlinedInputPropsSizeOverrides {
    small: true;
    medium: true;
    large: true;
  }
}

type MuiOutlinedInputStyleOverrides = Partial<
  OverridesStyleRules<keyof OutlinedInputClasses, 'MuiOutlinedInput', Theme>
>;

const DEFAULT_SIZE = 'medium';

const muiInputBasePaddingMap = {
  small: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  medium: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  large: {
    paddingTop: '9px',
    paddingBottom: '9px',
  },
};

export const MuiOutlinedInput = {
  defaultProps: {
    size: DEFAULT_SIZE,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      borderRadius: theme.textField.borderRadius,

      ...(ownerState.multiline
        ? { padding: '0.375rem 0.5rem' }
        : { height: theme.textField.size[ownerState.size ?? DEFAULT_SIZE] }),

      '& fieldset': {
        top: 0,
        borderColor: theme.palette.grey[200],
      },
      '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.cautionRed[700],
      },
      '& legend': {
        // Hide the white space over the input border
        display: 'none',
      },
      '&:hover': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[300],
        },
        '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.blue[700],
        },
        '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.cautionRed[700],
        },
      },
      '& .MuiInputBase-input': {
        ...muiInputBasePaddingMap[ownerState.size ?? DEFAULT_SIZE],
      },
      '&.MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
        ...(ownerState.multiline
          ? {}
          : muiInputBasePaddingMap[ownerState.size ?? DEFAULT_SIZE]),
        '& .MuiAutocomplete-input': {
          padding: '0!important',
        },
      },
    }),
  } as MuiOutlinedInputStyleOverrides,
};
