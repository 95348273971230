import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagSwallowtail } from '@fortawesome/pro-duotone-svg-icons';

type NotificationLastItemMessageProps = {
  sx?: SxProps<Theme>;
  isEmpty: boolean;
};

export function NotificationLastItemMessage({
  sx,
  isEmpty,
}: NotificationLastItemMessageProps) {
  return (
    <Box
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 0.875,
        },
      ]}
    >
      <Box>
        <FontAwesomeIcon icon={faFlagSwallowtail} size="2xl" />
      </Box>
      <Typography
        variant="textDefaultSemiBold"
        sx={{ maxWidth: '200px', textAlign: 'center' }}
      >
        {isEmpty
          ? 'There are no notifications'
          : 'That’s all your notifications from the last 30 days'}
      </Typography>
    </Box>
  );
}
