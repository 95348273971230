import { atom, Getter, Setter, useAtom, WritableAtom } from 'jotai';
import { atomWithReducer, atomWithStorage } from 'jotai/utils';
import { ObjectValues } from 'types/helpers';

export type AtomOptions = Parameters<typeof useAtom>[1];

// https://jotai.org/docs/advanced-recipes/atom-creators#atom-with-toggle
export const atomWithToggle = (initialValue?: boolean) => {
  const booleanAtom = atom(
    initialValue,
    (get: Getter, set: Setter, nextValue?: boolean) => {
      const update = nextValue ?? !get(booleanAtom);
      set(booleanAtom, update);
    },
  );

  return booleanAtom;
};

const defaultComparisonFn = (a: unknown, b: unknown) => a === b;

// If the neeValue exists in the array is removed, if not, it is added
export const atomWithArrayToggle = <K>(
  initialValue: K[],
  comparisonFn = defaultComparisonFn,
) => {
  const arrayAtom = atom(initialValue, (get, set, nextValue: K | K[]) => {
    const currentValues = get(arrayAtom);
    const index = currentValues.findIndex((value) => comparisonFn(value, nextValue));
    if (index >= 0) {
      const filteredArray = [...currentValues];
      filteredArray.splice(index, 1);
      set(arrayAtom, filteredArray);
    } else {
      set(arrayAtom, currentValues.concat(nextValue));
    }
  });

  return arrayAtom;
};

// Add or remove values to an array explicitly, not allowing repeated values
// If an array is passed as a value type, it is set as the new value
export const atomWithArraySet = <T>(initialValue: T[]) => {
  const arrayAtom = atom(initialValue, (get, set, { nextValue, add = true }) => {
    if (Array.isArray(nextValue)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set(arrayAtom, nextValue as any);
    } else {
      const currentValues = get(arrayAtom);
      if (add) {
        if (!currentValues.includes(nextValue)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          set(arrayAtom, currentValues.concat(nextValue) as any);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        set(arrayAtom, currentValues.filter((value) => value !== nextValue) as any);
      }
    }
  });

  return arrayAtom;
};

const removeItemFromArray = <K>(array: K[], index: number) => {
  const filteredArray = [...array];
  filteredArray.splice(index, 1);
  return filteredArray;
};

const ReduceActions = {
  TOGGLE: 'toggle',
  ADD: 'add',
  REMOVE: 'remove',
  CLEAR: 'clear',
  SET: 'set',
} as const;

type ReduceActionsType = ObjectValues<typeof ReduceActions>;
export type Action<K> = { type: ReduceActionsType; value?: K | K[] };

const arrayReducer = <K>(prevArr: K[], action?: Action<K>) => {
  const type = action?.type ?? 'toggle';
  const value = action?.value;
  const index = Array.isArray(value) ? null : prevArr.findIndex((prev) => prev === value);
  if (
    value !== undefined &&
    index !== null &&
    index < 0 &&
    (type === 'add' || type === 'toggle')
  ) {
    return prevArr.concat(value);
  } else if (
    value !== undefined &&
    index !== null &&
    index >= 0 &&
    (type === 'remove' || type === 'toggle')
  ) {
    return removeItemFromArray(prevArr, index);
  } else if (type === 'clear') {
    return [];
  } else if (value !== undefined && type === 'set') {
    return Array.isArray(value) ? value : [value];
  }
  return prevArr;
};

const getInitialValueFromLocalStorage = (key: string, initialValue: unknown) => {
  const item = localStorage.getItem(key);
  if (item !== null) {
    return JSON.parse(item);
  }
  return initialValue;
};

export const atomArrayWithReducer = <K>(initialValue: K[] = []) => {
  const arrayAtom = atomWithReducer<K[], Action<K>>(initialValue, arrayReducer);
  return arrayAtom as WritableAtom<K[], [Action<K>], void>;
};
export const atomArrayWithReducerLocalStorage = <K>(
  key: string,
  initialValue: K[] = [],
) => {
  const arrayAtom = atomWithReducer<K[], Action<K>>(
    getInitialValueFromLocalStorage(key, initialValue),
    (prev, action) => {
      const updatedValue = arrayReducer(prev, action);
      localStorage.setItem(key, JSON.stringify(updatedValue));
      return updatedValue;
    },
  );
  return arrayAtom as WritableAtom<K[], [Action<K>], void>;
};

export const atomWithOptionalLocalStorage = (key: string, initialValue: unknown) => {
  const baseAtom = atom(getInitialValueFromLocalStorage(key, initialValue));
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const { value, persist } =
        typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, value);
      if (persist) {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(key, JSON.stringify(initialValue));
      }
    },
  );
  return derivedAtom;
};

export function atomWithStorageByProject<Value>(key: string, initialValue: Value) {
  const atoms: Record<number, ReturnType<typeof atomWithStorage<Value>>> = {};

  function getAtom(projectId: number | null) {
    projectId = projectId ?? 0;
    if (!atoms[projectId]) {
      atoms[projectId] = atomWithStorage<Value>(`${key}-${projectId}`, initialValue);
    }
    return atoms[projectId];
  }
  return {
    getAtom,
  };
}
