import { useQueryParamState } from 'hooks/useQueryParamState';
import { useEffect, useRef } from 'react';
import { useBuildingConnectedAuthFlowStatus } from '../hooks/useBuildingConnectedAuthFlowStatus';
import log from 'loglevel';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { useBuildingConnectedLoginMutation } from 'hooks/building-connected/useBuildingConnectedLoginMutation';

export const BuildingConnected = () => {
  const [code] = useQueryParamState<string>({ name: 'code' });
  const [error] = useQueryParamState<string>({ name: 'error' });
  const [errorDescription] = useQueryParamState<string>({ name: 'error_description' });
  const { buildingConnectedLoginMutation } = useBuildingConnectedLoginMutation();
  const { buildConnectedAuthFlowStatus, setBuildConnectedAuthFlowStatus } =
    useBuildingConnectedAuthFlowStatus();
  const { errorToast } = useToastDialogs();
  const loginErrorMessageShownRef = useRef(false);

  useEffect(() => {
    // CPE-4210: we need to make sure that the login endpoint gets called just once with a code
    // I assumed that this component will have only a single instance since it's included in the Private.layout component.
    // This is not the case and multiple instances are created maybe because of this:
    // https://github.com/concntric/fe-app/blob/bd93b4662b3b3e45a0def34fd8f8d2591187337c/src/index.tsx#L64
    // i.e. 2 nested PrivateLayout nested into the two PrivateRoute components created.
    // The workaround here is: we store the code in the window object and don't perform the login call if the code is the same.
    const myWindow = window as Window & { accLoginCode?: string };
    if (error) {
      if (loginErrorMessageShownRef.current) {
        return;
      }
      loginErrorMessageShownRef.current = true;
      errorToast({
        title: 'BuildingConnected Login Error',
        text: errorDescription ?? error,
      });
      setBuildConnectedAuthFlowStatus({ status: 'error' });
      log.error(error);
      return;
    }
    if (code) {
      if (myWindow.accLoginCode === code) {
        log.info(`Ignoring acc login with already used code ${code}`);
        return;
      }
      log.info(`Hit ACC Login with code ${code}`);
      myWindow.accLoginCode = code;
      buildingConnectedLoginMutation.mutate(
        {
          code,
        },
        {
          onSuccess: () => {
            setBuildConnectedAuthFlowStatus({ status: 'authorized' });
          },
          onError: (error) => {
            setBuildConnectedAuthFlowStatus({ status: 'error' });
            log.error(error);

            errorToast({
              text: 'Could not login to BuildingConnected. Please try again or contact support.',
            });
          },
        },
      );
      return;
    }
    if (buildConnectedAuthFlowStatus.status === 'authorizing') {
      if (!buildConnectedAuthFlowStatus.redirect) {
        setBuildConnectedAuthFlowStatus({
          status: 'waiting_for_login',
          redirect: window.location.href,
        });
      }
      setTimeout(() => {
        const authUrl = `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=${
          import.meta.env.VITE_BUILDING_CONNECTED_CLIENT_ID
        }&redirect_uri=${
          import.meta.env.VITE_BUILDING_CONNECTED_REDIRECT_URI
        }&scope=data:read%20data:create%20data:write`;
        window.location.replace(authUrl);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildConnectedAuthFlowStatus, code]);

  return <></>;
};
