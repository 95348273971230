import { themeOptionsTypography } from '../typography';

export const MuiFormHelperText = {
  styleOverrides: {
    root() {
      return {
        ...themeOptionsTypography.textDefault,
      };
    },
  },
};
