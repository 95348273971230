import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';

export const useInvalidateTVDQueries = () => {
  const queryClient = useQueryClient();
  const invalidateTVDQueries = (selectedProjectId: number | null) => {
    queryClient.invalidateQueries({
      queryKey: queryKeys.project(selectedProjectId).tvdForesite,
    });
    queryClient.invalidateQueries({
      queryKey: queryKeys.project(selectedProjectId).foresiteCost,
    });
    queryClient.invalidateQueries({
      queryKey: queryKeys.project(selectedProjectId).foresiteSummary,
    });
    queryClient.invalidateQueries({
      queryKey: queryKeys.project(selectedProjectId).foresiteProgram,
    });
  };

  return invalidateTVDQueries;
};
