import { isEmpty } from 'lodash-es';
import { useState, useEffect, useMemo } from 'react';
import avatar from 'assets/img/faces/face-0.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, Grid, IconButton, Menu, Tooltip, Typography } from '@mui/material';

import { faClockNine, faRedo } from '@fortawesome/pro-light-svg-icons';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { TeamMember } from 'types/User';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { IconMenuItem } from 'components/IconMenuItem/IconMenuItem';
import { DeleteMenuItem } from 'components/IconMenuItem/DeleteMenuItem';
import { getUserFullnameOrEmail, joinSx } from 'utils/helpers';
import { usePermissionRoles } from 'features/ManageUsers/hooks/permissionRoles';
import { useResendInviteUser } from 'features/ManageUsers/hooks/companyUsers';

type ProjectMemberRowProps = {
  member: TeamMember;
  onRemoveMember: (member: TeamMember) => void;
};

export const ProjectMemberRow = ({ member, onRemoveMember }: ProjectMemberRowProps) => {
  // Global state
  const { currentUser } = useCurrentUser();

  const {
    permissionRolesQuery: { data: roles },
  } = usePermissionRoles();

  const { resendInviteMutation } = useResendInviteUser();
  const { selectedProjectId } = useSelectedProjectId();
  const { successToast } = useToastDialogs();
  // Local state
  const [roleName, setRoleName] = useState('');
  useEffect(() => {
    if (!roles || !member) {
      return;
    }
    const role =
      !isEmpty(member.named_groups) &&
      roles.find((role) => role.name === member.named_groups[0]);
    if (role) {
      setRoleName(role.name);
    }
  }, [member, roles]);

  const isCurrentUser = useMemo(
    () => currentUser && currentUser.email === member.email,
    [member, currentUser],
  );
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const label = getUserFullnameOrEmail(member);

  return (
    <>
      <Grid item xs={8} data-testid="name-column">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={avatar}
            sx={{
              width: '24px',
              height: '24px',
              borderRadius: '30px',
              marginRight: '5px',
            }}
          />
          <Tooltip
            title={label !== member.email ? member.email : ''}
            arrow
            placement="right"
          >
            <Typography
              noWrap
              sx={{
                textOverflow: 'ellipsis',
              }}
            >
              {label} {isCurrentUser && '(You)'}
            </Typography>
          </Tooltip>
          {member.is_invite_pending ? (
            <Tooltip title="Pending response" arrow>
              <Chip
                size="small"
                sx={{
                  ml: 1,
                  backgroundColor: 'categoricYellow.100',
                  color: 'categoricYellow.900',
                  '& .MuiChip-label': {
                    pr: 0,
                  },
                  '.MuiChip-icon': {
                    color: 'categoricYellow.900',
                  },
                }}
                icon={
                  <Box
                    component={FontAwesomeIcon}
                    icon={faClockNine}
                    sx={{ height: '0.7em', color: 'categoricYellow.900' }}
                  />
                }
              />
            </Tooltip>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ display: 'flex' }} data-testid="role-column">
        <Tooltip title={roleName} placement="top" arrow>
          <Box
            sx={joinSx(
              {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              isCurrentUser ? { mr: 6 } : { mr: 3 },
            )}
          >
            {roleName}
          </Box>
        </Tooltip>
        {!isCurrentUser ? (
          <Box sx={{ ml: 'auto' }}>
            <IconButton
              id="ellipsis-button"
              aria-controls={isMenuOpen ? 'actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? 'true' : undefined}
              onClick={(event) => setMenuAnchorEl(event.currentTarget)}
              sx={{ padding: '3px 10px', color: 'grey.400' }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} size="xs" />
            </IconButton>
            <Menu
              id="actions-menu"
              anchorEl={menuAnchorEl}
              open={isMenuOpen}
              onClose={() => setMenuAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'ellipsis-button',
              }}
            >
              {member.is_invite_pending ? (
                <IconMenuItem
                  disabled={resendInviteMutation.isPending}
                  icon={faRedo}
                  onClick={() => {
                    if (!selectedProjectId) return;
                    resendInviteMutation.mutate(
                      { userId: member.id },
                      {
                        onSuccess: () => {
                          setMenuAnchorEl(null);
                          successToast({
                            title: 'Invite resent',
                            text: 'Invitation has been resent successfully',
                          });
                        },
                      },
                    );
                  }}
                >
                  Resend invite
                </IconMenuItem>
              ) : null}
              <DeleteMenuItem onClick={() => onRemoveMember(member)}>
                Remove
              </DeleteMenuItem>
            </Menu>
          </Box>
        ) : null}
      </Grid>
    </>
  );
};
