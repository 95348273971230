import { faChartMixed, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { FlyoutDialogTitleIcon } from 'components/FlyoutDialog/FlyoutDialogTitleIcon';
import { ReactNode } from 'react';

type GenericExportDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
};

export const GenericExportDialog = ({
  open,
  onClose,
  title,
  children,
}: GenericExportDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': ({ breakpoints }) => ({
          minWidth: breakpoints.values.xsplus,
        }),
      }}
    >
      <DialogTitle sx={{ p: 2 }} data-testid="report-title">
        <FlyoutDialogTitleIcon icon={faChartMixed} />
        <Typography variant="h3SemiBold" component="span">
          {title}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ height: '2rem', width: '2rem', right: 8, position: 'absolute' }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 2, pb: 1, minHeight: '18rem' }}>{children}</DialogContent>
      <DialogActions
        sx={{
          p: 2,
          borderTop: 1,
          borderColor: 'divider',
          justifyContent: 'flex-start',
          gap: 3,
        }}
      >
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};
