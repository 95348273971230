import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { useMemo } from 'react';
import { Option } from 'components/MultiSelect/MultiSelect.type';
import { useProjectDashboardTableFilters } from 'features/project-dashboard/hooks/useProjectDashboardTableFilters';
import { sortBy, uniqBy } from 'lodash-es';
import { ProjectDashboard } from 'types/Dashboard';
import { useProjectDashboards } from 'features/project-dashboard/hooks/project-dashboard';

type ItemOption = Option<number>;
type ProjectGroupFilterProps = {
  isBusy?: boolean;
};

export const ProjectGroupFilter = (props: ProjectGroupFilterProps) => {
  const { selectedProjectGroups, setSelectedProjectGroups } =
    useProjectDashboardTableFilters();
  const { projectDashboardsQuery } = useProjectDashboards();

  const options: ItemOption[] = useMemo(() => {
    const r = !projectDashboardsQuery.data
      ? []
      : (uniqBy(
          projectDashboardsQuery.data
            .map((d) => d.project_groups)
            .filter(Boolean)
            .flat(),
          'id' as keyof Exclude<ProjectDashboard['project_groups'], undefined>[number],
        )
          .map((p) => {
            if (!p) return null;
            return {
              label: p.name,
              searchableLabel: p.name,
              value: p.id,
            };
          })
          .filter(Boolean) as ItemOption[]);
    return sortBy(r, 'label');
  }, [projectDashboardsQuery.data]);
  return (
    <MultiSelect
      loading={props.isBusy || projectDashboardsQuery.isLoading}
      options={options}
      title="Group"
      value={selectedProjectGroups}
      onChange={setSelectedProjectGroups}
      enableSearch
      enableSelectAll
      prependTitleOnSelect
      renderOnlyValueCount
      sx={{ maxWidth: '6.5rem' }}
    />
  );
};
