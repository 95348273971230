import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { ModelPermission, Role } from 'types/User';
import { queryKeys } from 'utils/reactQuery';
import { useSelectedRoleDetails } from './useSelectedRoleDetails';
import smallLogo from 'assets/img/concntric-logo-sm-light.svg';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';

export const systemRoleCreator = {
  id: 0,
  first_name: 'ConCntric',
  email: 'support@concntric.com',
  last_name: 'Inc',
  avatar: smallLogo,
};

export const usePermissionRoles = () => {
  const permissionRolesQuery = useQuery({
    queryKey: queryKeys.permissionRoles,
    queryFn: ({ signal }) => {
      return ApiService.get(Resources.PERMISSION_ROLES, { signal }).then(
        (res) => res.data as Role[],
      );
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  return { permissionRolesQuery };
};

export const usePermissionRolePermissions = (roleDetailsId: number | undefined) => {
  const permissionRolePermissionsQuery = useQuery({
    queryKey: queryKeys.permissionRolePermissions(roleDetailsId),
    queryFn: ({ signal }) => {
      const endPoint = Resources.PERMISSION_ROLE_PERMISSIONS.replace(
        '<int:pk>',
        String(roleDetailsId),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ModelPermission[],
      );
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!roleDetailsId,
  });
  return { permissionRolePermissionsQuery };
};

export const useSelectedPermissionRolePermissions = () => {
  const { selectedRoleDetailsId } = useSelectedRoleDetails();
  const { permissionRolePermissionsQuery: selectedPermissionRolePermissionsQuery } =
    usePermissionRolePermissions(selectedRoleDetailsId);

  return { selectedPermissionRolePermissionsQuery };
};

export const usePermissionRoleClone = () => {
  const queryClient = useQueryClient();

  const permissionRoleCloneMutation = useMutation({
    mutationFn: (roleId: number) => {
      const endPoint = Resources.PERMISSION_ROLE_CLONE.replace(
        '<int:pk>',
        String(roleId),
      );
      return ApiService.post(endPoint);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.permissionRoles });
    },
  });
  return { permissionRoleCloneMutation };
};

export const useSavePermissionRolePermissions = () => {
  const queryClient = useQueryClient();

  const savePermissionRolePermissionsMutation = useMutation({
    mutationFn: ({
      roleId,
      permissions,
    }: {
      roleId: number;
      permissions: ModelPermission[];
    }) => {
      const endPoint = Resources.PERMISSION_ROLE_PERMISSIONS.replace(
        '<int:pk>',
        String(roleId),
      );
      return ApiService.post(endPoint, permissions);
    },
    onMutate: async ({ roleId, permissions }) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.permissionRolePermissions(roleId),
      });
      const previousPermissions = queryClient.getQueryData(
        queryKeys.permissionRolePermissions(roleId),
      );
      queryClient.setQueryData(queryKeys.permissionRolePermissions(roleId), permissions);
      return previousPermissions;
    },
    onError: (error, { roleId }, previousPermissions) => {
      if (previousPermissions) {
        queryClient.setQueryData(
          queryKeys.permissionRolePermissions(roleId),
          previousPermissions,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: (_response, _error, { roleId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.permissionRolePermissions(roleId),
      });
    },
  });
  return { savePermissionRolePermissionsMutation };
};

export const useSavePermissionRole = () => {
  const queryClient = useQueryClient();

  const savePermissionRoleMutation = useMutation({
    mutationFn: (role: Role) => {
      const endPoint = Resources.PERMISSION_ROLE.replace('<int:pk>', String(role.id));
      return ApiService.patch(endPoint, {
        ...role,
        members: role.members.map(({ id }) => id),
      });
    },
    onMutate: async (role) => {
      await queryClient.cancelQueries({ queryKey: queryKeys.permissionRoles });
      const previousPermissionRoles = queryClient.getQueryData(queryKeys.permissionRoles);

      if (previousPermissionRoles) {
        queryClient.setQueryData(
          queryKeys.permissionRoles,
          (oldPermissionRoles?: Role[]) =>
            oldPermissionRoles?.map((oldPermissionRole) =>
              oldPermissionRole.id === role.id
                ? { ...oldPermissionRole, ...role }
                : oldPermissionRole,
            ),
        );
      }

      return previousPermissionRoles;
    },
    onError: (error, _role, previousPermissionRoles) => {
      if (previousPermissionRoles) {
        queryClient.setQueryData(queryKeys.permissionRoles, previousPermissionRoles);
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.permissionRoles });
      queryClient.invalidateQueries({ queryKey: queryKeys.companyUsers });
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'project' && query.queryKey[2] === 'moduleMembers',
      });
    },
  });
  return { savePermissionRoleMutation };
};

export const useInvalidateCurrentUserData = () => {
  const { currentUser, loadCurrentUser } = useCurrentUser();
  const { permissionRolesQuery } = usePermissionRoles();
  const queryClient = useQueryClient();

  const invalidateCurrentUserData = (roleId?: number) => {
    const role = roleId
      ? permissionRolesQuery.data?.find((role) => role.id === roleId)
      : undefined;
    if (
      role &&
      currentUser &&
      role.members.some((member) => member.id === currentUser.id)
    ) {
      loadCurrentUser();
      queryClient.invalidateQueries();
    }
  };
  return { invalidateCurrentUserData };
};

export const usePermissionRoleDelete = () => {
  const queryClient = useQueryClient();

  const permissionRoleDeleteMutation = useMutation({
    mutationFn: (roleId: number) => {
      const endPoint = Resources.PERMISSION_ROLE.replace('<int:pk>', String(roleId));
      return ApiService.delete(endPoint);
    },
    onMutate: async (roleId) => {
      await queryClient.cancelQueries({ queryKey: queryKeys.permissionRoles });
      const previousPermissionRoles = queryClient.getQueryData(queryKeys.permissionRoles);

      if (previousPermissionRoles) {
        queryClient.setQueryData(
          queryKeys.permissionRoles,
          (oldPermissionRoles?: Role[]) =>
            oldPermissionRoles?.filter(
              (oldPermissionRole) => oldPermissionRole.id !== roleId,
            ),
        );
      }

      return previousPermissionRoles;
    },
    onError: (error, _role, previousPermissionRoles) => {
      if (previousPermissionRoles) {
        queryClient.setQueryData(queryKeys.permissionRoles, previousPermissionRoles);
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.permissionRoles });
    },
  });
  return { permissionRoleDeleteMutation };
};
