import { DEFAULT_PROJECT_DASHBOARD_PORTFOLIO, ProjectDashboard } from 'types/Dashboard';
import { GridColumns } from 'types/helpers';
import { Box, Chip, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { NONE_OUTLINED_SELECTED_CELL } from 'style/DataGridCommonStyles';
import { OverlapWithBadgeWrapper } from 'components/OverlapWithBadgeWrapper/OverlapWithBadgeWrapper';
import { getUserFullnameOrEmail } from 'utils/helpers';
import { UserBrief } from 'types/User';
import { MemberChip } from 'components/CustomChips/MemberChip';
import moment from 'moment';
import { DATE_FMT } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { ProjectDashboardNameTableCell } from 'features/project-dashboard/table/ProjectDashboardNameTableCell';
import { defaultEstimateWidths } from 'features/Estimate/hooks/useEstimateTableWidths';
import { ProjectDashboardTableActions } from 'features/project-dashboard/table/ProjectDashboardTableActions';
import { DASHBOARD_VISIBILITIES } from 'features/project-dashboard/helpers';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';

export function ProjectDashboardTable({
  rows,
  loading,
}: {
  rows: ProjectDashboard[];
  loading: boolean;
}) {
  const { currentUser } = useCurrentUser();
  const columns: GridColumns<ProjectDashboard, 'actions'> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <ProjectDashboardNameTableCell projectDashboard={params.row} />;
      },
    },
    {
      field: 'project_groups',
      headerName: 'Project Groups',
      width: 200,
      renderCell: (params) => {
        const groups = params.row.project_groups;
        if (!groups?.length) return '-';

        return (
          <Typography>
            {groups[0].name}{' '}
            {groups.length > 1 ? (
              <Chip
                component="span"
                label={`+${groups.length - 1}`}
                sx={{
                  ml: 0.5,
                  '& .MuiChip-label': { px: 0.75, fontWeight: 700 },
                }}
              />
            ) : null}
          </Typography>
        );
      },
    },
    {
      field: 'related_projects',
      headerName: 'Projects',
      width: 200,
      renderCell: (params) => {
        if (params.row.id === DEFAULT_PROJECT_DASHBOARD_PORTFOLIO) {
          return 'All';
        }
        const projects = params.row.related_projects;
        if (!projects?.length) return '-';

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography
              component="span"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {projects[0].name}
            </Typography>
            {projects.length > 1 ? (
              <Chip
                label={`+${projects.length - 1}`}
                sx={{
                  ml: 0.5,
                  '& .MuiChip-label': { px: 0.75, fontWeight: 700 },
                }}
              />
            ) : null}
          </Box>
        );
      },
    },
    {
      field: 'users',
      headerName: 'Members',
      width: 200,
      renderCell: (params) => {
        let users = params.row.users;
        if (params.row.id === DEFAULT_PROJECT_DASHBOARD_PORTFOLIO) {
          users = currentUser ? [currentUser] : [];
        }
        users = users || [];
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <OverlapWithBadgeWrapper
              labels={users.map(getUserFullnameOrEmail)}
              badgeTop={16}
            >
              {users.map((member: UserBrief) => (
                <MemberChip key={member.id} member={member} />
              ))}
            </OverlapWithBadgeWrapper>
          </Box>
        );
      },
    },
    {
      field: 'created_at',
      headerName: 'Date created',
      width: 150,
      renderCell: (params) => {
        if (!params.row.created_at) return '';
        return (
          <Box>
            <Box
              component={FontAwesomeIcon}
              icon={faCalendar}
              sx={{ color: 'grey.400', mr: 1 }}
            />
            {moment(params.row.created_at).format(DATE_FMT)}
          </Box>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      renderCell: (params) => {
        return (
          <Typography variant="textDefaultSemiBold">
            {DASHBOARD_VISIBILITIES[params.row.type]?.label || ''}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      resizable: false,
      width: defaultEstimateWidths.actions,
      renderCell: (param) =>
        param.row.id !== DEFAULT_PROJECT_DASHBOARD_PORTFOLIO ? (
          <ProjectDashboardTableActions projectDashboard={param.row} />
        ) : null,
    },
  ];

  return (
    <DataGridPremium
      rows={rows}
      autoHeight
      columns={columns}
      loading={loading}
      hideFooter
      disableColumnMenu
      initialState={{
        pinnedColumns: {
          right: ['actions'],
        },
      }}
      sx={{
        ...NONE_OUTLINED_SELECTED_CELL,
        '& .MuiDataGrid-row': {
          cursor: 'pointer',
        },
      }}
    />
  );
}
