import {
  faBedEmpty,
  faSquareDashed,
  faBedPulse,
  faBriefcase,
  faWater,
  faAnchor,
  faMicrophoneStand,
  faBoxesStacked,
  faCube as farCube,
  faHeat,
  faDungeon,
  faRectangle,
  faPump,
  IconPrefix,
  IconName,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faPersonSeat,
  faGavel,
  faBorderAll,
  faRankingStar,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faBullseyeArrow,
  faCircleDot,
  faRoad,
  faContainerStorage,
  faCube,
  faKey,
  faObjectsColumn,
  faStore,
  faBuilding,
  faBolt,
} from '@fortawesome/pro-light-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faBedEmpty,
  faSquareDashed,
  faBedPulse,
  faBriefcase,
  faWater,
  faAnchor,
  faMicrophoneStand,
  faBoxesStacked,
  farCube,
  faHeat,
  faDungeon,
  faRectangle,
  faPump,
  faPersonSeat,
  faGavel,
  faBorderAll,
  faRankingStar,
  faBullseyeArrow,
  faCircleDot,
  faRoad,
  faContainerStorage,
  faCube,
  faKey,
  faObjectsColumn,
  faStore,
  faBuilding,
  faBolt,
);

export const BaseUnitIconsMap = {
  seats: ['fas', 'person-seat'],
  courtrooms: ['fas', 'gavel'],
  cells: ['fas', 'border-all'],
  beds: ['far', 'bed-empty'],
  acres: ['far', 'square-dashed'],
  targets: ['fal', 'bullseye-arrow'],
  holes: ['fal', 'circle-dot'],
  courts: ['fas', 'ranking-star'],
  lanes: ['fal', 'road'],
  units: ['fal', 'container-storage'],
  rsf: ['fal', 'cube'], // not found, use default
  procedure_rooms: ['fal', 'cube'], // not found, use default
  treatment_rooms: ['far', 'bed-pulse'],
  kennels: ['fal', 'cube'],
  keys: ['fal', 'key'],
  lots: ['fal', 'objects-column'],
  stalls: ['fal', 'store'],
  office_units: ['fal', 'building'],
  work_stations: ['far', 'briefcase'],
  bays: ['far', 'water'],
  docks: ['far', 'anchor'],
  stages: ['far', 'microphone-stand'],
  tons_stored: ['far', 'boxes-stacked'],
  gallons: ['far', 'cube'],
  cy: ['far', 'cube'],
  btu: ['far', 'heat'],
  lf: ['far', 'cube'],
  kwh: ['far', 'cube'],
  mw: ['far', 'cube'],
  watts: ['fal', 'bolt'],
  gates: ['far', 'dungeon'],
  pads: ['far', 'rectangle'],
  slips: ['far', 'cube'], // not found, use default
  pumps: ['far', 'pump'],
  spaces: ['far', 'rectangle'],
} as Record<string, [IconPrefix, IconName]>;
