import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, MenuItem } from '@mui/material';
import { IconMenuItemProps } from './types';
import { joinSx } from 'utils/helpers';

export const IconMenuItem = ({ children, icon, iconSx, ...props }: IconMenuItemProps) => (
  <MenuItem {...props}>
    <Box
      sx={joinSx({ mr: 1, pb: '2px' }, iconSx)}
      component={FontAwesomeIcon}
      icon={icon}
    />
    {children}
  </MenuItem>
);
