import { CustomCostGroupDefinition } from 'types/CostGroup';
import { useCurrentCompany } from './useCurrentCompany';
import { useQuery } from '@tanstack/react-query';
import { Resources } from 'api/Resources';
import { queryKeys } from 'utils/reactQuery';
import { keyBy } from 'lodash-es';
import { ApiService } from 'api/ApiService';
import { useMemo } from 'react';

export const useCustomCostGroupDefinitions = <
  T extends Omit<CustomCostGroupDefinition, 'custom_codes'> = Omit<
    CustomCostGroupDefinition,
    'custom_codes'
  >,
>({
  projectId = null,
  milestoneId = null,
  includeDetails = false,
  excludeEstimatesOnly = false,
  includeChildren = false,
}: {
  projectId?: number | null | undefined;
  milestoneId?: number | null | undefined;
  excludeEstimatesOnly?: boolean;
  includeDetails?: boolean;
  includeChildren?: boolean;
} = {}) => {
  const { currentCompany } = useCurrentCompany();

  const customCostGroupDefinitionsQuery = useQuery({
    queryKey: queryKeys
      .company(currentCompany?.id)
      .customCostGroupDefinitions()
      .details(includeDetails, projectId, milestoneId, includeChildren),
    queryFn: ({ signal }) => {
      const endpoint = Resources.CUSTOM_CODE_DEFINITIONS as string;
      let params = {};
      if (milestoneId) {
        params = { milestone_id: milestoneId };
      }
      if (projectId) {
        params = { ...params, project_id: projectId };
      }
      if (includeDetails) {
        params = { ...params, include_details: true };
      }
      if (includeChildren) {
        params = { ...params, include_children: true };
      }
      return ApiService.get(endpoint, { signal, params }).then((res) => res.data as T[]);
    },
    enabled: !!currentCompany,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const allDefinitions = useMemo(() => {
    const r = excludeEstimatesOnly
      ? customCostGroupDefinitionsQuery.data?.filter(
          (definition) => !definition.is_estimates_only,
        )
      : customCostGroupDefinitionsQuery.data;

    return r || [];
  }, [customCostGroupDefinitionsQuery.data, excludeEstimatesOnly]);

  const { customCostGroupDefinitions, customCostGroupDefinitionsById } = useMemo(() => {
    const customCostGroupDefinitions = allDefinitions.filter(
      (definition) => !definition.is_standard,
    );
    const customCostGroupDefinitionsById = keyBy(customCostGroupDefinitions, 'id');
    return { customCostGroupDefinitions, customCostGroupDefinitionsById };
  }, [allDefinitions]);

  const { standardCostGroupDefinitions, standardCostGroupDefinitionsById } =
    useMemo(() => {
      const standardCostGroupDefinitions = allDefinitions.filter(
        (definition) => definition.is_standard,
      );
      const standardCostGroupDefinitionsById = keyBy(standardCostGroupDefinitions, 'id');
      return { standardCostGroupDefinitions, standardCostGroupDefinitionsById };
    }, [allDefinitions]);

  return {
    customCostGroupDefinitionsQuery: {
      ...customCostGroupDefinitionsQuery,
      costGroupDefinitionsById: useMemo(
        () => keyBy(allDefinitions, 'id') as Record<string | number, T>,
        [allDefinitions],
      ),
      costGroupDefinitions: allDefinitions,
      standardCostGroupDefinitionsById,
      standardCostGroupDefinitions,
      customCostGroupDefinitions,
      customCostGroupDefinitionsById,
    },
  };
};
