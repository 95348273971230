import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { OmniClassCategory } from 'types/OmniClassCategory';
import { queryKeys } from 'utils/reactQuery';

export const useFavoriteOmniClassCategories = () => {
  const favoriteOmniClassCategoriesQuery = useQuery({
    queryKey: queryKeys.favoriteCategories,
    queryFn: () =>
      ApiService.get(Resources.FAVORITE_CATEGORIES).then(
        (res) => res.data as OmniClassCategory[],
      ),
    staleTime: Infinity,
  });

  return { favoriteOmniClassCategoriesQuery };
};

export const useUpdateFavoriteOmniClassCategory = () => {
  const queryClient = useQueryClient();

  const updateFavoriteOmniClassCategoryQuery = useMutation({
    mutationFn: ({
      categoryId,
      isFavorite,
    }: {
      categoryId: number;
      isFavorite: boolean;
    }) => {
      const endPoint = Resources.FAVORITE_CATEGORY.replace(
        '<int:pk>',
        String(categoryId),
      );
      return isFavorite ? ApiService.post(endPoint) : ApiService.delete(endPoint);
    },
    onMutate: async ({ categoryId, isFavorite }) => {
      await queryClient.cancelQueries({ queryKey: queryKeys.categories });
      const previousCategories = queryClient.getQueryData(queryKeys.categories);

      if (previousCategories) {
        queryClient.setQueryData(
          queryKeys.categories,
          (oldCategoriesData?: {
            categories: OmniClassCategory[];
            mappedCategories: Record<number, OmniClassCategory>;
          }) => {
            if (oldCategoriesData) {
              const categories = oldCategoriesData.categories.map((category) =>
                category.id === categoryId
                  ? { ...category, is_favorite: isFavorite }
                  : category,
              );
              const mappedCategories = oldCategoriesData.mappedCategories[categoryId]
                ? {
                    ...oldCategoriesData.mappedCategories,
                    [categoryId]: {
                      ...oldCategoriesData.mappedCategories[categoryId],
                      is_favorite: isFavorite,
                    },
                  }
                : oldCategoriesData.mappedCategories;
              return { categories, mappedCategories };
            }
          },
        );
      }

      return previousCategories;
    },
    onError: (error, _data, previousCategories) => {
      if (previousCategories) {
        queryClient.setQueryData(queryKeys.categories, previousCategories);
      }
      log.error(error instanceof Error ? error.message : error);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.favoriteCategories });
      queryClient.invalidateQueries({ queryKey: queryKeys.categories });
    },
  });

  return { updateFavoriteOmniClassCategoryQuery };
};
