import { Project } from 'types/Project';

export function isLegacyOrDraftProject(
  project: { status?: Project['status'] } | undefined,
) {
  return Boolean(
    project &&
      project.status &&
      (['LEGACY', 'DRAFT_LEGACY'] as Project['status'][]).includes(project.status),
  );
}

export function isLegacyProject(project: Project | undefined) {
  return Boolean(project && project.status === 'LEGACY');
}

export function isLegacyDraftProject(project: Project | undefined) {
  return Boolean(project && project.status === 'DRAFT_LEGACY');
}
