import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { Option } from 'components/MultiSelect/MultiSelect.type';
import { useProjectDashboardTableFilters } from 'features/project-dashboard/hooks/useProjectDashboardTableFilters';
import { DASHBOARD_VISIBILITIES } from 'features/project-dashboard/helpers';

type ItemOption = Option<string>;
type ProjectDashboadTypeFilterProps = {
  isBusy?: boolean;
};

const options: ItemOption[] = [
  {
    label: DASHBOARD_VISIBILITIES.INTERNAL.label,
    searchableLabel: DASHBOARD_VISIBILITIES.INTERNAL.label,
    value: 'INTERNAL',
  },
  {
    label: DASHBOARD_VISIBILITIES.EXTERNAL.label,
    searchableLabel: DASHBOARD_VISIBILITIES.EXTERNAL.label,
    value: 'EXTERNAL',
  },
];
export const ProjectDashboadTypeFilter = (props: ProjectDashboadTypeFilterProps) => {
  const { selectedTypes, setSelectedTypes } = useProjectDashboardTableFilters();

  return (
    <MultiSelect
      loading={props.isBusy}
      options={options}
      title="Type"
      value={selectedTypes}
      onChange={setSelectedTypes}
      enableSearch
      enableSelectAll
      prependTitleOnSelect
      renderOnlyValueCount
      sx={{ maxWidth: '6.5rem' }}
    />
  );
};
