import { Box, Paper, Popover, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { NotificationList } from './NotificationList';

type NotificationPopoverProps = {
  id: string | undefined;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
};

export function NotificationPopover({ id, anchorEl, onClose }: NotificationPopoverProps) {
  const open = Boolean(anchorEl);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& > .MuiPaper-root': {
          mt: 0.25,
        },
      }}
    >
      <Box
        sx={{
          width: '528px',
        }}
      >
        <Paper sx={{ m: -1, pt: 1, px: 1 }}>
          <Box sx={{ p: 4, display: 'flex', alignItems: 'center', gap: 1.5 }}>
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'blue.100',
                color: 'blue.700',
                backgroundColor: 'blue.50',
                borderRadius: '50%',
                width: 24,
                height: 24,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon icon={faBell} />
            </Box>
            <Typography variant="h3Bold">Notifications</Typography>
          </Box>
        </Paper>
        <NotificationList
          sx={{ pt: 1, maxHeight: '70vh', overflow: 'auto' }}
          onClickItem={onClose}
        />
      </Box>
    </Popover>
  );
}
