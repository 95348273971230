import { BREAKPOINTS_KEYS } from 'utils/constants';

import { createTheme, ThemeOptions } from '@mui/material';

import { customPalette } from './palette';
import { themeOptionsTypography } from './typography';
import { muiCustomizedComponents } from './muiCustomizedComponents';

const breakpointsValues = {
  [BREAKPOINTS_KEYS.xs]: 0,
  [BREAKPOINTS_KEYS.xsplus]: 400,
  [BREAKPOINTS_KEYS.sm]: 640,
  [BREAKPOINTS_KEYS.smplus]: 768,
  [BREAKPOINTS_KEYS.md]: 900,
  [BREAKPOINTS_KEYS.mdplus]: 1024,
  [BREAKPOINTS_KEYS.lg]: 1280,
  [BREAKPOINTS_KEYS.xl]: 1536,
  [BREAKPOINTS_KEYS.xxl]: 1650,
};

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    xsplus: true;
    sm: true;
    smplus: true;
    md: true;
    mdplus: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const themeOptions = {
  breakpoints: {
    values: breakpointsValues,
  },
  typography: themeOptionsTypography,
  palette: customPalette,
  components: muiCustomizedComponents,
} as unknown as ThemeOptions;

const customConstants = {
  navBarHeight: '3rem',
  sideBarWidth: '14.5rem',
  sideBarCollapsedWidth: '3rem',
  bottomBarHeight: '4rem',
  textField: {
    label: {
      fontSize: '0.875rem', // 14px
      mb: '2px',
    },
    borderRadius: '0.5rem', // 8px
    size: {
      small: '1.5rem',
      medium: '2rem',
      large: '2.5rem',
    },
  },
};

const theme = createTheme(themeOptions, customConstants);

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
    bottomBarHeight: string;
    textField: typeof customConstants.textField;
    navBarHeight: string;
    sideBarWidth: string;
    sideBarCollapsedWidth: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    bottomBarHeight?: string;
    textField: typeof customConstants.textField;
    navBarHeight: string;
    sideBarWidth: string;
    sideBarCollapsedWidth: string;
  }
}

export default theme;
