import { MuiButton } from './muiButton';
import { MuiChip } from './muiChip';
import { MuiCssBaseline } from './muiCssBaseline';
import { MuiDataGrid } from './muiDataGrid';
import { MuiDialogTitle } from './muiDialog';
import { MuiPaper } from './muiPaper';
import { MuiSelect } from './muiSelect';
import { MuiTabs } from './muiTabs';
import { MuiTextField } from './muiTextField';
import { MuiAutocomplete } from './muiAutocomplete';
import { MuiOutlinedInput } from './muiOutlinedInput';
import { MuiFormHelperText } from './muiFormHelperText';
import { MuiListItemText } from './muiListItemText';

export const muiCustomizedComponents = {
  MuiButton,
  MuiChip,
  MuiCssBaseline,
  MuiDataGrid,
  MuiDialogTitle,
  MuiPaper,
  MuiSelect,
  MuiTabs,
  MuiTextField,
  MuiAutocomplete,
  MuiOutlinedInput,
  MuiFormHelperText,
  MuiListItemText,
};
