import { faAlignLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { useOmniClassCategory } from 'hooks/omni-class-category/useOmniClassCategory';
import { findCategoryBranch } from 'utils/categories';

type CategoryFullPathProps = {
  categoryId: number;
};

export const CategoryFullPath = (props: CategoryFullPathProps) => {
  const { mappedOmniClassCategories } = useOmniClassCategory();
  const path = mappedOmniClassCategories
    ? findCategoryBranch(mappedOmniClassCategories, props.categoryId)
    : [];

  return path.length
    ? path.map((category, i, arr) => (
        <Box key={category.id} component="span">
          {i > 0 && (
            <Typography
              component="span"
              sx={{ color: 'grey.400', fontWeight: 900, mx: 1 }}
            >
              /
            </Typography>
          )}
          {i === arr.length - 1 && (
            <Box component={FontAwesomeIcon} icon={faAlignLeft} sx={{ mr: 1 }} />
          )}
          <Typography component="span" variant="textDefaultSemiBold">
            {category.name}
          </Typography>
        </Box>
      ))
    : '';
};
