import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { useMemo } from 'react';
import { Option } from 'components/MultiSelect/MultiSelect.type';
import { useProjectDashboardTableFilters } from 'features/project-dashboard/hooks/useProjectDashboardTableFilters';
import { sortBy, uniqBy } from 'lodash-es';
import { useProjectDashboards } from 'features/project-dashboard/hooks/project-dashboard';
import { ProjectDashboard } from 'types/Dashboard';

type ItemOption = Option<number>;
type ProjectFilterProps = {
  isBusy?: boolean;
};

export const ProjectFilter = (props: ProjectFilterProps) => {
  const { selectedProjects, setSelectedProjects } = useProjectDashboardTableFilters();
  const { projectDashboardsQuery } = useProjectDashboards();

  const options: ItemOption[] = useMemo(() => {
    const r = !projectDashboardsQuery.data
      ? []
      : (uniqBy(
          projectDashboardsQuery.data
            .map((d) => d.related_projects)
            .filter(Boolean)
            .flat(),
          'id' as keyof Exclude<ProjectDashboard['related_projects'], undefined>[number],
        )
          .map((p) => {
            if (!p) return null;
            return {
              label: p.name,
              searchableLabel: p.name,
              value: p.id,
            };
          })
          .filter(Boolean) as ItemOption[]);
    return sortBy(r, 'label');
  }, [projectDashboardsQuery.data]);

  return (
    <MultiSelect
      loading={props.isBusy || projectDashboardsQuery.isLoading}
      options={options}
      title="Project"
      value={selectedProjects}
      onChange={setSelectedProjects}
      enableSearch
      enableSelectAll
      prependTitleOnSelect
      renderOnlyValueCount
      sx={{ maxWidth: '6.5rem' }}
    />
  );
};
