import { useEffect, useRef } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import { Formik, FormikProps } from 'formik';
import { NavLink } from 'react-router-dom';
import { PublicURL } from 'Urls';
import newLogo from 'assets/img/concntric-logo-light.svg';
import { object, string } from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { useSessionActions } from '../hook/session';
import { AuthPagePaper } from 'components/PageContainer/AuthPagePaper';
import log from 'loglevel';

type FormFields = {
  email: string;
  password: string;
  rememberMe?: boolean;
};
const loginSchema = object().shape({
  email: string().email('E-mail not valid').required('Email required'),
  password: string().required('Password required'),
});

export const Login = () => {
  const { resetToast, errorToast } = useToastDialogs();
  const formikRef = useRef<FormikProps<FormFields>>(null);
  const { storedUsername, login } = useSessionActions();

  useEffect(() => {
    if (storedUsername) {
      if (formikRef.current) {
        formikRef.current.setFieldValue('email', storedUsername);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthPagePaper>
      <Box component="img" src={newLogo} sx={{ width: '168px', mb: 4 }} />

      <Typography variant="h1" sx={{ mb: 4 }}>
        Log in to ConCntric
      </Typography>
      <Formik
        innerRef={formikRef}
        initialValues={{ email: '', password: '', rememberMe: true }}
        validationSchema={loginSchema}
        onSubmit={async ({ email, password, rememberMe }, { setSubmitting }) => {
          resetToast();
          try {
            await login(email, password, rememberMe);
          } catch (error) {
            let message;
            if (error instanceof Error) {
              log.error(error.stack);
              message = error.message;
            }
            errorToast({
              text: message || 'The service is temporarily unavailable. Try again later.',
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              size="large"
              className="gapForHelperText"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter your work email address"
              placeholder="name@company.com"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={Boolean(errors.email && touched.email)}
              helperText={touched.email && errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputAdornment>
                ),
              }}
              inputProps={{ 'data-1p-ignore': false }}
            />

            <TextField
              size="large"
              className="gapForHelperText"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              placeholder="Enter password..."
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={Boolean(errors.password && touched.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faLockKeyhole} />
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.rememberMe}
                  color="primary"
                  onChange={handleChange}
                  name="rememberMe"
                />
              }
              label="Remember me"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, py: 1 }}
              disabled={isSubmitting}
            >
              Continue
            </Button>
            <Grid container>
              <Grid item xs>
                <Box
                  component={NavLink}
                  to={PublicURL.RESET_PASSWORD}
                  sx={(theme) => ({
                    color: `${theme.palette.primary.main} !important`,
                  })}
                >
                  Forgot password?
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </AuthPagePaper>
  );
};
