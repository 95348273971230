import { Theme } from '@mui/material';

export const MuiDataGrid = {
  defaultProps: {
    density: 'compact',
    disableColumnMenu: true,
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      border: 'none',
      borderRadius: 0,
      '& .MuiDataGrid-columnHeaders': {
        '--DataGrid-containerBackground': theme.palette.grey[50],
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        color: theme.palette.grey[500],
      },
      ' & .MuiDataGrid-columnHeader--pinnedLeft,  & .MuiDataGrid-columnHeader--pinnedRight':
        {
          '--DataGrid-pinnedBackground': `${theme.palette.grey[50]} !important`,
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
          color: `${theme.palette.grey[500]} !important`,
        },
      '& .MuiDataGrid-cell:hover': {
        color: theme.palette.primary.main,
      },
      '& .MuiDataGrid-rowReorderCell': {
        marginTop: theme.spacing(0.5),
      },
      '& .MuiDataGrid-cell .MuiTypography-root': {
        lineHeight: 'normal',
      },
      '& .MuiDataGrid-cell > .MuiTypography-root, & .MuiDataGrid-cell > .tooltip-content > .MuiTypography-root':
        {
          lineHeight: 'inherit',
        },
    }),
  },
};
