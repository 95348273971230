import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { OmniClassCategory } from 'types/OmniClassCategory';
import { queryKeys } from 'utils/reactQuery';

export const useRecentOmniClassCategories = () => {
  const recentOmniClassCategoriesQuery = useQuery({
    queryKey: queryKeys.recentCategories,
    queryFn: () =>
      ApiService.get(Resources.RECENT_CATEGORIES).then(
        (res) => res.data as OmniClassCategory[],
      ),
    staleTime: Infinity,
  });

  return { recentOmniClassCategoriesQuery };
};
