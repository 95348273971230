import {
  faBallotCheck,
  faBullseye,
  faCalculatorSimple,
  faChartLine,
  faChartScatter,
  faCubes,
  faDiagramSankey,
  faFileChartColumn,
  faFileCheck,
  faFilePen,
  faGaugeMax,
  faLightbulbOn,
  faRulerTriangle,
  faTimelineArrow,
} from '@fortawesome/pro-regular-svg-icons';
import { faDiamond } from '@fortawesome/pro-solid-svg-icons';

export const icons = {
  vision: faChartLine,
  foresite: faDiagramSankey,
  tvd: faBullseye,
  overview: faChartLine,
  procurement: faFileCheck,
  timeline: faTimelineArrow,
  calibrate: faChartScatter,
  risk: faGaugeMax,
  projectDetail: faFilePen,
  components: faCubes,
  estimate: faCalculatorSimple,
  program: faRulerTriangle,
  reports: faFileChartColumn,
  context: faLightbulbOn,
  issues: faBallotCheck,
  milestone: faDiamond,
};
