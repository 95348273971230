import { Box, IconButton, Paper, SxProps, Theme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faXmark } from '@fortawesome/pro-regular-svg-icons';
import Draggable, { ControlPosition, DraggableProps } from 'react-draggable';
import { ReactNode, useRef } from 'react';
import { joinSx } from 'utils/helpers';

const widthSize = {
  sm: '360px',
  md: '500px',
} as const;

type ModalProps = {
  children: ReactNode;
  title?: ReactNode;
  rightActions?: ReactNode;
  onClose: () => void;
  size?: keyof typeof widthSize;
  sx?: SxProps<Theme>;
  onDrag?: (p: ControlPosition) => void;
} & Partial<Pick<DraggableProps, 'defaultPosition'>>;

export function Modal({
  defaultPosition,
  onDrag,
  children,
  rightActions,
  size = 'md',
  onClose,
  sx,
  title,
}: ModalProps) {
  // Draggable component MUST have the nodeRef set, pointing to its child
  const nodeRef = useRef(null);
  return (
    <Draggable
      defaultPosition={defaultPosition}
      bounds="#root"
      onDrag={(_, p) => {
        onDrag?.(p);
      }}
      nodeRef={nodeRef}
    >
      <Paper
        sx={joinSx(
          {
            position: 'fixed',
            mb: 1,
            width: widthSize[size],
            zIndex: 1200,
            boxShadow: '-2px 0px 8px rgba(118, 124, 137, 0.2)',
          },
          sx,
        )}
        ref={nodeRef}
      >
        <Box
          sx={{
            px: 1.5,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            borderBottom: '1px solid',
            borderBottomColor: 'grey.100',
            color: 'grey.500',
            cursor: 'pointer',
            height: 30,
            boxShadow: '0px 1px 4px 0px rgba(106, 117, 155, 0.25)',
          }}
        >
          <Box
            component={FontAwesomeIcon}
            icon={faGripLines}
            sx={{
              color: 'grey.500',
            }}
          />
          {title}
          <Box sx={{ display: 'flex', gap: 1, mr: 1, ml: 'auto' }}>
            {rightActions}
            <IconButton
              edge="end"
              aria-label="close"
              sx={{
                color: 'grey.500',
                ml: '4px',
                py: 0.5,
              }}
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faXmark} size="xs" />
            </IconButton>
          </Box>
        </Box>
        {children}
      </Paper>
    </Draggable>
  );
}
