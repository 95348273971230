import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';
import { Resources } from 'api/Resources';
import { ApiService } from 'api/ApiService';
import { ProjectGroup } from 'types/Project';
import log from 'loglevel';

export function useProjectGroups() {
  const projectGroupsQuery = useQuery({
    queryKey: queryKeys.projectGroups,
    queryFn: ({ signal }) => {
      const endPoint = Resources.PROJECT_GROUPS;
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ProjectGroup[],
      );
    },
    refetchOnWindowFocus: false,
  });
  return { projectGroupsQuery };
}

export function useSaveProjectGroup() {
  const queryKey = queryKeys.projectGroups;

  const queryClient = useQueryClient();
  const saveProjectGroupMutation = useMutation({
    mutationFn: (updatedProjectGroup: Partial<ProjectGroup>) => {
      if (updatedProjectGroup.id) {
        const endPoint = Resources.PROJECT_GROUP_BY_ID.replace(
          '<int:pk>',
          updatedProjectGroup.id.toString(),
        );
        return ApiService.patch(endPoint, updatedProjectGroup).then(
          (res) => res.data as ProjectGroup,
        );
      }
      const endPoint = Resources.PROJECT_GROUPS;
      return ApiService.post(endPoint, updatedProjectGroup).then(
        (res) => res.data as ProjectGroup,
      );
    },
    onMutate: async (newValue) => {
      await queryClient.cancelQueries({ queryKey });
      const previousGroups: ProjectGroup[] | undefined =
        queryClient.getQueryData(queryKey);
      if (previousGroups) {
        queryClient.setQueryData(queryKey, (oldGroups?: ProjectGroup[]) => [
          ...(oldGroups || []),
          { ...newValue, id: 0 },
        ]);
      }

      return previousGroups;
    },
    onError: (error, _newValue, previousGroups) => {
      if (previousGroups) {
        queryClient.setQueryData(queryKey, previousGroups);
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: (_data, _error) => {
      queryClient.invalidateQueries({
        queryKey,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData<ProjectGroup[]>(queryKey, (prev) => {
        if (!prev) return prev;
        const index = prev.findIndex((r) => r.id === data.id);
        let updated: ProjectGroup[];
        if (index >= 0) {
          updated = [...prev];
          updated[index] = data;
        } else {
          updated = [...prev, data];
        }
        return updated;
      });
    },
  });
  return { saveProjectGroupMutation };
}
