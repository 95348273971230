import { Theme } from '@mui/material';

export const MuiListItemText = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '& .MuiListItemText-primary': theme.typography.textDefaultSemiBold,
      '& .MuiListItemText-secondary': {
        ...theme.typography.textSmall,
        marginTop: theme.spacing(0.5),
      },
    }),
  },
};
