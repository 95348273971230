import { ReactNode, useCallback } from 'react';
import { UrlRoute } from 'Urls';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { PermissionType, Permissions } from '../Permissions';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useTenantFeatureFlags } from 'hooks/useTenantFeatureFlags';

/**
 * Custom React Hook that provides the util functions and React Components
 * to enforce app access restrictions for the current
 * logged in user based on her permissions.
 *
 * This hook function returns an object exposing these fields:
 *
 * @see Permissions
 * @see hasPerm
 * @see ifHasPerm
 * @see HasPerm
 */
function usePermissions() {
  // Rely on the current logged in user state.
  const { currentUser } = useCurrentUser();
  const featureFlags = useFeatureFlags();
  const { isLoading: isTenantFeatureFlagsLoading, tenantFeatureFlags } =
    useTenantFeatureFlags();

  /** Main permission checking function.
   *
   * @param permission: the target permission to check.
   * @returns a boolean value. Whether the permission is
   * granted or not for the current logged in user.
   */
  const hasPerm = useCallback(
    (permission: PermissionType) => {
      return !!currentUser && currentUser.permissions.includes(permission);
    },
    [currentUser],
  );

  /** Multiple checking function.
   *
   * @param permissions: the target permissions to check.
   * @returns a boolean value. Whether all of the permissions is
   * granted or not for the current logged in user.
   */
  const hasAllPerms = useCallback(
    (permissions: PermissionType[]) => {
      return permissions.every(hasPerm);
    },
    [hasPerm],
  );

  /** Multiple checking function.
   *
   * @param permissions: the target permissions to check.
   * @returns a boolean value. Whether any of the permissions is
   * granted or not for the current logged in user.
   */
  const hasAnyPerms = useCallback(
    (permissions: PermissionType[]) => {
      return permissions.some(hasPerm);
    },
    [hasPerm],
  );

  /**
   * Conditionally returns `then` or `otherwise`. Both `then` and `otherwise` params
   * can be values or functions. If they are functions they are called
   * and the resulting value is returned.
   *
   * @param permission the target permission to check.
   * @param then the value or function to return or evaluate if the permission is granted.
   * @param otherwise the value or function to return or evaluate if the permission is denied.
   * @returns `then` if permission is granted, `otherwise` if not granted or `false` if has no permission
   * and otherwise is `undefined`.
   * @see hasPerm
   */
  const ifHasPerm = useCallback(
    <T,>(permission: PermissionType, then: (() => T) | T, otherwise?: (() => T) | T) => {
      if (hasPerm(permission)) {
        return typeof then === 'function' ? (then as () => T)() : then;
      } else if (otherwise !== undefined) {
        return typeof otherwise === 'function' ? (otherwise as () => T)() : otherwise;
      }
      return false;
    },
    [hasPerm],
  );

  /**
   * React Component to optionally render its `children` prop.
   * if `permission` is  granted for the current logged in user.
   * Renders an empty block otherwise.
   */
  const HasPerm = useCallback(
    ({
      permission,
      children,
    }: {
      permission: PermissionType;
      children?: ReactNode | undefined;
    }) => {
      return <>{hasPerm(permission) && children}</>;
    },
    [hasPerm],
  );

  /**
   * React Component to optionally render its `children` prop.
   * if `permissions` is  granted for the current logged in user.
   * Renders an empty block otherwise.
   */
  const HasAnyPerms = useCallback(
    ({
      permissions,
      children,
    }: {
      permissions: PermissionType[];
      children?: ReactNode | undefined;
    }) => {
      return <>{hasAnyPerms(permissions) && children}</>;
    },
    [hasAnyPerms],
  );

  /**
   * @param route: the target permission to check.
   * @returns a boolean value. Whether the permission is
   * granted or not for the current logged in user.
   */
  const isRouteEnable = useCallback(
    (route: Partial<UrlRoute>) => {
      const hasUserPerm = !route.requiredPermission || hasPerm(route.requiredPermission);
      const featIsOn =
        !route.requiredFeatureFlags ||
        route.requiredFeatureFlags?.every((flag) => featureFlags[flag]);
      const tenantFlagIsOn =
        !route.requiredTenantFlags ||
        route.requiredTenantFlags?.every(
          (flag) => !isTenantFeatureFlagsLoading && tenantFeatureFlags[flag],
        );
      return {
        enabled: featIsOn && tenantFlagIsOn && hasUserPerm,
        isLoading: isTenantFeatureFlagsLoading,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featureFlags, tenantFeatureFlags, hasPerm],
  );

  return {
    Permissions,
    HasPerm,
    hasPerm,
    HasAnyPerms,
    hasAnyPerms,
    hasAllPerms,
    ifHasPerm,
    isRouteEnable,
  };
}

export default usePermissions;
