import {
  Box,
  CircularProgress,
  FormHelperText,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from '@mui/material';
import { useMilestones } from 'features/Estimate/hooks/useMilestones';
import { DesignMilestone } from 'types/DesignMilestones';
import { ProjectMilestoneSummary } from 'types/Project';
import { OptionLabelWithInfo } from './OptionLabelWithInfo';
import { keyBy } from 'lodash-es';
import { joinSx } from 'utils/helpers';

type DesignMilestoneSelectorProps = {
  milestone?: DesignMilestone | ProjectMilestoneSummary | null;
  setMilestone: (milestone: DesignMilestone) => void;
  allowNoMilestone?: boolean;
  onlyWithEstimates?: boolean;
  sx?: SxProps<Theme>;
};

export const DesignMilestoneSelector = ({
  milestone,
  setMilestone,
  allowNoMilestone = false,
  onlyWithEstimates = false,
  sx,
}: DesignMilestoneSelectorProps) => {
  const {
    milestones,
    milestonesMapById,
    isLoading: isMilestonesLoading,
    isFetching: isMilestonesFetching,
  } = useMilestones();

  // Function called to generate the list of options
  // available in the Design Milestones dropdown.
  const options = [
    ...milestones.map((milestone) => {
      return {
        value: milestone.id,
        label: milestone.name,
        hasEstimate: milestone.has_estimate,
        type: milestone.type,
      };
    }),
    ...(allowNoMilestone
      ? [{ value: -1, label: 'No Milestone', hasEstimate: false, type: undefined }]
      : []),
  ].filter((milestone) => milestone.hasEstimate || !onlyWithEstimates);

  const optionsHash = keyBy(options, 'value');

  const isLoading = isMilestonesFetching || isMilestonesLoading;

  return isLoading ? (
    <Box>
      <CircularProgress size={24} />
    </Box>
  ) : (
    <>
      <Select
        data-testid="item-selector"
        value={milestone?.id ?? (allowNoMilestone ? -1 : undefined)}
        renderValue={(value) =>
          optionsHash[value] ? (
            <OptionLabelWithInfo
              option={optionsHash[value]}
              forceInfo
              sx={{ pl: 1, py: 1 }}
            />
          ) : (
            ''
          )
        }
        onChange={(option) => {
          setMilestone(milestonesMapById[option.target.value] ?? null);
        }}
        disabled={!options.length}
        sx={joinSx(
          {
            alignSelf: 'start',
            minWidth: '26rem',
            maxWidth: '100%',
            '& .MuiSelect-icon': {
              translate: '-8px 0',
            },
            '& .MuiSelect-select': {
              py: 1,
            },
          },
          sx,
        )}
        className="large"
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ px: 0 }}>
            <OptionLabelWithInfo option={option} sx={{ pl: 2.75, pr: 2.75 }} />
          </MenuItem>
        ))}
      </Select>
      {!isLoading && !options.length ? (
        <FormHelperText
          sx={{ color: 'error.main' }}
          data-testid="milestone-required-error"
        >
          There are no milestones created for this project
        </FormHelperText>
      ) : null}
    </>
  );
};
