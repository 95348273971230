import { useCallback } from 'react';
import { useAtom, WritableAtom } from 'jotai';
import { sum } from 'lodash-es';

type TableWidths = {
  columnsWidth: Record<string, number>;
  setup: boolean;
};
type SetStateAction<Value> = Value | ((prev: Value) => Value);
type WidthsAtomWithStorage = WritableAtom<
  TableWidths,
  [SetStateAction<TableWidths>],
  void
>;

export function useTableWidths(widthsAtom: WidthsAtomWithStorage) {
  const [tableWidths, setTableWidths] = useAtom(widthsAtom);

  const updateTableWidths = useCallback(
    (fields: Record<string, number>) => {
      setTableWidths((prev) => ({
        columnsWidth: {
          ...prev.columnsWidth,
          ...fields,
        },
        setup: prev.setup,
      }));
    },
    [setTableWidths],
  );

  return {
    tableWidths,
    setTableWidths,
    updateTableWidths,
  };
}

export function calculateColumnWidth(headerCell: Element | null | undefined) {
  if (!headerCell) return 0;
  const rightPin = headerCell.closest('.MuiDataGrid-columnHeader--pinnedRight');
  let width = headerCell.clientWidth;
  if (rightPin) {
    width =
      headerCell.clientWidth + parseInt(window.getComputedStyle(rightPin).paddingRight);
  }
  return width;
}

export function getColumnsWidth(dataGrid: HTMLElement | null) {
  const headerCells = dataGrid?.querySelectorAll('.MuiDataGrid-columnHeader');
  if (!headerCells || headerCells.length === 0) return null;
  const columnsWidth = Object.fromEntries(
    Array.from(headerCells).map((headerCell) => {
      const field = headerCell.getAttribute('data-field');
      const width = calculateColumnWidth(headerCell);
      return [field, width];
    }),
  );
  if (sum(Object.values(columnsWidth)) === 0) return null;
  return columnsWidth;
}
