import {
  CleanAccordion,
  CleanAccordionDetails,
  CleanAccordionSummary,
} from 'components/CleanAccordion';
import { Box, Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useMemo, useState } from 'react';
import { getUserFullnameOrEmail, joinSx } from 'utils/helpers';
import { useFormikContext } from 'formik';
import { EditingProjectDashboard } from 'features/project-dashboard/helpers';
import { DashboardMembership, UserDashboardMembership } from 'types/Dashboard';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { MemberChip } from 'components/CustomChips/MemberChip';
import { OverlapWithBadgeWrapper } from 'components/OverlapWithBadgeWrapper/OverlapWithBadgeWrapper';
import { ProjectDashboardMembersSectionManage } from 'features/project-dashboard/modal/ProjectDashboardMembersSectionManage';
import { MultiSelect } from 'components/MultiSelect/MultiSelect';

import { useDashboardPermissions } from 'features/project-dashboard/hooks/dashboard-permission';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';

type ProjectDashboardMembersSectionProps = {
  sx?: SxProps<Theme>;
  readOnly?: boolean;
};
export function ProjectDashboardMembersSection({
  sx,
  readOnly,
}: ProjectDashboardMembersSectionProps) {
  const { values, setFieldValue } = useFormikContext<EditingProjectDashboard>();
  const userDashboardMemberships = useMemo(() => {
    const users = values.users || [];
    const memberships = values.memberships || [];
    return users.map((u) => {
      const membership = memberships.find((m) => m && m.user_id === u.id);
      return {
        ...u,
        membership,
      } as UserDashboardMembership;
    });
  }, [values.memberships, values.users]);

  const [isExpanded, setIsExpanded] = useState(true);
  const [showManageMembers, setShowManageMembers] = useState(false);
  const { dashboardPermissionsQuery } = useDashboardPermissions();
  const { currentUser } = useCurrentUser();

  const permissionOptions =
    dashboardPermissionsQuery.data?.map((p) => {
      return {
        label: p.name,
        searchableLabel: p.name,
        value: p.id,
      };
    }) || [];

  return (
    <Box sx={joinSx(sx, { position: 'relative' })}>
      <CleanAccordion expanded={isExpanded}>
        <CleanAccordionSummary
          expanded={isExpanded}
          aria-controls="members-section-panel"
          id="members-section-header"
          onClick={() => setIsExpanded((prev) => !prev)}
          label="Members"
          sx={{ ml: -1 }}
        >
          <Box sx={{ ml: 1.5 }}>
            <OverlapWithBadgeWrapper
              labels={userDashboardMemberships.map(getUserFullnameOrEmail)}
              badgeTop={12}
            >
              {userDashboardMemberships.map((member) => (
                <MemberChip key={member.id} member={member} />
              ))}
            </OverlapWithBadgeWrapper>
          </Box>
        </CleanAccordionSummary>
        <CleanAccordionDetails sx={{ p: 0 }}>
          <Grid container sx={{ alignItems: 'center' }} rowSpacing={1.5}>
            {userDashboardMemberships.map((udm) => {
              return (
                <Fragment key={udm.id}>
                  <Grid item xs={0.75} sx={{ display: 'flex', alignItems: 'center' }}>
                    <MemberChip member={udm} />
                  </Grid>
                  <Grid item xs={7.25}>
                    <Typography>{getUserFullnameOrEmail(udm)}</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: 'end' }}>
                    {currentUser && udm.id !== values.created_by ? (
                      <MultiSelect
                        disabled={readOnly}
                        loading={dashboardPermissionsQuery.isLoading}
                        options={permissionOptions}
                        title="Permissions"
                        value={udm.membership?.permission_ids || []}
                        onChange={(updatePermissions) => {
                          let updatedMemberships = [...values.memberships];
                          const exist = updatedMemberships.find(
                            (m) => m && m.user_id === udm.id,
                          );
                          if (!exist) {
                            updatedMemberships = [
                              ...updatedMemberships,
                              {
                                user_id: udm.id,
                                permission_ids: updatePermissions,
                              } as DashboardMembership,
                            ];
                          } else {
                            updatedMemberships = updatedMemberships.map((m) =>
                              m && m.user_id === udm.id
                                ? { ...m, permission_ids: updatePermissions }
                                : m,
                            );
                          }
                          setFieldValue('memberships', updatedMemberships);
                        }}
                        sx={{ width: '100%' }}
                      />
                    ) : null}
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </CleanAccordionDetails>
      </CleanAccordion>

      {!readOnly ? (
        <Box
          sx={{
            display: 'inline-block',
            position: 'absolute',
            left: 118,
            top: userDashboardMemberships.length ? 14 : 12,
          }}
        >
          <Button
            variant="blueGhost"
            sx={{
              ml: Math.min(5, userDashboardMemberships.length) * 3,
              maxWidth: '24px',
              minWidth: '24px',
              height: '24px',
              borderRadius: '4px',
              p: 0,
            }}
            onClick={() => {
              setIsExpanded(true);
              setShowManageMembers((prev) => !prev);
            }}
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </Button>
          {showManageMembers ? (
            <ProjectDashboardMembersSectionManage
              onClose={() => setShowManageMembers(false)}
            />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}
