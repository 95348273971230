import { ProjectMemberRow } from './ProjectMemberRow';
import { Grid, Typography } from '@mui/material';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { TeamMember } from 'types/User';
import {
  useDeleteProjectMember,
  useProjectMembers,
} from 'features/Projects/hook/members';
import { usePermissionRoles } from 'features/ManageUsers/hooks/permissionRoles';

export const ProjectMembers = () => {
  const {
    permissionRolesQuery: { data: roles },
  } = usePermissionRoles();
  const { projectMembers } = useProjectMembers();
  const { confirmToast } = useToastDialogs();
  const { deleteProjectMemberMutation } = useDeleteProjectMember();
  const { successToast } = useToastDialogs();

  const handleSuccessDeletion = () => {
    successToast({
      title: 'Project Member',
      text: 'Project member successfully deleted',
    });
  };

  // CPE-452 business logic: if the member to be deleted is the only member in one of their roles,
  // then the user should be prompted to confirm the member deletion.
  const onRemoveMember = (member: TeamMember) => {
    // First get the count of members in each role
    const countByRoleId: Record<string, number> = {};

    projectMembers &&
      projectMembers
        .flatMap((member) => member.named_groups)
        .forEach((roleName) => {
          countByRoleId[roleName] = (countByRoleId[roleName] || 0) + 1;
        });
    // Then check if the user is the only member on any of their roles
    const roleAsOnlyMemberId = member.named_groups.find(
      (roleName) => countByRoleId[roleName] === 1,
    );
    const memberRole =
      roleAsOnlyMemberId && roles?.find((role) => roleAsOnlyMemberId === role.name);

    const message = memberRole ? (
      <Typography>
        You are about to remove the only {memberRole.name} in the project:{' '}
        <Typography variant="textDefaultSemiBold">{member.email}</Typography>
      </Typography>
    ) : (
      <Typography>You will delete this project member</Typography>
    );
    // Prompt the user to confirm the deletion
    confirmToast({
      message,
      handleSuccessCallback: () => {
        deleteProjectMemberMutation.mutate(member.id, {
          onSuccess: handleSuccessDeletion,
        });
      },
    });
  };

  return (
    <Grid sx={{ mt: 2 }} rowGap={2} container data-testid="project-members-list">
      {!!projectMembers &&
        projectMembers.map((member) => (
          <ProjectMemberRow
            key={member.id}
            member={member}
            onRemoveMember={onRemoveMember}
          />
        ))}
    </Grid>
  );
};
