import axios, { InternalAxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import { History, Layout, PublicURL } from 'Urls';
import { isArray } from 'lodash-es';
import { getBackedApiUrl } from './url-helper';
import { getSession, hasSession, setSession } from 'features/Auth/hook/sessionHelpers';
import { Resources } from './Resources';

export const ApiService = axios.create({
  baseURL: getBackedApiUrl(),
  timeout: import.meta.env.VITE_BACKEND_TIMEOUT || 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Build the error message if the API returns something like:
// {"quantity":["This field is required."]} on response.data
const buildFieldsErrorMessage = (fieldErrors?: Record<string, string[]>) => {
  if (!fieldErrors || typeof fieldErrors !== 'object') {
    return null;
  }

  return Object.keys(fieldErrors).reduce((acc, field) => {
    const error = fieldErrors[field];
    return `${acc} ${field}: ${isArray(error) ? fieldErrors[field].join('. ') : error}`;
  }, '');
};

const refreshToken = async () => {
  try {
    const session = getSession();
    if (!session) throw Error('There is no session');
    const { access } = await ApiService.post(Resources.REFRESH_AUTH, {
      refresh: session.refresh,
    }).then(({ data }) => data as { access: string }); // eslint-disable-next-line
    setSession({ ...session, access });
  } catch (e) {
    History.push(PublicURL.LOGOUT);
  }
};

const setAuthorizationHeader = (config: InternalAxiosRequestConfig) => {
  const session = getSession();
  if (session) {
    config.headers.Authorization = 'Bearer ' + session.access;
  }
  return config;
};

// Attaching the JWT token to all requests,
// if the user is authenticated.
ApiService.interceptors.request.use(setAuthorizationHeader);

ApiService.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    // If the status code is 401, the user is redirected to the login page.
    if ((error.response ? error.response.status : '') === 401) {
      const path = History.location?.pathname || '';
      const query = queryString.parse(History.location?.search || '');
      if (
        (!path.startsWith(Layout.PUBLIC) && query.redirect === undefined) ||
        !hasSession()
      ) {
        // Ok, we tried to hit a protected API but the user is not authenticated.
        // Try to refresh the token
        const originalRequest = error.config;
        if (
          error.config.url !== Resources.REFRESH_AUTH &&
          hasSession() &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          await refreshToken();
          setAuthorizationHeader(originalRequest);
          return axios(originalRequest);
        } else {
          // Clear the session tokens then...
          History.push(PublicURL.LOGOUT);
        }
      }
    }
    const message =
      error.response?.data?.message ||
      error.response?.data?.detail ||
      buildFieldsErrorMessage(error.response?.data) ||
      error.message;
    return Promise.reject(new Error(message, { cause: error }));
  },
);
