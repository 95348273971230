import { useProject } from 'features/Projects/hook/useProject';
import { useCustomCostGroupDefinitions } from './useCustomCostGroupDefinitions';
import { CustomCostGroupDefinition } from 'types/CostGroup';

type OptionsType = {
  milestoneId?: number | null;
  excludeEstimatesOnly?: boolean;
  includeChildren?: boolean;
};

export const useProjectCustomCostGroupDefinition = (
  {
    milestoneId = null,
    excludeEstimatesOnly = true,
    includeChildren = false,
  }: OptionsType = {} as OptionsType,
) => {
  const { project } = useProject();
  const projectId = project?.id;
  return useCustomCostGroupDefinitions<CustomCostGroupDefinition>({
    milestoneId,
    projectId,
    includeChildren,
    includeDetails: true,
    excludeEstimatesOnly,
  });
};

export const useProjectHasCustomGroupDefinition = () => {
  const { project } = useProject();
  const projectId = project?.id;
  const { customCostGroupDefinitionsQuery } =
    useCustomCostGroupDefinitions<CustomCostGroupDefinition>({
      projectId,
      includeChildren: false,
      includeDetails: true,
      excludeEstimatesOnly: true,
    });
  return {
    hasCustomFormats:
      customCostGroupDefinitionsQuery.customCostGroupDefinitions.length > 0,
  };
};
