import { useMemo } from 'react';
import { useForesiteReport } from './useForesiteReport';
import { BasicDownloadOptions, ReportItem } from 'types/Reports';
import { useEstimateReport } from './useEstimateReport';
import { useItemReport } from './useItemReport';
import { DesignMilestone } from 'types/DesignMilestones';
import { Permissions } from 'features/Auth/Permissions';
import usePermissions from 'features/Auth/hook/usePermissions';

export const useReportsList = () => {
  const { hasPerm } = usePermissions();
  const { download, setIsForesiteReportModalOpen } = useForesiteReport();
  const {
    download: downloadSubtotals,
    setIsFullEstimateReport,
    setIsEstimateReportModalOpen,
  } = useEstimateReport();
  const { download: downloadItem, setIsItemReportModalOpen } = useItemReport();
  const reports: ReportItem[] = useMemo(
    () =>
      [
        {
          id: 'ITEMREPORT',
          name: 'Item Report',
          description: 'Get a summary report of one Item',
          formats: [],
          onDownload: downloadItem,
          openCustomReportModal: setIsItemReportModalOpen,
          permission: Permissions.FORESITE_ACCESS,
        },
        {
          id: 'SUBTOTALSREPORT',
          name: 'Milestone Report',
          description: 'Get a summary report of one milestone',
          formats: [],
          onDownload: downloadSubtotals,
          openCustomReportModal: (open: boolean) => {
            setIsFullEstimateReport(false);
            setIsEstimateReportModalOpen(open);
          },
          permission: Permissions.ESTIMATE_ACCESS,
        },
        {
          id: 'VOREPORT',
          name: 'Value Optioneering',
          description:
            "Get a summary and also a detailed report of your project's Items.",
          formats: ['xlsx', 'pdf'],
          onDownload: download,
          openCustomReportModal: setIsForesiteReportModalOpen,
          permission: Permissions.FORESITE_ACCESS,
        },
        {
          id: 'EST_REPORT',
          name: 'Estimate Report',
          description: 'Get a summary your current Estimate',
          formats: [],
          openCustomReportModal: (open: boolean) => {
            setIsFullEstimateReport(true);
            setIsEstimateReportModalOpen(open);
          },
          onDownload: (
            milestone: DesignMilestone,
            options: BasicDownloadOptions & { per_component: boolean },
          ) => downloadSubtotals(milestone, { ...options, per_component: true }),
          permission: Permissions.ESTIMATE_ACCESS,
        },
      ].filter((report) => hasPerm(report.permission)) as ReportItem[],
    [
      download,
      downloadItem,
      downloadSubtotals,
      setIsForesiteReportModalOpen,
      setIsItemReportModalOpen,
      setIsEstimateReportModalOpen,
      setIsFullEstimateReport,
      hasPerm,
    ],
  );
  return {
    reports,
  };
};
