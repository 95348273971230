import { faCog, faFileExcel, faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@mui/material';
import { useProject } from 'features/Projects/hook/useProject';
import log from 'loglevel';
import { useState } from 'react';
import { ReportFileType, ReportItem } from 'types/Reports';

type ReportDownloadButtonsProps = {
  report: ReportItem;
};

export const ReportDownloadButtons = ({ report }: ReportDownloadButtonsProps) => {
  const { project } = useProject();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadReport = (format: ReportFileType) => {
    setIsDownloading(true);
    report
      .onDownload(project!, { type: format })
      .then(() => setIsDownloading(false))
      .catch((error) => log.error(error instanceof Error ? error.message : error));
  };

  return (
    <Box
      data-testid="export_button"
      id="export-actions-menu"
      sx={{
        display: 'flex',
        gap: 1,
        justifyContent: 'flex-end',
        width: '100%',
        my: 0.25,
      }}
    >
      {report.formats.length === 0 && report.openCustomReportModal ? (
        <CustomReportButton report={report} isOnlyOption disabled={isDownloading} />
      ) : null}

      {report.formats.map((format) => {
        if (format === 'pdf') {
          return (
            <Button
              key="pdf"
              onClick={() => handleDownloadReport(format)}
              startIcon={
                <Box
                  sx={{ minWidth: 14, pb: '2px' }}
                  component={FontAwesomeIcon}
                  icon={faFilePdf}
                  data-testid="custom-report-option"
                />
              }
              disabled={isDownloading}
              variant="blueGhost"
            >
              PDF
            </Button>
          );
        }

        if (format === 'xlsx') {
          return (
            <Button
              key="xlsx"
              onClick={() => handleDownloadReport(format)}
              startIcon={
                <Box
                  sx={{ minWidth: 14, pb: '2px' }}
                  component={FontAwesomeIcon}
                  icon={faFileExcel}
                  data-testid="custom-report-option"
                />
              }
              disabled={isDownloading}
              variant="blueGhost"
            >
              Excel
            </Button>
          );
        }

        return null;
      })}

      {report.formats.length && report.openCustomReportModal ? (
        <CustomReportButton
          report={report}
          isOnlyOption={false}
          disabled={isDownloading}
        />
      ) : null}
    </Box>
  );
};

const CustomReportButton = ({
  report,
  disabled,
  isOnlyOption,
}: {
  report: ReportItem;
  disabled: boolean;
  isOnlyOption: boolean;
}) => (
  <Button
    onClick={() => {
      report.openCustomReportModal?.(true);
    }}
    startIcon={
      <Box
        sx={{ minWidth: 14, pb: '2px' }}
        component={FontAwesomeIcon}
        icon={faCog}
        data-testid="custom-report-option"
      />
    }
    disabled={disabled}
    variant="blueGhost"
    sx={{ minWidth: '110px' }}
  >
    {isOnlyOption ? 'Download' : 'Custom'}
  </Button>
);
