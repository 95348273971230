import { IconDefinition, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { IconMenuItem } from './IconMenuItem';
import { IconMenuItemProps } from './types';

export const DeleteMenuItem = ({
  children,
  icon,
  ...props
}: Omit<IconMenuItemProps, 'icon'> & { icon?: IconDefinition }) => (
  <IconMenuItem sx={{ color: 'error.main' }} icon={icon ?? faTrash} {...props}>
    {children}
  </IconMenuItem>
);
