// Stolen from https://github.com/streamich/react-use/blob/325f5bd69904346788ea981ec18bfc7397c611df/src/useTitle.ts#L13

import { useEffect, useRef } from 'react';

export type UseTitleOptions = {
  restoreOnUnmount: boolean;
  suffix: string;
  default: string;
};

const DEFAULT_USE_TITLE_OPTIONS: UseTitleOptions = {
  restoreOnUnmount: true,
  suffix: ' - ConCntric',
  default: 'ConCntric',
};

export function useTitle(title: string, opt?: Partial<UseTitleOptions>) {
  const options = { ...opt, ...DEFAULT_USE_TITLE_OPTIONS };
  const prevTitleRef = useRef(document.title);

  if (document.title !== title) document.title = title + options.suffix;

  useEffect(() => {
    if (options && options.restoreOnUnmount) {
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        document.title = prevTitleRef.current || options.default;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
