import React from 'react';
import { isEmpty, isEqual } from 'lodash-es';
import {
  Box,
  CircularProgress,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import {
  faArrowTurnDownLeft,
  faCirclePlus,
  faDiagramProject,
  faPencil,
  faTurnDownRight,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategorySelectModal } from './CategorySelectModal';
import { joinSx, truncateString } from 'utils/helpers';
import { useMappedOmniClassCategories } from 'hooks/omni-class-category/useOmniClassCategory';
import { OmniClassCategory } from 'types/OmniClassCategory';
import { findCategoryBranch } from 'utils/categories';
import { Kbd } from 'components/Kbd/Kbd';
import { useMixpanel } from 'hooks/useMixpanel';

const CATEGORY_IDS_TO_FILTER = [1659]; // Uncategorized

const filterCategoriesByFixedValues = (
  normalizedCategories: Record<number, OmniClassCategory> | undefined,
  fixed: OmniClassCategory[],
): Record<number, OmniClassCategory> | undefined => {
  if (fixed.length === 0 || !normalizedCategories) {
    return normalizedCategories;
  } else {
    const id = fixed[0].id;
    const category = normalizedCategories[id];
    const subCategories = filterCategoriesByFixedValues(
      category.subCategories,
      fixed.slice(1),
    );
    return {
      [id]: { ...category, subCategories },
    };
  }
};

type CategorySelectProps = {
  label?: string;
  readOnly?: boolean;
  onChange: (category: OmniClassCategory | undefined) => void;
  value?: number;
  fixedValues?: number;
  placeholder?: string;
  TextFieldProps?: TextFieldProps;
  addCommonCategory?: boolean;
  addTable13Categories?: boolean;
  allowSelectAny?: boolean;
  dialogTitle?: string;
  clearable?: boolean;
  sx?: SxProps<Theme>;
};

export const CategorySelect = (props: CategorySelectProps) => {
  // Array of categories, where index 0 is a Level 1 category selected,
  // index 1, Level 2 category selected, and so on.
  const [selectedCategories, setSelectedCategories] = React.useState<OmniClassCategory[]>(
    [],
  );
  const { mixpanel } = useMixpanel();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [categories, setCategories] = React.useState<Record<number, OmniClassCategory>>(
    {},
  );

  const { mappedOmniClassCategories, isError, isLoading } = useMappedOmniClassCategories(
    CATEGORY_IDS_TO_FILTER,
    !!props.addCommonCategory,
    Boolean(props.addTable13Categories),
  );

  React.useEffect(() => {
    // If there a `value` is passed, find it in the categories object and
    // set it in selectedCategories
    const selectedArray = findCategoryBranch(mappedOmniClassCategories, props.value);

    if (!isEmpty(mappedOmniClassCategories)) {
      // The categories that should not changed
      if (props.fixedValues !== undefined) {
        const fixedCategories = selectedArray.slice(
          0,
          Math.max(Math.min(props.fixedValues, 3), 0),
        );
        const filteredCategories = filterCategoriesByFixedValues(
          mappedOmniClassCategories,
          fixedCategories,
        );
        if (!isEqual(categories, filteredCategories)) {
          setCategories(filteredCategories ?? {});
        }
      } else {
        if (!isEqual(categories, mappedOmniClassCategories)) {
          setCategories(mappedOmniClassCategories);
        }
      }
    }
  }, [categories, mappedOmniClassCategories, props.fixedValues, props.value]);

  const onModalClose = () => {
    if (
      selectedCategories.length &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedCategories.at(-1)!.id !== props.value &&
      categories
    ) {
      const originalSelected = findCategoryBranch(categories, props.value);

      setSelectedCategories(originalSelected);
    }
    setIsModalOpen(false);
  };

  const onModalDone = () => {
    props.onChange(selectedCategories[selectedCategories.length - 1]);
    setIsModalOpen(false);
  };

  const onButtonClick = () => {
    mixpanel.click('open category modal');
    setIsModalOpen(true);
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      onButtonClick();
    }
  };

  // Check when a value is changed externally
  React.useEffect(() => {
    if (!props.value && selectedCategories.length) {
      setSelectedCategories([]);
    } else if (
      props.value &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (!selectedCategories.length || selectedCategories.at(-1)!.id !== props.value) &&
      categories
    ) {
      const foundSelectedCategories = findCategoryBranch(categories, props.value);

      setSelectedCategories(foundSelectedCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, categories]);

  const handleCategorySelection = (category: OmniClassCategory) => {
    // TODO: this can be improved as we could know the selectedCategory tree
    if (categories) {
      const newSelected = findCategoryBranch(categories, category.id);

      setSelectedCategories(newSelected);
    }
  };

  const maxCategoryLength = 120 / selectedCategories.length;
  const inputValue = selectedCategories.length
    ? selectedCategories
        .map((category) => truncateString(category.name, maxCategoryLength))
        .join(' / ')
    : '';

  const hasSelectedCategories = Boolean(selectedCategories.length);
  const hasHelperText = Boolean(props.TextFieldProps?.helperText);

  const tooltipTitle = hasSelectedCategories ? (
    selectedCategories.map((category, i) => (
      <Box sx={{ ml: (theme) => theme.spacing(category.level - 1) }} key={category.id}>
        {i > 0 && <FontAwesomeIcon icon={faTurnDownRight} />} {category.name}
      </Box>
    ))
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <Kbd icon={faArrowTurnDownLeft} sx={{ fontSize: '12px', mr: 0.5 }} />
      {'to select'}
    </Box>
  );
  const tooltipProps: Partial<TooltipProps> = hasSelectedCategories
    ? {}
    : {
        arrow: true,
        disableHoverListener: true,
        slotProps: {
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, hasHelperText ? -35 : -10],
                },
              },
            ],
          },
        },
      };

  let endAdornment: JSX.Element | null = null;
  if (isLoading) {
    endAdornment = (
      <InputAdornment position="end">
        <CircularProgress color="inherit" size={20} />
      </InputAdornment>
    );
  } else if (!props.readOnly) {
    if (props.clearable && hasSelectedCategories) {
      endAdornment = (
        <InputAdornment
          position="end"
          sx={{ display: 'none' }}
          onClick={(event) => {
            event.stopPropagation();
            props.onChange(undefined);
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </InputAdornment>
      );
    } else {
      endAdornment = (
        <InputAdornment position="end" sx={{ color: 'primary.main', display: 'none' }}>
          <FontAwesomeIcon icon={faPencil} />
        </InputAdornment>
      );
    }
  }

  return isError ? (
    <Box>There was an error fetching the categories</Box>
  ) : (
    <>
      <CategorySelectModal
        isOpen={!props.readOnly && isModalOpen}
        onClose={onModalClose}
        onDone={onModalDone}
        dialogTitle={props.dialogTitle}
        categories={categories}
        selectedCategories={selectedCategories}
        onCategorySelection={handleCategorySelection}
        allowSelectAny={props.allowSelectAny}
      />
      <Tooltip title={tooltipTitle} {...tooltipProps}>
        <TextField
          label={props.label}
          onClick={onButtonClick}
          onKeyUp={handleKeyUp}
          fullWidth
          value={inputValue}
          placeholder={props.placeholder ?? 'Select category'}
          autoComplete="off"
          sx={joinSx(
            {
              '& .MuiOutlinedInput-input , & .MuiTextField-root': {
                cursor: 'pointer',
              },
              '&:hover': {
                '& .MuiInputAdornment-root': {
                  display: 'flex',
                  cursor: 'pointer',
                },
              },
            },
            props.sx,
          )}
          InputProps={{
            sx: {
              cursor: 'pointer',
            },
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={hasSelectedCategories ? faDiagramProject : faCirclePlus}
                />
              </InputAdornment>
            ),
            endAdornment,
            readOnly: true,
          }}
          {...(props.TextFieldProps ?? {})}
        />
      </Tooltip>
    </>
  );
};
