import { PropsWithChildren } from 'react';
import { matchPath } from 'react-router-dom';
import { History } from 'Urls';
import { PrivateRoutes } from 'Routes';
import usePermissions from '../hook/usePermissions';
/**
 * React Component to optionally render its `children` prop
 * if the current user is authorized to access the current navigation route.
 * Renders an empty block otherwise.
 */
export const HasRoutePerm = ({ children }: PropsWithChildren) => {
  const { hasPerm } = usePermissions();

  const route = PrivateRoutes.find((route) =>
    matchPath(History.location.pathname, route.path),
  );
  if (!route) {
    // The route is not private, no restriction.
    return <>{children}</>;
  }

  // Only return the restricted content if there are no restrictions or
  // the current user has the proper permission.
  return (route.requiredPermission ? hasPerm(route.requiredPermission) : true) ? (
    <>{children}</>
  ) : null;
};
