import { faChartSimple } from '@fortawesome/pro-regular-svg-icons';
import { PageContainer } from 'components/PageContainer/PageContainer';
import { PageHeader } from 'components/PageContainer/PageHeader';
import { Stack } from '@mui/material';
import { ProjectDashboardsFilters } from 'features/project-dashboard/table/ProjectDashboardsFilters';
import { CreateProjectDashboardButton } from 'features/project-dashboard/table/CreateProjectDashboardButton';
import { ProjectDashboardTable } from 'features/project-dashboard/table/ProjectDashboardTable';
import { useProjectDashboardTableFilters } from 'features/project-dashboard/hooks/useProjectDashboardTableFilters';
import { useProjectDashboards } from 'features/project-dashboard/hooks/project-dashboard';
import { ProjectDashboardDetailsModal } from 'features/project-dashboard/modal/ProjectDashboardDetailsModal';
import { useForesiteFlyout } from 'features/Foresite/hooks/ui';
import { DEFAULT_PROJECT_DASHBOARD_PORTFOLIO } from 'types/Dashboard';

export function ProjectDashboardsPage() {
  const { combinedFilters } = useProjectDashboardTableFilters();
  const { projectDashboardsQuery } = useProjectDashboards();
  const { projectDashboardDetailsId, setProjectDashboardDetailsId } = useForesiteFlyout();
  let rows = projectDashboardsQuery.data ?? [];
  rows = rows.filter(
    (r) => r.id === DEFAULT_PROJECT_DASHBOARD_PORTFOLIO || combinedFilters(r),
  );

  return (
    <PageContainer fullWidth>
      <PageHeader icon={faChartSimple}>Dashboards</PageHeader>

      <Stack sx={{ flexDirection: 'row', mb: 2 }}>
        <ProjectDashboardsFilters />
        <CreateProjectDashboardButton
          sx={{ ml: 'auto' }}
          onClick={() => {
            setProjectDashboardDetailsId('new');
          }}
        />
      </Stack>
      <ProjectDashboardTable rows={rows} loading={projectDashboardsQuery.isLoading} />
      <ProjectDashboardDetailsModal key={projectDashboardDetailsId} />
    </PageContainer>
  );
}
