import { ProjectComparison } from 'types/Calibrate';
import { AnalysisVariant, ConceptAnalysis, CostGroupComparison } from './type';
import { round, sum } from 'lodash-es';

export const ANALYSIS_TYPE_OPTIONS = [
  { value: 'OVERALL_PROJECT', label: 'By Project' },
  { value: 'BY_COMPONENTS', label: 'By Component' },
] as const;

export const getVariantStatusColor = (variant: AnalysisVariant | null) => {
  if (variant?.status === 'CONCEPT') {
    return { backgroundColor: 'purple.100', color: 'purple.800' };
  } else {
    return { backgroundColor: 'blue.200', color: 'blue.700' };
  }
};

export const transformConceptToProjectJson = (
  analysis: ConceptAnalysis | undefined,
  concepts: Pick<
    ProjectComparison,
    'id' | 'base_unit' | 'construction_category' | 'cost' | 'name' | 'gsf'
  >[],
  costGroups: CostGroupComparison[],
) => {
  if (!analysis) return null;

  const project = {
    name: analysis.name,
    construction_category: analysis.construction_category,
    target_square_footage: analysis.area ?? concepts.find((c) => !!c.gsf)?.gsf ?? 0,
    target_budget: round(sum(concepts.map((c) => c.cost))),
    components: concepts.map((concept, index) => ({
      name: concept.name,
      construction_sub_category: concept.construction_category,
      target_square_footage: concept.gsf,
      base_unit_count: concept.base_unit?.count,
      component_order: index + 1,
      costs: costGroups.map((cg) => ({
        cost_group: cg.id,
        total_cost: cg.projects.find((p) => p.id === concept.id)?.cost ?? 0,
      })),
    })),
  };
  return project;
};
