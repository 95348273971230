import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { keyBy, omit } from 'lodash-es';
import { AccessScope, AccessScopeTree } from 'types/User';
import { queryKeys } from 'utils/reactQuery';
import {
  getAccessScopeFullPath,
  getPlainAccessScopes,
  makeAccessScopeTree,
  mapAccessScopesByParent,
} from '../utils/accessScopesTransforms';

export const useAccessScopes = () => {
  const accessScopesQuery = useQuery({
    queryKey: queryKeys.accessScopes,
    queryFn: ({ signal }) => {
      return ApiService.get(Resources.ACCESS_SCOPES, { signal }).then(
        (res) => res.data as AccessScope[],
      );
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  return { accessScopesQuery };
};

export const useGetAccessScope = () => {
  const {
    accessScopesQuery: { data },
  } = useAccessScopes();

  const getAccessScope = (accessScopeId: number | undefined) => {
    if (!accessScopeId || !data) return undefined;

    return data.find((accessScope) => accessScope.id === accessScopeId);
  };

  return { getAccessScope };
};

export const useAccessScopesTree = () => {
  const { accessScopesQuery } = useAccessScopes();

  let data: AccessScopeTree | undefined;
  if (accessScopesQuery.data) {
    const rootScope = accessScopesQuery.data.find((scope) => scope.parent === null);
    if (rootScope) {
      const mappedAccessScopeByParent = mapAccessScopesByParent(accessScopesQuery.data);
      data = makeAccessScopeTree(rootScope, mappedAccessScopeByParent);
    }
  }
  return { accessScopeTreeQuery: { ...accessScopesQuery, data } };
};

export const useCreateAccessScope = () => {
  const queryClient = useQueryClient();

  const createAccessScopeMutation = useMutation({
    mutationFn: (accessScope: Omit<AccessScope, 'id'>) => {
      return ApiService.post(Resources.ACCESS_SCOPES, {
        ...accessScope,
        members: accessScope.members.map(({ id }) => id),
      }).then((res) => res.data as AccessScope[]);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.accessScopes });
      queryClient.invalidateQueries({
        queryKey: queryKeys.apiKeys.permissionsAndAccessScopes,
      });
    },
  });
  return { createAccessScopeMutation };
};

export const useUpdateAccessScope = () => {
  const queryClient = useQueryClient();

  const updateAccessScopeMutation = useMutation({
    mutationFn: (accessScope: AccessScope) => {
      const endPoint = Resources.ACCESS_SCOPE.replace('<int:pk>', String(accessScope.id));

      const payload = {
        ...omit(accessScope, 'users'),
        members: accessScope.members.map(({ id }) => id),
      };

      return ApiService.patch(endPoint, payload).then(
        (res) => res.data as { id: number },
      );
    },
    onMutate: async (accessScope) => {
      await queryClient.cancelQueries({ queryKey: queryKeys.accessScopes });
      const previousAccessScopes = queryClient.getQueryData(queryKeys.accessScopes);
      if (previousAccessScopes) {
        queryClient.setQueryData(
          queryKeys.accessScopes,
          (oldAccessScopes?: AccessScope[]) =>
            oldAccessScopes?.map((oldAccessScope) =>
              oldAccessScope.id === accessScope.id
                ? { ...oldAccessScope, ...accessScope }
                : oldAccessScope,
            ),
        );
      }
      return previousAccessScopes;
    },
    onError: (error, _accessScope, previousPermissions) => {
      if (previousPermissions) {
        queryClient.setQueryData(queryKeys.accessScopes, previousPermissions);
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.accessScopes });
      queryClient.invalidateQueries({
        queryKey: queryKeys.apiKeys.permissionsAndAccessScopes,
      });
    },
  });
  return { updateAccessScopeMutation };
};

export const useDeleteAccessScope = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const queryClient = useQueryClient();

  const deleteAccessScopeMutation = useMutation({
    mutationFn: (accessScopeId: number) => {
      const endPoint = Resources.ACCESS_SCOPE.replace('<int:pk>', String(accessScopeId));

      return ApiService.delete(endPoint).then((res) => res.data);
    },
    onMutate: async (accessScopeId) => {
      await queryClient.cancelQueries({ queryKey: queryKeys.accessScopes });
      const previousAccessScopes = queryClient.getQueryData(queryKeys.accessScopes);
      if (previousAccessScopes) {
        queryClient.setQueryData(
          queryKeys.accessScopes,
          (oldAccessScopes?: AccessScope[]) =>
            oldAccessScopes?.filter(
              (oldAccessScope) => oldAccessScope.id !== accessScopeId,
            ),
        );
      }
      return previousAccessScopes;
    },
    onError: (error, _accessScope, previousPermissions) => {
      if (previousPermissions) {
        queryClient.setQueryData(queryKeys.accessScopes, previousPermissions);
      }
      log.error(error instanceof Error ? error.message : error);
      onError?.(error);
    },
    onSuccess,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.accessScopes });
      queryClient.invalidateQueries({
        queryKey: queryKeys.apiKeys.permissionsAndAccessScopes,
      });
    },
  });
  return { deleteAccessScopeMutation };
};

export const useAccessScopeFullPath = (accessScopeId: number) => {
  const { accessScopeTreeQuery } = useAccessScopesTree();
  if (!accessScopeTreeQuery.data) return '';
  const accessScopesArray = getPlainAccessScopes(accessScopeTreeQuery.data);
  const accessScopesHash: Record<number, AccessScopeTree> = keyBy(
    accessScopesArray,
    'id',
  );
  return getAccessScopeFullPath(accessScopeId, accessScopesHash);
};
