import { useEffect, useRef } from 'react';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { useMixpanel } from 'hooks/useMixpanel';
import { getUserFullName } from 'utils/helpers';
import { useIsUserGuidingBlocked } from 'hooks/useIsUserGuidingBlocked';

export function UserGuiding() {
  const { currentUser } = useCurrentUser();
  const { mixpanel } = useMixpanel();
  const { setIsUserGuidingBlocked } = useIsUserGuidingBlocked();

  const hasBeenLoaded = useRef(false);
  useEffect(() => {
    if (hasBeenLoaded.current) return;
    const script = document.createElement('script');
    script.nonce = 'concntric';
    script.innerHTML = `
      (function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','496607103ID');
    `;
    document.body.appendChild(script);
    hasBeenLoaded.current = true;
    return () => {
      // Clean up the injected script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      mixpanel.identify(String(currentUser.id));
      window.userGuiding?.identify(String(currentUser.id), {
        email: currentUser.email,
        name: getUserFullName(currentUser),
        env: import.meta.env.VITE_ENV,
      });
    }
  }, [mixpanel, currentUser]);

  useEffect(() => {
    if (currentUser) {
      const url = 'https://user.userguiding.com/sdk/identify';
      fetch(url, {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store',
      })
        .then(({ redirected }) => {
          if (redirected) setIsUserGuidingBlocked(true);
        })
        .catch(() => {
          setIsUserGuidingBlocked(true);
        });
    }
  }, [currentUser, setIsUserGuidingBlocked]);

  return <></>;
}
