import { Box, LinearProgress, SxProps, Theme } from '@mui/material';

import { NotificationItem } from './NotificationItem';
import { useNotifications } from 'features/Notification/hooks/notifications';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
} from '@mui/x-data-grid-premium';
import { Notification } from 'types/Notification';
import { useMemo } from 'react';
import { NotificationLastItemMessage } from 'features/Notification/components/NotificationLastItemMessage';
import { joinSx } from 'utils/helpers';

type NotificationListProps = {
  sx: SxProps<Theme>;
  onClickItem: () => void;
};

type Column = GridColDef<Notification> & {
  field: keyof Notification;
};

const LAST_ITEM_ID = -1;

export function NotificationList({ onClickItem, sx }: NotificationListProps) {
  const {
    isPending,
    isLoading,
    isFetchingNextPage,
    flatData,
    fetchNextPage,
    hasNextPage,
  } = useNotifications();
  const loading = isPending || isLoading || isFetchingNextPage;

  const rows = useMemo(() => {
    if (flatData) {
      if (!hasNextPage) {
        return [...flatData, { id: LAST_ITEM_ID } as Notification];
      }
      return flatData;
    }
    return [];
  }, [flatData, hasNextPage]);

  const handleOnRowsScrollEnd: DataGridPremiumProps['onRowsScrollEnd'] = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const columns: Column[] = [
    {
      field: 'id',
      headerName: '',
      flex: 1,
      renderCell: (params) => {
        const notification = params.row;
        if (notification.id === LAST_ITEM_ID) {
          return (
            <NotificationLastItemMessage
              key="last-item"
              sx={{
                borderTop: '1px solid',
                borderTopColor: 'grey.200',
                pt: 4,
                pb: 4,
                width: '100%',
                color: 'grey.700',
              }}
              isEmpty={rows.length <= 1}
            />
          );
        }

        return (
          <NotificationItem
            key={notification.id}
            notification={notification}
            sx={{ width: '100%' }}
            onClick={onClickItem}
          />
        );
      },
      sortable: false,
      editable: false,
    },
  ];
  const autoHeight = rows.length <= 4;
  return (
    <Box sx={joinSx(sx, !autoHeight ? { height: 500 } : null, { width: '100%' })}>
      <DataGridPremium
        columns={columns}
        rows={rows}
        loading={loading}
        hideFooter
        autoHeight={autoHeight}
        columnHeaderHeight={0}
        disableColumnMenu
        disableColumnReorder
        onRowsScrollEnd={handleOnRowsScrollEnd}
        getRowHeight={() => 'auto'}
        slots={{
          loadingOverlay: () => <LinearProgress />,
        }}
        sx={{
          '& .MuiDataGrid-row': {
            width: '100%',
          },
          '& .MuiDataGrid-cell': {
            padding: 0,
            border: 0,
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 0,
          },
        }}
      />
    </Box>
  );
}
