import { Theme } from '@mui/material';

export const MuiTabs = {
  styleOverrides: {
    root: () => ({
      minHeight: '1.5rem',
      color: ({ palette }: Theme) => palette.grey[500],
      fontWeight: 700,
      '& .MuiButtonBase-root': {
        minHeight: '1.5rem',
        textTransform: 'none',
        paddingBottom: '0.375rem',
        '&.Mui-selected': {
          color: ({ palette }: Theme) => palette.grey[800],
        },
      },
      '& .MuiTabs-indicator': {
        height: '4px',
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
      },
    }),
  },
};
