import mixpanel, {
  Callback,
  Dict,
  Mixpanel as TMixpanel,
  RequestOptions,
} from 'mixpanel-browser';
import { APP_VERSION, GIT_HASH, isProd } from 'utils/environment';
import { UserCompany } from 'types/Company';

const shouldLogMixpanelEvents = Boolean(
  import.meta.env.VITE_FEATUREFLAG_MIXPANEL === 'true' || isProd(),
);

if (shouldLogMixpanelEvents) mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN);

export type MixpanelActions = Pick<TMixpanel, 'identify'> & {
  track: (
    company: UserCompany | undefined,
    event_name: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback,
  ) => void;
  click: (
    company: UserCompany | undefined,
    target: string,
    to?: string,
    properties?: Dict,
  ) => void;
  pageLoaded: (company: UserCompany | undefined, target: string, to?: string) => void;
};

export function getCompanyMixpanelProps(company: UserCompany | undefined) {
  return {
    tenant: company?.slug,
    company: company?.name,
  };
}

export const mixpanelActions = {
  identify: function (id) {
    if (!shouldLogMixpanelEvents) return;
    mixpanel.identify(id);
  },
  track: function (company, name, props?) {
    if (!shouldLogMixpanelEvents) return;
    mixpanel.track(name, {
      ...props,
      ...getCompanyMixpanelProps(company),
      app_version: `v${APP_VERSION}(${GIT_HASH})`,
    });
  },
  click: function (company, target, to, props?) {
    if (!shouldLogMixpanelEvents) return;
    if (to)
      this.track(
        company,
        `Click on ${target.toLowerCase()} from ${to.toLowerCase()}`,
        props,
      );
    else this.track(company, `Click on ${target.toLowerCase()}`, props);
  },
  pageLoaded: function (company, label) {
    if (!shouldLogMixpanelEvents) return;
    this.track(company, `Page ${label.toLowerCase()} loaded`);
  },
} as MixpanelActions;
