import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { TeamMember } from 'types/User';
import { useSelectedProjectId } from './useSelectedProjectId';
import { usePermissionRoles } from 'features/ManageUsers/hooks/permissionRoles';
import { queryKeys } from 'utils/reactQuery';

export const useProjectMembers = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const { permissionRolesQuery } = usePermissionRoles();

  const projectMembersQuery = useQuery({
    queryKey: queryKeys.project(selectedProjectId).members,
    queryFn: ({ signal }) => {
      const endPoint = Resources.PROJECT_TEAM_CONTACT.replace(
        '<int:pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      );
      return ApiService.get(endPoint, { signal }).then((res) => res.data as TeamMember[]);
    },
    staleTime: Infinity,
    enabled: !!selectedProjectId && !permissionRolesQuery.isLoading,
  });
  const { data, ...rest } = projectMembersQuery;
  const signedUpMembers = data
    ? projectMembersQuery.data.filter((member) => !member.is_invite_pending)
    : [];
  return { signedUpMembers, projectMembers: data, ...rest };
};

export const useDeleteProjectMember = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const queryClient = useQueryClient();

  const deleteProjectMemberMutation = useMutation({
    mutationFn: (userId: number) => {
      if (!selectedProjectId) {
        throw Error('There is no selected project id');
      }

      const endPoint = Resources.PROJECT_MEMBER_BY_ID.replace(
        '<int:project_pk>',
        selectedProjectId.toString(),
      ).replace('<int:user_pk>', userId.toString());

      return ApiService.delete(endPoint).then((res) => res.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.project(selectedProjectId).members,
      });
      queryClient.invalidateQueries({ queryKey: queryKeys.companyUsers });
    },
  });
  return { deleteProjectMemberMutation };
};
