import {
  Box,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faGrid2 } from '@fortawesome/pro-solid-svg-icons';
import { SetStateAction } from 'react';
import { ReportViewType } from 'types/Reports';

export function ViewTypeSelector({
  sx,
  viewType,
  onChange,
}: {
  viewType: ReportViewType;
  onChange: (value: SetStateAction<ReportViewType>) => void;
  sx: SxProps<Theme>;
}) {
  return (
    <ToggleButtonGroup
      color="primary"
      value={viewType}
      exclusive
      unselectable="on"
      onChange={(_, nextView) => {
        if (nextView) onChange(nextView);
      }}
      sx={sx}
    >
      <ToggleButton value="list" aria-label="List View">
        <Tooltip title="List View" placement="top" arrow>
          <Box component={FontAwesomeIcon} icon={faBars} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="grid" aria-label="Grid View">
        <Tooltip title="Grid View" placement="top" arrow>
          <Box component={FontAwesomeIcon} icon={faGrid2} />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
