import { atom, useAtom } from 'jotai';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { useState } from 'react';
import { BasicDownloadOptions } from 'types/Reports';
import { addSearchParamsToUrl } from 'utils/helpers';
import { Item } from 'types/Item';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { config } from '../helpers';

const itemReportyoutsAtom = atom(false);
itemReportyoutsAtom.debugLabel = 'itemReportFlyoutsAtom';

export const useItemReport = () => {
  const { infoToast, errorToast, successToast } = useToastDialogs();
  const { selectedProjectId } = useSelectedProjectId();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isItemReportModalOpen, setIsItemReportModalOpen] = useAtom(itemReportyoutsAtom);

  const download = async (item: Item, options: BasicDownloadOptions) => {
    if (typeof item.id !== 'number' || item.id < 0) {
      throw Error();
    }
    let url = Resources.ITEM_REPORT_DOWNLOAD.replace(
      '<int:project_pk>',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedProjectId!.toString(),
    ).replace('<int:item_pk>', item.id.toString());

    url = addSearchParamsToUrl({
      url,
      searchParams: {
        type: options.type ?? 'pdf',
      },
    });

    setIsDownloading(true);

    infoToast({ title: 'Downloading..', text: 'We are preparing your report.' });

    try {
      const response = await ApiService.get(url, config);
      if (!response) {
        return;
      }
      const file_url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = file_url;
      link.setAttribute('download', `${item.name}.${options.type ?? 'pdf'}`);
      document.body.appendChild(link);
      link.click();
      successToast({
        title: 'Report Downloaded',
        text: 'Report downloaded successfully',
      });
      setIsDownloading(false);
      return response;
    } catch (error) {
      setIsDownloading(false);
      const message =
        error instanceof Error
          ? error.message
          : 'The service is temporarily unavailable. Try again later.';

      errorToast({
        text: message,
      });
    }
  };

  return {
    download,
    isDownloading,
    isItemReportModalOpen,
    setIsItemReportModalOpen,
  };
};
