import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

type Status =
  | 'idle'
  | 'authorizing'
  | 'waiting_for_login'
  | 'authorized'
  | 'unauthorized'
  | 'error';
const BUILDING_CONNECTED_AUTH_STATUS = 'building-connected-auth-status';

const statusAtom = atomWithStorage<{ status: Status; redirect?: string }>(
  BUILDING_CONNECTED_AUTH_STATUS,
  {
    status: 'idle',
  },
  undefined,
  { getOnInit: true },
);

export const useBuildingConnectedAuthFlowStatus = () => {
  const [buildConnectedAuthFlowStatus, setBuildConnectedAuthFlowStatus] =
    useAtom(statusAtom);

  return { buildConnectedAuthFlowStatus, setBuildConnectedAuthFlowStatus };
};
