import React, { useEffect, useRef } from 'react';

import { Box, LinearProgress, Portal, Typography } from '@mui/material';
import { toastAtom, useToastDialogs } from 'hooks/useToastDialogs';
import { ConfirmToastDialog } from './ConfirmToastDialog';
import { Toast, ToastTitle } from 'components/Toast';
import { LinearProgressProps } from '@mui/material/LinearProgress/LinearProgress';
import { joinSx } from 'utils/helpers';
import { useAtomValue } from 'jotai/index';

const AUTO_CLOSE_TIMEOUT = 7000;
const PROGRESS_INTERVAL = 250;

const CountdownProgressBar = (props: { color: LinearProgressProps['color'] }) => {
  const [progress, setProgress] = React.useState(
    100 - 100 / (AUTO_CLOSE_TIMEOUT / PROGRESS_INTERVAL),
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(
        (progress) => progress - 100 / (AUTO_CLOSE_TIMEOUT / PROGRESS_INTERVAL),
      );
    }, PROGRESS_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '-6px',
        left: 0,
        height: '10px',
        width: '100%',
      }}
    >
      <LinearProgress variant="determinate" value={progress} color={props.color} />
    </Box>
  );
};

export const ToastDialogs = () => {
  const hideTimeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const toast = useAtomValue(toastAtom);
  const { resetToast } = useToastDialogs();

  useEffect(() => {
    if (!toast?.type || !('autoClose' in toast.params && toast.params.autoClose)) {
      return;
    }

    // From specs: Display for 7 seconds.
    hideTimeoutIdRef && clearTimeout(hideTimeoutIdRef.current);
    hideTimeoutIdRef.current = setTimeout(() => {
      resetToast();
    }, AUTO_CLOSE_TIMEOUT);
  }, [resetToast, toast]);

  if (!toast) return null;

  if (toast.type === 'success' || toast.type === 'info' || toast.type === 'error') {
    const { params } = toast;
    return (
      <Portal>
        <Toast
          severity={toast.type}
          onClose={() => {
            resetToast();
            if (params.onClose) {
              params.onClose();
            }
          }}
          sx={joinSx({ zIndex: 1500 }, params.sx)}
        >
          <ToastTitle>{params.title}</ToastTitle>
          <Box
            sx={{
              position: 'absolute',
              marginTop: '5px',
              marginRight: '1rem',
              left: '16px',
            }}
          >
            <Typography sx={{ display: 'block' }}>{params.text}</Typography>
            {params.actions}
          </Box>
          {params.autoClose ? <CountdownProgressBar color={toast.type} /> : null}
        </Toast>
      </Portal>
    );
  }

  // CONFIRM TOAST
  if (toast.type === 'confirm') {
    return <ConfirmToastDialog />;
  }

  return null;
};
