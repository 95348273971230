import { Badge, Grid, ListItemIcon, Menu, MenuItem, ListItemText } from '@mui/material';
import { ReactNode, useState } from 'react';
import { GridProps } from '@mui/material/Grid/Grid';
import { CircleChip } from 'components/CustomChips/CircleChip';

type OverlapWithBadgeWrapperProps = GridProps & {
  children: ReactNode[];
  labels: string[];
  overlap?: string;
  chipBorderWidth?: string;
  childrenToDisplay?: number;
  badgeTop?: number;
  sameLevelBadge?: boolean;
};

export function OverlapWithBadgeWrapper({
  overlap = '.25rem',
  chipBorderWidth = '2px',
  children,
  labels,
  childrenToDisplay = 4,
  badgeTop = 0,
  sameLevelBadge,
  ...rest
}: OverlapWithBadgeWrapperProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isBadgeInvisible = children.length - childrenToDisplay < 1;
  return (
    <>
      <Badge
        badgeContent={`+${children.length - childrenToDisplay}`}
        variant="standard"
        invisible={isBadgeInvisible || !!sameLevelBadge}
        sx={{
          '& .MuiBadge-badge': {
            top: badgeTop,
            right: -8,
            zIndex: children.length,
            cursor: 'pointer',
            backgroundColor: 'grey.75',
            fontSize: '14px',
            fontWeight: 700,
            padding: 1,
            boxShadow: (theme) =>
              `0 0 0 ${chipBorderWidth} ${theme.palette.common.white}`,
          },
        }}
        onClick={!isBadgeInvisible && !sameLevelBadge ? handleClick : undefined}
      >
        <Grid container wrap="nowrap" {...rest}>
          {children &&
            children.map((child, index) => {
              if (index < childrenToDisplay) {
                return (
                  <Grid
                    item
                    key={index}
                    sx={{
                      marginLeft: `-${overlap}`,
                      display: 'inline',
                      zIndex: children.length - index,
                      '&:hover': {
                        zIndex: children.length + 1,
                      },
                      '& .MuiChip-root': {
                        boxShadow: (theme) =>
                          `0 0 0 ${chipBorderWidth} ${theme.palette.common.white}`,
                      },
                    }}
                  >
                    {child}
                  </Grid>
                );
              }

              return null;
            })}
          {!!sameLevelBadge && !isBadgeInvisible && (
            <Grid
              item
              sx={{
                marginLeft: `-${overlap}`,
                display: 'inline',
                '& .MuiChip-root': {
                  zIndex: children.length,
                  boxShadow: (theme) =>
                    `0 0 0 ${chipBorderWidth} ${theme.palette.common.white}`,
                },
              }}
            >
              <CircleChip
                className="same-level-badge"
                label={`+${children.length - childrenToDisplay}`}
                sx={{
                  background: (theme) => `${theme.palette.grey[75]}!important`,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'grey.75',
                  },
                  '& .MuiChip-label': {
                    color: 'grey.700',
                    fontSize: '12px',
                  },
                }}
                onClick={handleClick}
              />
            </Grid>
          )}
        </Grid>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={!isBadgeInvisible && open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {children.map((child, index) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const onClick = (child as any)?.props?.onClick;
          return (
            <MenuItem
              key={index + labels[index]}
              sx={{
                cursor: onClick ? 'pointer' : 'auto',
                '&:hover': { backgroundColor: 'inherit' },
              }}
              onClick={onClick}
            >
              <ListItemIcon>{child}</ListItemIcon>{' '}
              <ListItemText>{labels[index]}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
