import Grid from '@mui/material/Grid';
import { DialogContent, Divider, Typography } from '@mui/material';
import { FlyoutDialog } from 'components/FlyoutDialog';
import { ProjectMembers } from './ProjectMembers';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { InviteMemberForm } from './InviteMemberForm';
import { FlyoutDialogProps } from 'components/FlyoutDialog/FlyoutDialog';

type AddProjectMembersFlyoutProps = Omit<FlyoutDialogProps, 'children'>;

export const AddProjectMembersFlyout = (props: AddProjectMembersFlyoutProps) => (
  <FlyoutDialog titleIcon={faUserPlus} title="Add members" {...props}>
    <DialogContent>
      <Grid container direction="column">
        <InviteMemberForm />
        <Divider sx={{ mt: 4, mb: 2 }} />
        <Typography variant="h4Bold">Project Members</Typography>
        <ProjectMembers />
      </Grid>
    </DialogContent>
  </FlyoutDialog>
);
