import { isArray } from 'lodash-es';
import { OmniClassCategory } from 'types/OmniClassCategory';

export const findLevelOneCategories = (
  mappedOmniClassCategories: Record<number, OmniClassCategory>,
  idsToFindFirstLevelParent: number[],
) => {
  // Find the passed value (a category id) deep in the object structure
  const findSelectedCategories = (
    mappedCategories: Record<number, OmniClassCategory>,
    ids: number[],
    levelOneCategories = [] as OmniClassCategory[],
  ): [OmniClassCategory[], number[], boolean] => {
    if (ids.length) {
      const remainingIds = [...ids];
      const newMatches = [...levelOneCategories];
      const keys = Object.keys(mappedCategories);
      for (let i = 0; i < keys.length; i++) {
        if (!remainingIds.length) break;
        const key = parseInt(Object.keys(mappedCategories)[i]);
        const indexOfMatch = remainingIds.indexOf(key);
        if (indexOfMatch >= 0) {
          remainingIds.splice(indexOfMatch, 1);
          if (mappedCategories[key].parent_category === null) {
            newMatches.push(mappedCategories[key]);
            break;
          } else {
            return [newMatches, remainingIds, true];
          }
        } else {
          if (mappedCategories[key].subCategories) {
            const [subCategoriesMatched, remainingIdsFromSubcategories, thereIsNewMatch] =
              findSelectedCategories(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                mappedCategories[key].subCategories!,
                remainingIds,
                newMatches,
              );
            if (thereIsNewMatch) {
              if (mappedCategories[key].parent_category === null) {
                newMatches.push(mappedCategories[key]);
              } else {
                return [subCategoriesMatched, remainingIdsFromSubcategories, true];
              }
            }
          }
        }
      }
      return [newMatches, remainingIds, false];
    }
    return [levelOneCategories, ids, false];
  };

  const [matches] = findSelectedCategories(
    mappedOmniClassCategories,
    idsToFindFirstLevelParent,
  );
  return matches;
};

export const findCategoryBreathFirst = (
  startingNodes: OmniClassCategory[] | OmniClassCategory | null,
  categoryId: number,
) => {
  const queue = startingNodes
    ? isArray(startingNodes)
      ? [...startingNodes]
      : [startingNodes]
    : [];
  for (let index = 0; index < queue.length; index++) {
    const category = queue[index];
    if (category.id === categoryId) {
      return category;
    }
    if (category.subCategories) {
      queue.push(...Object.values(category.subCategories));
    }
  }
  return null;
};

export const findCategoryPath = (
  mappedCategories: Record<number, OmniClassCategory>,
  categoryId: number,
  previousResult: OmniClassCategory[] = [],
): OmniClassCategory[] => {
  if (mappedCategories[categoryId]) {
    return previousResult.concat(mappedCategories[categoryId]);
  }
  for (const id in mappedCategories) {
    if (mappedCategories[id].subCategories) {
      const path = findCategoryPath(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mappedCategories[id].subCategories!,
        categoryId,
        previousResult.concat(mappedCategories[id]),
      );
      if (path.length > 0) {
        return path;
      }
    }
  }
  return [];
};
