import { Box } from '@mui/material';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MuiAutocomplete = {
  defaultProps: {
    popupIcon: (
      <Box component={FontAwesomeIcon} icon={faChevronDown} sx={{ width: '1rem' }} />
    ),
  },
};
