import { scrollbarWidth } from 'utils/constants';
import { atomWithStorage } from 'jotai/utils';
import { useTableWidths } from 'hooks/useTableWidths';

export const defaultEstimateWidths = {
  detailPanel: 49,
  costGroupCode: 75,
  costGroupName: 240,
  componentCost: 150,
  totalCost: 125,
  actions: 35,
  actionsSeparator: 15,
  scrollbar: scrollbarWidth,
  wideTotalCost: 0,
};
defaultEstimateWidths.wideTotalCost =
  defaultEstimateWidths.totalCost + defaultEstimateWidths.actions;

export const maxWidth = {
  costGroupName: 550,
};

const initialValue = {
  columnsWidth: {} as Record<string, number>,
  setup: false,
};

const estimateTableWidthsAtom = atomWithStorage(
  'estimate-table-widths',
  initialValue,
  undefined,
  { getOnInit: true },
);

export function useEstimateTableWidths() {
  const { tableWidths, setTableWidths, updateTableWidths } = useTableWidths(
    estimateTableWidthsAtom,
  );

  return {
    estimateTableWidths: tableWidths,
    setEstimateTableWidths: setTableWidths,
    updateTableWidths,
  };
}
