import { Box, Avatar, SxProps, Typography, Theme } from '@mui/material';
import { UserCompany } from 'types/Company';
import { getInitials, joinSx } from 'utils/helpers';

type WorkspaceItemCardProps = {
  workspace: UserCompany;
  sx?: SxProps<Theme>;
};

export const WorkspaceItemCard = (props: WorkspaceItemCardProps) => {
  return (
    <Box sx={joinSx({ display: 'flex', alignItems: 'center', gap: 1 }, props.sx)}>
      {props.workspace.logo_url ? (
        <Avatar
          variant="rounded"
          sx={{ height: '2rem', width: '2rem' }}
          alt={props.workspace.name}
          src={props.workspace.logo_url}
        />
      ) : (
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: 'blue.800',
            fontSize: '14px',
            fontWeight: 600,
            height: '2rem',
            width: '2rem',
          }}
        >
          {getInitials(props.workspace.name)}
        </Avatar>
      )}

      <Typography>{props.workspace.name}</Typography>
    </Box>
  );
};
