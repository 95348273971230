import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { Company } from 'types/Company';
import { queryKeys } from 'utils/reactQuery';
import { useAttachmentsByEntity, useUploadAttachments } from 'hooks/attachments';
import { useCurrentCompany } from './useCurrentCompany';

const companyKey = ['companyProfile'];
export const useCompany = () => {
  const { currentCompany } = useCurrentCompany();
  const companyQuery = useQuery({
    queryKey: companyKey,
    queryFn: ({ signal }) => {
      if (!currentCompany) throw new Error('Current company not found');
      const endPoint = Resources.COMPANY_BY_ID.replace(
        '<int:pk>',
        String(currentCompany.id),
      );
      return ApiService.get(endPoint, { signal }).then((res) => res.data as Company);
    },
    staleTime: Infinity,
    enabled: !!currentCompany,
  });
  return { companyQuery };
};

export function useCurrentUserCompanyLogo() {
  const { currentCompany } = useCurrentCompany();

  const { attachmentsByEntityQuery } = useAttachmentsByEntity({
    entityName: 'company',
    entityId: currentCompany?.id,
  });
  const pictureData = attachmentsByEntityQuery.data?.filter(
    (attachment) => !attachment.field_name || attachment.field_name === 'picture',
  );
  const logoData = attachmentsByEntityQuery.data?.filter(
    (attachment) => attachment.field_name === 'logo',
  );
  const companyLogoQuery = {
    ...attachmentsByEntityQuery,
    data: pictureData?.[pictureData.length - 1],
    logoData: logoData?.[logoData.length - 1],
  };
  return {
    companyLogoQuery,
  };
}

export const useSaveCompanyProfile = () => {
  const { currentCompany } = useCurrentCompany();
  const { loadCurrentUser } = useCurrentUser();

  const saveCompanyProfileMutation = useMutation({
    mutationFn: (updatedCompany: Partial<Company>) => {
      if (!currentCompany)
        return Promise.reject(new Error('Current company profile not found'));
      const endPoint = Resources.COMPANY_BY_ID.replace(
        '<int:pk>',
        String(currentCompany.id),
      );
      return ApiService.patch(endPoint, updatedCompany).then(
        (res) => res.data as Company,
      );
    },
    onSettled: () => {
      loadCurrentUser();
    },
  });
  return { saveCompanyProfileMutation };
};

export const useSaveCompanyLogo = () => {
  const queryClient = useQueryClient();
  const { currentCompany } = useCurrentCompany();
  const { uploadAttachmentsMutation } = useUploadAttachments();
  const { currentUser } = useCurrentUser();

  const saveCompanyLogoMutation = useMutation({
    mutationFn: ({ file, fieldName }: { file: File; fieldName: string }) => {
      if (!currentCompany) throw new Error('User current company not set');
      return uploadAttachmentsMutation.mutateAsync({
        entityName: 'company',
        entityId: currentCompany.id,
        files: [{ file, name: 'logo' }],
        fieldName,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.companyLogo(currentUser?.email), data);
    },
  });
  return { saveCompanyLogoMutation };
};
