import { isEmpty, keyBy } from 'lodash-es';
import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  usePermissionRolePermissions,
  usePermissionRoles,
} from 'features/ManageUsers/hooks/permissionRoles';
import { checkModelPermissionsAreInternal } from 'features/Auth/internal-model-permissions';
import { useFormikContext } from 'formik';
import { Role } from 'types/User';
import { MouseEvent, useEffect } from 'react';

const SelectOption = (props: {
  value: Role['id'];
  optionRole: Role;
  isExternalUser: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}) => {
  const {
    permissionRolePermissionsQuery: { data: roleModelPermissions, isLoading },
  } = usePermissionRolePermissions(props.optionRole.id);

  const isRoleInternal =
    !!roleModelPermissions && checkModelPermissionsAreInternal(roleModelPermissions);

  const isDisabled = (props.isExternalUser && isRoleInternal) || isLoading;
  const selectOption = (
    <MenuItem
      sx={{ width: '296px' }}
      value={props.optionRole.id}
      disabled={isDisabled}
      key={props.optionRole.id}
      onClick={props.onClick}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="textDefaultSemiBold">{props.optionRole.name}</Typography>
        </Grid>
      </Grid>
    </MenuItem>
  );
  return isDisabled ? (
    <Tooltip
      title={isDisabled ? 'External users can not be assigned to this role' : ''}
      arrow
      placement="top"
    >
      <div>{selectOption}</div>
    </Tooltip>
  ) : (
    selectOption
  );
};

type RoleSelectProps = {
  roleId: number | null;
  isExternalUser: boolean;
  disabled: boolean;
};

export const RoleSelect = ({ roleId, isExternalUser, disabled }: RoleSelectProps) => {
  const {
    permissionRolePermissionsQuery: { data: roleModelPermissions },
  } = usePermissionRolePermissions(roleId ?? undefined);
  const {
    permissionRolesQuery: { data: roles },
  } = usePermissionRoles();

  const { setFieldValue, handleBlur } = useFormikContext();
  const rolesHash = keyBy(roles, 'id');
  const role = roleId ? rolesHash[roleId] : null;

  const isRoleInternal =
    !!roleModelPermissions && checkModelPermissionsAreInternal(roleModelPermissions);

  useEffect(() => {
    if (isExternalUser && isRoleInternal && role !== null) {
      setFieldValue('role', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExternalUser, isRoleInternal, role]);

  const handleOnChange = (event: SelectChangeEvent<number>) => {
    setFieldValue('role', event.target.value);
  };

  return (
    <Select
      placeholder="Role"
      labelId="select-role-label"
      displayEmpty
      name="role"
      disabled={disabled}
      value={roleId ?? ''}
      onChange={handleOnChange}
      onBlur={handleBlur}
      renderValue={(value) => {
        if (!roles || !value) {
          return <Typography sx={{ color: 'grey.400' }}>Select a role</Typography>;
        }
        return rolesHash[value].name || '';
      }}
      startAdornment={isEmpty(roles) && <Box sx={{ verticalAlign: 'middle' }}></Box>}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
      sx={{ width: '100%', height: '2rem' }}
    >
      {roles?.map((optionRole) => (
        <SelectOption
          value={optionRole.id}
          optionRole={optionRole}
          isExternalUser={isExternalUser}
          key={optionRole.id}
        />
      ))}
    </Select>
  );
};
