import { forwardRef } from 'react';
import { TextField, InputAdornment, TextFieldProps, SxProps } from '@mui/material';
import { SourceInfo } from 'react-number-format';
import { Theme } from '@mui/material/styles';
import { NumberFormat, NumberFormatProps } from 'components/NumberFormat/NumberFormat';

type NumberFormatCustomProps = NumberFormatProps & {
  onChange: (value?: number | '') => void;
};
// CPE-521 Number Input based on https://mui.com/material-ui/react-text-field/#FormattedInputs.js
const NumberFormatCustom = forwardRef(function NumberFormatCustom(
  props: NumberFormatCustomProps,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange(values.floatValue ?? '');
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

type NumberFieldProps = Omit<TextFieldProps, 'onChange' | 'prefix'> & {
  prefix?: JSX.Element | string;
  suffix?: JSX.Element | string;
  suffixSx?: SxProps<Theme>;
  readOnly?: boolean;
  NumberFormatProps?: NumberFormatProps;
  onChange?: (value?: number) => void;
};

export const NumberField = (props: NumberFieldProps) => {
  const {
    prefix,
    suffix,
    onFocus,
    onBlur,
    onKeyPress,
    onChange,
    NumberFormatProps,
    readOnly,
    disabled,
    suffixSx = {},
    ...others
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (e: any) => {
    onChange?.(e !== '' ? e : null);
  };

  return (
    <TextField
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumberFormatCustom as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputProps: NumberFormatProps as any,
        onBlur,
        onFocus,
        onChange: handleOnChange,
        startAdornment: prefix && (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ),
        endAdornment: suffix && (
          <InputAdornment position="end" sx={suffixSx}>
            {suffix}
          </InputAdornment>
        ),
        readOnly,
        disabled,
        onKeyPress,
        sx: {
          '& input': {
            fontFamily: (theme) => theme.typography.monospaceDefault,
          },
        },
      }}
      fullWidth
      {...others}
    />
  );
};

type NumberFormatMuiProps = Omit<NumberFormatProps, 'onChange' | 'size' | 'color'> & {
  onChange?: (value?: number, sourceInfo?: SourceInfo) => void;
};
export const NumberFormatMui = ({ onChange, ...props }: NumberFormatMuiProps) => {
  return (
    <NumberFormat
      {...props}
      customInput={TextField}
      onValueChange={({ floatValue }, sourceInfo) => onChange?.(floatValue, sourceInfo)}
    />
  );
};
