import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    footer: true;
  }
}
export const MuiPaper: Components<Theme>['MuiPaper'] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[200],
      borderRadius: '8px',
      boxShadow: '0px 1px 4px rgba(106, 117, 155, 0.25)',
    }),
  },
  variants: [
    {
      props: { variant: 'footer' },
      style: () => ({
        boxShadow: '0px 0px 6px rgba(118, 124, 137, 0.1)',
      }),
    },
  ],
};
