import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { BaseUnit } from 'types/Component';
import { queryKeys } from 'utils/reactQuery';

export const useBaseUnits = () => {
  const {
    data: baseUnits,
    isLoading,
    isError,
  } = useQuery({
    queryKey: queryKeys.componentBaseUnits,
    queryFn: () =>
      ApiService.get(Resources.ALL_BASE_UNITS).then((res) => res.data as BaseUnit[]),
    // Note: this query is persisted, should not have staleTime: Infinity
    staleTime: 24 * 60 * 60 * 1000, // 1 day
    refetchOnWindowFocus: false,
  });

  return {
    baseUnits,
    isLoading,
    isError,
  };
};
