import { useCurrentCompany } from 'features/Company/hooks/useCurrentCompany';
import { useMemo, useRef } from 'react';
import { UserCompany } from 'types/Company';
import { MixpanelActions, mixpanelActions } from 'services/Mixpanel';
import { Callback, Dict, RequestOptions } from 'mixpanel-browser';

export function useMixpanel() {
  const { currentCompany } = useCurrentCompany();
  const currentCompanyRef = useRef<UserCompany | undefined>();
  currentCompanyRef.current = currentCompany;

  const mixpanel = useMemo(() => {
    return {
      ...mixpanelActions,
      track: (...props) => mixpanelActions.track(currentCompanyRef.current, ...props),
      click: (...props) => mixpanelActions.click(currentCompanyRef.current, ...props),
      pageLoaded: (...props) =>
        mixpanelActions.pageLoaded(currentCompanyRef.current, ...props),
    } as Omit<MixpanelActions, 'track' | 'click' | 'pageLoaded'> & {
      track: (
        event_name: string,
        properties?: Dict,
        optionsOrCallback?: RequestOptions | Callback,
        callback?: Callback,
      ) => void;
      click: (target: string, to?: string, properties?: Dict) => void;
      pageLoaded: (target: string, to?: string) => void;
    };
  }, []);

  return { mixpanel };
}
