import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { Resources } from 'api/Resources';
import { ApiService } from 'api/ApiService';
import { Notification } from 'types/Notification';
import { useMemo } from 'react';
import { isDate31DaysAgoOrMore } from '../helpers/date-helper';
import { addSearchParamsToUrl } from 'utils/helpers';
import { queryKeys } from 'utils/reactQuery';

const limit = 10;

export function useNotifications() {
  const query = useInfiniteQuery({
    queryKey: queryKeys.notifications,
    queryFn: ({ signal, pageParam }) => {
      const endPoint = addSearchParamsToUrl({
        url: Resources.NOTIFICATIONS,
        searchParams: {
          limit: pageParam * limit + limit,
          offset: pageParam * limit,
        },
      });
      return ApiService.get(endPoint, { signal }).then((res) =>
        (res.data as Notification[]).filter(
          // TODO: filter on the BE
          (notification) => !isDate31DaysAgoOrMore(notification.date_created),
        ),
      );
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < limit) return undefined;
      return allPages.length;
    },
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });

  const { data } = query;
  const flatData = useMemo(() => {
    return data?.pages.flat();
  }, [data?.pages]);

  return { ...query, flatData };
}

export function useUnReadNotifications() {
  const { flatData } = useNotifications();

  return useMemo(() => {
    return flatData?.filter((n) => !n.is_read);
  }, [flatData]);
}

export function useMarkReadNotifications() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (notificationIds: Notification['id'][]) => {
      return ApiService.post(Resources.NOTIFICATIONS_READ, { ids: notificationIds }).then(
        (res) => res.data as Notification[],
      );
    },
    onSuccess: (result) => {
      queryClient.setQueryData<InfiniteData<Notification[]>>(
        queryKeys.notifications,
        (prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            pages: prev.pages.map((page) =>
              page.map((prevNotification) => {
                const updatedNotification = result.find(
                  (resultNotification) => resultNotification.id === prevNotification.id,
                );
                if (updatedNotification) return updatedNotification;
                return prevNotification;
              }),
            ),
          };
        },
      );
    },
  });
}
