import { useAtom, WritableAtom } from 'jotai';

import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { useEffect } from 'react';
import { RESET } from 'jotai/utils';

type UseAtomWithUserIdType<T, K> = (atom: K) => [value: T, setValue: (value: T) => void];

type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

type AtomWithStorage<V> = WritableAtom<V, [SetStateActionWithReset<V>], V>;
type AtomValue =
  | { isStorageAtomValue: boolean; [key: number]: unknown | null }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
  | null;
export const useAtomWithUserId: UseAtomWithUserIdType<
  AtomValue,
  AtomWithStorage<AtomValue>
> = (atom) => {
  const [atomData, setAtomData] = useAtom(atom) as unknown as [
    atomData: AtomValue,
    setAtomData: (value: unknown) => void,
  ];
  const { currentUser } = useCurrentUser();

  const setValue = (value: unknown) => {
    if (currentUser?.id) {
      const prevData = atomData?.isStorageAtomValue ? atomData : {};
      setAtomData({
        ...prevData,
        [currentUser.id]: value,
        isStorageAtomValue: true,
      });
    }
  };
  // To set the initialValue
  useEffect(() => {
    if (atomData !== undefined && !atomData?.isStorageAtomValue) {
      setValue(atomData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atomData]);
  const value =
    currentUser?.id && atomData && atomData[currentUser.id] !== undefined
      ? atomData[currentUser.id]
      : null;
  return [value, setValue];
};
