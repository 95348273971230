import { Box, Theme } from '@mui/material';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MuiSelect = {
  defaultProps: {
    IconComponent: ({ className }: { className: string }) => {
      return (
        <Box
          component={FontAwesomeIcon}
          icon={faChevronDown}
          sx={{ translate: '-4px 0' }}
          className={className}
        />
      );
    },
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      borderRadius: '8px',
      // This component can not extend `size` prop to implement large
      // https://stackoverflow.com/questions/73730510/cant-create-custom-variant-for-muiselect
      '&.large': {
        height: theme.textField.size.large,
      },
    }),
  },
};
