import { Box, Divider, Popover, Typography } from '@mui/material';
import { NavBarButton } from 'components/NavBarButton/NavBarButton';
import { PrivateURL } from 'Urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulbDollar } from '@fortawesome/pro-regular-svg-icons';
import { useIsLocationActive } from 'hooks/useIsLocationActive';
import { ReactNode, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SxProps } from '@mui/system/styleFunctionSx';
import { joinSx } from 'utils/helpers';
import usePermissions from 'features/Auth/hook/usePermissions';
import { useTenantFeatureFlags } from 'hooks/useTenantFeatureFlags';
import { useAnalysisFlyoutDetails } from 'features/ConceptLab/hooks/state';
import smallLogo from 'assets/img/concntric-logo-sm-light.svg';
import { AnalysisFormModal } from 'features/ConceptLab/Analysis/AnalysisFormModal';
import { useRecentConceptAnalysis } from 'features/ConceptLab/hooks/useRecentConceptAnalysis';

export function NavBarConceptLabButton() {
  const {
    tenantFeatureFlags: { concept_lab: isConceptLabEnabled },
  } = useTenantFeatureFlags();
  const { isExactLocationActive } = useIsLocationActive();
  const popoverAnchor = useRef<HTMLDivElement | null>(null);
  const [openedPopover, setOpenedPopover] = useState(false);
  const { hasAnyPerms, Permissions } = usePermissions();
  const { setAnalysisDetailId } = useAnalysisFlyoutDetails();

  const { recentConceptAnalysis } = useRecentConceptAnalysis();

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const id = 'navbar-concept-lab-mouse-over-popover';
  const hasRecentAnalysis = recentConceptAnalysis.length > 0;

  if (
    !isConceptLabEnabled ||
    !hasAnyPerms([
      Permissions.CHANGE_CONCEPT_LAB_ANALYSIS,
      Permissions.ADD_CONCEPT_LAB_ANALYSIS,
    ])
  )
    return null;

  return (
    <>
      <AnalysisFormModal />
      <Box
        ref={popoverAnchor}
        aria-owns={id}
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        <NavBarButton
          icon={<FontAwesomeIcon icon={faLightbulbDollar} />}
          sx={{ px: 1 }}
          isActive={
            isExactLocationActive(PrivateURL.CONCEPT_LAB) ||
            isExactLocationActive(PrivateURL.CONCEPT_LAB_ANALYSIS_BY_ID) ||
            isExactLocationActive(PrivateURL.CONCEPT_LAB_CONCEPTS)
          }
        >
          Concept Hub
        </NavBarButton>
      </Box>
      <Popover
        id={id}
        sx={{ pointerEvents: 'none' }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={popoverLeave}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
          sx: {
            pointerEvents: 'auto',
            minWidth: 220,
            overflow: 'auto',
          },
        }}
      >
        {hasRecentAnalysis ? (
          <>
            <Typography
              variant="textDefaultSemiBold"
              sx={{ color: 'grey.500', p: 1.5, display: 'block' }}
            >
              Recent
            </Typography>
            {recentConceptAnalysis.map((recent) => {
              return (
                <NavBardPopoverButton
                  key={recent.id}
                  onClick={popoverLeave}
                  to={PrivateURL.CONCEPT_LAB_ANALYSIS_BY_ID.replace(
                    ':analysisId',
                    String(recent.id),
                  )}
                >
                  <Box
                    component="img"
                    src={smallLogo}
                    alt="Small Concentric logo"
                    sx={{ height: '1.25rem' }}
                  />
                  <Typography variant="textDefaultSemiBold">{recent.name}</Typography>
                </NavBardPopoverButton>
              );
            })}
          </>
        ) : null}
        {recentConceptAnalysis.length > 0 ? <Divider sx={{ my: 1 }} /> : null}
        <NavBardPopoverButton to={PrivateURL.CONCEPT_LAB} onClick={popoverLeave}>
          <Typography variant="textDefaultSemiBold">View all analyses</Typography>
        </NavBardPopoverButton>
        <NavBardPopoverButton
          onClick={() => {
            setAnalysisDetailId('new');
            popoverLeave();
          }}
        >
          <Typography variant="textDefaultSemiBold">Create analysis</Typography>
        </NavBardPopoverButton>
      </Popover>
    </>
  );
}

function NavBardPopoverButton({
  onClick,
  to,
  sx,
  children,
}: {
  onClick: () => void;
  to?: string;
  sx?: SxProps;
  children: ReactNode;
}) {
  return (
    <Box
      {...(to
        ? {
            component: Link,
            to,
          }
        : {})}
      sx={joinSx(
        {
          cursor: 'pointer',
          px: 1.5,
          py: 1,
          display: 'flex',
          gap: 1,
          '&:hover': { backgroundColor: 'blue.50' },
        },
        sx,
      )}
      onClick={onClick}
    >
      {children}
    </Box>
  );
}
