import { SxProps } from '@mui/material';
import { PageHeader } from 'components/PageContainer/PageHeader';
import { projectSections, SectionType } from 'Urls';

export function ProjectPageHeader({
  section,
  sx,
}: {
  section: SectionType;
  sx?: SxProps;
}) {
  return (
    <PageHeader icon={projectSections[section].icon} sx={sx}>
      {projectSections[section].label}
    </PageHeader>
  );
}
