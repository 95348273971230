import { matchPath, useLocation } from 'react-router-dom';

export function useIsLocationActive() {
  const location = useLocation();

  const isExactLocationActive = (path: string) =>
    !!path &&
    matchPath(location.pathname, {
      path,
      exact: true,
      strict: false,
    })?.isExact;

  return { isExactLocationActive };
}
