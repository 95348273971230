import { faPlus, faRulerTriangle } from '@fortawesome/pro-regular-svg-icons';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { DebouncedTextField } from 'components/DebouncedTextField/DebouncedTextField';
import { ModalTitleWithIconAndClose } from 'components/ModalTitleWithIconAndClose/ModalTitleWithIconAndClose';
import { Formik, FormikProps } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { useAnalysisFlyoutDetails } from '../hooks/state';
import { ConceptAnalysis, ConceptAnalysisFormValues } from '../type';
import { ANALYSIS_TYPE_OPTIONS } from '../utils';
import { useSaveConceptLabAnalysis } from '../hooks/analysis';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { useProjectCustomCostGroupDefinition } from 'features/Company/hooks/useProjectCustomCostGroupDefinition';
import { History, PrivateURL } from 'Urls';
import { CategorySelect } from 'components/CategorySelect';
import { NumberField } from 'components/NumberField/NumberField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseUnitField } from 'components/BaseUnitField/BaseUnitField';

const formSchema = Yup.object({
  name: Yup.string().required('Name required'),
  description: Yup.string().nullable(),
  definition: Yup.number()
    .min(1, 'Format required')
    .required('Format required')
    .nullable('true'),
  type: Yup.string().required('Analysis type required'),
  construction_category: Yup.number()
    .nullable()
    .required('Category selection is required')
    .typeError('Category selection is required'),
  area: Yup.number().nullable(),
  base_unit_count: Yup.number().nullable(),
});

export const AnalysisFormModal = () => {
  const { analysisDetailId, closeAnalysisModal } = useAnalysisFlyoutDetails();
  const formikRef = useRef<FormikProps<ConceptAnalysisFormValues>>(null);
  const { customCostGroupDefinitionsQuery } = useProjectCustomCostGroupDefinition({
    includeChildren: false,
    excludeEstimatesOnly: false,
  });
  const { successToast, errorToast } = useToastDialogs();

  const formInitialValues = {
    name: '',
    description: '',
    definition: '',
    type: '',
    construction_category: null,
    area: null,
    base_unit_count: null,
  } as ConceptAnalysisFormValues;

  const { saveConceptLabAnalysisMutation } = useSaveConceptLabAnalysis();

  const handleOnSubmit = (values: ConceptAnalysisFormValues) => {
    saveConceptLabAnalysisMutation.mutate(
      { analysis: values as ConceptAnalysis },
      {
        onSuccess: () => {
          successToast({
            title: 'Analysis saved',
            text: 'Analysis saved',
          });
          closeAnalysisModal();
        },
        onSettled: (data) => {
          History.push(
            PrivateURL.CONCEPT_LAB_ANALYSIS_BY_ID.replace(':analysisId', String(data.id)),
          );
        },
        onError: () => {
          errorToast({
            text: `Analysis could not be saved`,
          });
        },
      },
    );
  };

  const handleDone = () => {
    setTimeout(() => formikRef.current?.handleSubmit(), 0);
  };

  return (
    <Dialog
      fullWidth
      open={analysisDetailId === 'new'}
      onClose={() => closeAnalysisModal()}
      maxWidth="md"
    >
      <ModalTitleWithIconAndClose
        icon={faPlus}
        title="New Analysis"
        onModalClose={closeAnalysisModal}
      />
      <DialogContent sx={{ py: 2, px: 4, minHeight: '250px' }}>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          onSubmit={(values) => handleOnSubmit(values)}
          innerRef={formikRef}
          validationSchema={formSchema}
        >
          {({ errors, handleChange, handleBlur, setFieldValue, touched, values }) => {
            return (
              <Grid
                container
                columnSpacing={2}
                rowSpacing={1}
                sx={{ position: 'relative' }}
              >
                <Grid item xs={12}>
                  <DebouncedTextField
                    value={values.name}
                    placeholder="Give a name to this analysis..."
                    label="Name"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    fullWidth
                    error={!!(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <DebouncedTextField
                    value={values.description}
                    placeholder="Describe your analysis for future reference"
                    label="Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    fullWidth
                    rows={2}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CategorySelect
                    label="Category *"
                    onChange={(category) => {
                      setFieldValue('construction_category', category?.id);
                    }}
                    value={values.construction_category ?? undefined}
                    TextFieldProps={{
                      error: !!(
                        touched.construction_category && errors.construction_category
                      ),
                      helperText:
                        touched.construction_category && errors.construction_category,
                      name: 'construction_category',
                      onBlur: handleBlur,
                      className: 'gapForHelperText',
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberField
                    label="Target Area"
                    name="area"
                    prefix={<FontAwesomeIcon icon={faRulerTriangle} />}
                    suffix="GSF"
                    value={values.area}
                    onFocus={(e) => e.target.select()}
                    onChange={(value) => {
                      setFieldValue('area', value);
                    }}
                    NumberFormatProps={{ allowNegative: false }}
                    error={!!(touched.area && errors.area)}
                    helperText="Customizable in every scenario"
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={4}>
                  <BaseUnitField
                    constructionCategory={values.construction_category}
                    placeholder="0"
                    value={values.base_unit_count ?? ''}
                    onFocus={(e) => e.target.select()}
                    onChange={(value) => {
                      setFieldValue('base_unit_count', value);
                    }}
                    name="base_unit_count"
                    onBlur={handleBlur}
                    helperText="Customizable in every scenario"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography component="div" variant="textDefaultBold" sx={{ mt: 1 }}>
                    Which format do you want to use?
                  </Typography>

                  <RadioGroup row sx={{ mt: 1, ml: 1 }}>
                    {customCostGroupDefinitionsQuery.isLoading ? (
                      <CircularProgress />
                    ) : null}
                    {customCostGroupDefinitionsQuery.data?.map((definition) => (
                      <FormControlLabel
                        key={definition.id}
                        control={
                          <Radio
                            checked={definition.id === values.definition}
                            onChange={() => {
                              setFieldValue('definition', definition.id);
                            }}
                            size="small"
                          />
                        }
                        sx={{
                          pr: 2,
                          border: '1px solid',
                          borderColor: 'grey.100',
                          borderRadius: 2,
                        }}
                        label={definition.name}
                      />
                    ))}
                  </RadioGroup>
                  {touched.definition && errors.definition ? (
                    <FormHelperText sx={{ color: 'error.main' }}>
                      {errors.definition}
                    </FormHelperText>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <Typography component="div" variant="textDefaultBold" sx={{ mt: 1 }}>
                    How will you perform this analysis?
                  </Typography>

                  <RadioGroup row sx={{ mt: 1, ml: 1 }}>
                    {ANALYSIS_TYPE_OPTIONS.map((typeOption) => (
                      <FormControlLabel
                        key={typeOption.value}
                        control={
                          <Radio
                            checked={typeOption.value === values.type}
                            onChange={() => {
                              setFieldValue('type', typeOption.value);
                            }}
                            size="small"
                          />
                        }
                        sx={{
                          pr: 2,
                          border: '1px solid',
                          borderColor: 'grey.100',
                          borderRadius: 2,
                        }}
                        label={typeOption.label}
                      />
                    ))}
                  </RadioGroup>
                  {touched.type && errors.type ? (
                    <FormHelperText sx={{ color: 'error.main' }}>
                      {errors.type}
                    </FormHelperText>
                  ) : null}
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'flex-start', gap: 3, ml: 3, mb: 1 }}
      >
        <Button variant="greyGhost" onClick={closeAnalysisModal}>
          Cancel
        </Button>

        <Button onClick={handleDone}>Start Analysis</Button>
      </DialogActions>
    </Dialog>
  );
};
