import { useEffect, useState } from 'react';

import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Box,
  styled,
  Typography,
  Button,
} from '@mui/material';
import {
  faAlignLeft,
  faCheck,
  faChevronRight,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OmniClassCategory } from 'types/OmniClassCategory';
import { getCategoryAccordionClass, selectedCategoryClass } from './utils';
import { useUpdateFavoriteOmniClassCategory } from 'hooks/omni-class-category/useFavoriteOmniClassCategories';
import { StarButton } from './StarButton';
import { useMixpanel } from 'hooks/useMixpanel';

const Accordion = styled((props: AccordionProps) => <MuiAccordion {...props} />)(() => ({
  border: 'none',
  boxShadow: 'none',
  '&::before': {
    opacity: 0,
  },
  '&.Mui-expanded': {
    marginTop: 0,
    marginBottom: 0,
  },
  '&.Mui-expanded::before': {
    opacity: 0,
  },
}));

type CustomAccordionSummaryProps = AccordionSummaryProps & {
  isLeaf: boolean;
  isSelected: boolean;
};

const AccordionSummary = styled((props: CustomAccordionSummaryProps) => {
  const { isLeaf, isSelected, ...rest } = props;
  const icon = isLeaf ? faAlignLeft : faChevronRight;
  return (
    <MuiAccordionSummary
      expandIcon={
        <Box color={isLeaf && isSelected ? 'blue.700' : 'inherit'}>
          <FontAwesomeIcon icon={icon} />
        </Box>
      }
      {...rest}
    />
  );
})(({ theme, isLeaf }) => ({
  flexDirection: 'row-reverse',
  minHeight: '3rem!important',
  '& .MuiPaper-root': {
    border: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: isLeaf ? 'none' : 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    '&.Mui-expanded': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
}));

type CategoryAccordionProps = {
  category: OmniClassCategory;
  selectedCategories: OmniClassCategory[];
  onCategorySummaryClick: (category: OmniClassCategory) => void;
  allowSelectAny?: boolean;
  expand?: OmniClassCategory[];
};

export const CategoryAccordion = (props: CategoryAccordionProps) => {
  const { updateFavoriteOmniClassCategoryQuery } = useUpdateFavoriteOmniClassCategory();
  const { mixpanel } = useMixpanel();
  const handleStartClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    mixpanel.track('update category favorite status from all categories', {
      categoryId: props.category.id,
      isFavorite: !props.category.is_favorite,
    });

    updateFavoriteOmniClassCategoryQuery.mutate({
      categoryId: props.category.id,
      isFavorite: !props.category.is_favorite,
    });
  };

  const isLeaf = !props.category.subCategories;

  const isInSelected = !!props.selectedCategories.find(
    (selected) => selected.id === props.category.id,
  );

  const isSelected =
    !!props.selectedCategories.length &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    props.selectedCategories.at(-1)!.id === props.category.id;

  const [isExpanded, setIsExpanded] = useState(
    () =>
      (!isLeaf && isInSelected) ||
      props.expand?.some((cat) => cat.id === props.category.id),
  );

  useEffect(() => {
    setIsExpanded(
      (!isLeaf && isInSelected && (!isSelected || isExpanded)) ||
        props.expand?.some((cat) => cat.id === props.category.id),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInSelected, isLeaf, isSelected, props.expand]);

  const handleCategoryClick = () => {
    if (isLeaf) {
      if (!props.allowSelectAny) {
        props.onCategorySummaryClick(props.category);
      }
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  const handleSelectClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    mixpanel.click('select single category', 'all categories section', {
      categoryId: props.category.id,
    });

    if (props.allowSelectAny) {
      props.onCategorySummaryClick(props.category);
    }
  };

  return (
    <Accordion
      expanded={isExpanded}
      slotProps={{ transition: { unmountOnExit: true } }}
      className={`${isLeaf && isSelected ? selectedCategoryClass : ''} ${getCategoryAccordionClass(props.category.id)}`}
    >
      <AccordionSummary
        isLeaf={isLeaf}
        isSelected={isSelected}
        aria-controls="panel1a-content"
        onClick={handleCategoryClick}
        sx={[
          (theme) => {
            const ml = -2 * props.category.level + 2;
            const w = -ml * 2;
            const p = props.category.level * 2;
            return {
              backgroundColor: isSelected ? 'blue.50' : 'none',
              marginLeft: theme.spacing(ml),
              width: `calc(100% + ${theme.spacing(w)})`,
              paddingLeft: theme.spacing(p),
              '&:hover': {
                backgroundColor: isSelected ? 'blue.50' : 'grey.50',
                '& .selectTag': {
                  visibility: 'visible',
                },
              },
            };
          },
          {
            cursor: props.allowSelectAny ? 'default !important' : 'pointer',
            '&:hover': {
              '& .selectButton': {
                visibility: props.allowSelectAny && !isSelected ? 'visible' : 'hidden',
              },
              '& .selectTag': {
                visibility:
                  (props.allowSelectAny && isSelected) || !props.allowSelectAny
                    ? 'visible'
                    : 'hidden',
              },
            },
          },
        ]}
      >
        <Box flexGrow={1}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            data-testid="category-option"
          >
            <Typography variant="textDefaultBold" sx={{ display: 'block' }}>
              <Typography>{props.category.name}</Typography>
            </Typography>
            <Button
              data-testid="select-omniclass-category"
              variant="blueGhost"
              size="extraSmall"
              startIcon={<FontAwesomeIcon icon={faCheck} />}
              onClick={handleSelectClick}
              className="selectButton"
              sx={{ visibility: 'hidden' }}
            >
              Select
            </Button>
          </Box>
          <Typography variant="textSmall">{props.category.description}</Typography>
        </Box>
        {(props.allowSelectAny || isLeaf) && (
          <Box
            alignSelf="center"
            display="flex"
            ml={2}
            color="primary.main"
            fontSize="1.5rem"
            alignItems="center"
            visibility={isSelected ? 'visible' : 'hidden'}
            className="selectTag"
          >
            <Box mr={1}>
              <FontAwesomeIcon icon={isSelected ? faCheck : faPlus} />
            </Box>
            <Typography variant="textDefaultBold">
              {isSelected ? 'Selected' : 'Select'}
            </Typography>
          </Box>
        )}
        {isLeaf && (
          <StarButton
            isFavorite={props.category.is_favorite}
            onClick={handleStartClick}
            sx={{
              ml: 2,
              alignSelf: 'center',
            }}
          />
        )}
      </AccordionSummary>
      {!isLeaf && (
        <AccordionDetails>
          {!!props.category.subCategories &&
            Object.values(props.category.subCategories)
              .sort((a, d) => (d.subCategories ? 1 : 0 - (a.subCategories ? 1 : 0)))
              .map((subCategory) => (
                <CategoryAccordion
                  key={subCategory.id}
                  category={subCategory}
                  selectedCategories={props.selectedCategories}
                  onCategorySummaryClick={props.onCategorySummaryClick}
                  allowSelectAny={props.allowSelectAny}
                  expand={props.expand}
                />
              ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
