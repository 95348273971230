import { atom, useAtom } from 'jotai';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { useState } from 'react';
import { Project } from 'types/Project';
import { CustomForesiteReportModalOptions } from 'types/Reports';
import { addSearchParamsToUrl } from 'utils/helpers';
import { config, getForesiteSearchParams } from '../helpers';

const foresiteReportlyoutsAtom = atom(false);
foresiteReportlyoutsAtom.debugLabel = 'foresiteFlyoutsAtom';

export const useForesiteReport = () => {
  const { infoToast, errorToast, successToast } = useToastDialogs();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isForesiteReportModalOpen, setIsForesiteReportModalOpen] = useAtom(
    foresiteReportlyoutsAtom,
  );

  const download = (project: Project, options: CustomForesiteReportModalOptions) => {
    if (typeof project.id !== 'number' || project.id < 0) {
      throw Error();
    }
    let url = Resources.FORESITE_PDF_DOWNLOAD.replace('<int:pk>', project.id.toString());

    const searchParams = getForesiteSearchParams(options);

    url = addSearchParamsToUrl({
      url,
      searchParams,
    });
    setIsDownloading(true);
    infoToast({ title: 'Downloading..', text: 'We are preparing your report.' });
    return ApiService.get(url, config)
      .then((response) => {
        if (!response) {
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${project.name} - Foresite.${options.type ?? 'pdf'}`,
        );
        document.body.appendChild(link);
        link.click();
        successToast({
          title: 'Report Downloaded',
          text: 'Report downloaded successfully',
        });
        setIsDownloading(false);
        return response;
      })
      .catch((e) => {
        setIsDownloading(false);
        errorToast({
          text: e?.message ?? 'The service is temporarily unavailable. Try again later.',
        });
      });
  };

  return {
    download,
    isDownloading,
    isForesiteReportModalOpen,
    setIsForesiteReportModalOpen,
  };
};
