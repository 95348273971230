import React from 'react';
import { useLocation } from 'react-router-dom';

export function useQueryParam<T extends Record<string, string>>() {
  const { search } = useLocation();

  return React.useMemo(
    () => Object.fromEntries(new URLSearchParams(search)) as T,
    [search],
  );
}
